<section
  class="custom_select"
  [style.background-color]="params.transparent ? 'transparent' : null"
  [ngClass]="{
    'custom_select--expanded': params.expanded,
    'custom_select--disabled': params.disabled
  }"
>
  <section
    class="select_cnt"
    [ngClass]="{
      'select_cnt--smaller': !params.disabled,
      'select_cnt--enabled': !params.smaller
    }"
  >
    <article class="select_header" (click)="expandSelect()">
      <h1
        class="select_header-title"
        [ngClass]="{
          'title--disabled': params.disabled,
          'title--smaller': !!params.smaller,
          'select_cnt--enabled': !params.smaller
        }"
        [style.color]="params.transparent ? '#F0D459' : null"
        [style.font-weight]="params.transparent ? '400' : null"
      >
        {{ params.title }}
      </h1>
      <span
        class="icon-arrow_down select_header-icon"
        [style.color]="params.transparent ? '#F0D459' : null"
        [ngClass]="{
          'icon--disabled': params.disabled,
          'icon--rotate': params.expanded
        }"
      ></span>
    </article>
    <section
      class="select_body"
      [ngClass]="{
        'select_body--expanded': params.expanded,
        'select_body--expanded_auto':
          params.expanded && params.elementList.length <= 5
      }"
      [style.padding]="!params.showInputSearch ? '0' : '0.5rem 0 0'"
    >
      <div
        class="input_container"
        [style.padding]="!params.showInputSearch ? '0' : '0 0.5rem 0.5rem'"
        [style.margin]="!params.showInputSearch ? '0' : '0.325rem'"
        [style.border-bottom]="
          !params.showInputSearch ? 'none' : '1px solid #939295'
        "
      >
        <div class="input_cnt" *ngIf="params.showInputSearch">
          <form class="w-100" [formGroup]="form">
            <input
              id="search_input"
              type="text"
              class="search_input"
              placeholder="Search"
              formControlName="search"
              (keypress)="findDataOnList($event)"
              (keydown)="handleKeyDown($event)"
            />
          </form>
          <span class="icon-search icon_search"></span>
        </div>
      </div>
      <ul class="list">
        <li
          class="list-item"
          [ngClass]="{ 'item--hidden': i === 0 }"
          *ngFor="let ele of params.elementList; let i = index"
          (click)="handleElement(i)"
        >
          {{ ele.name }}
        </li>
      </ul>
    </section>
  </section>
</section>
