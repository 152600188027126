import { EventEmitter, Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { User } from '../models/user.models';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  public authNotifier: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Constructor
   */
  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
    });
  }

  /**
   * Method that handles to notify authentication status
   * @param status value
   */
  notifyAuthStatus(status: boolean) {
    this.authNotifier.next(status);
  }

  /**
   * Method that sign uo of a value
   * @param user value
   * @returns value
   */
  signUp(user: User): Promise<any> {
    return Auth.signUp({
      username: user.name,
      password: user.password,
      attributes: {
        'custom:role': user.role,
      },
    });
  }

  /**
   * Method that confirms the sign uo of a value
   * @param user value
   * @returns value
   */
  confirmSignup(user: User): Promise<any> {
    return Auth.confirmSignUp(user.name, user.code);
  }

  /**
   * Method that sign in and start session
   * @param user value
   * @returns value
   */
  signIn(user: User): Promise<any> {
    return Auth.signIn(user.name, user.password);
  }

  /**
   * Method that get user
   * @returns value
   */
  getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  /**
   * Method that update user
   * @param user value
   * @returns value
   */
  updateUser(user: User): Promise<any> {
    return Auth.currentUserPoolUser().then((cognitoUser: any) => {
      return Auth.updateUserAttributes(cognitoUser, user);
    });
  }

  /**
   * Method that remove session from storage
   * @returns value
   */
  signOut(): Promise<any> {
    return Auth.signOut();
  }

  /**
   * Method that gets token
   * @returns token
   */
  getToken(): Promise<any> {
    return Auth.currentUserPoolUser().then((cognitoUser: any) => {
      return Auth.userSession(cognitoUser).then((authUser: any) => {
        return authUser.getAccessToken().getJwtToken();
      });
    });
  }

  /**
   * Method that gets the role
   * @returns user role
   */
  getRole(): Promise<any> {
    return this.getUser().then((user) => {
      return user && user.username ? user.username : '';
    });
  }
}
