import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InputTypes } from 'src/app/ui/input/input-types.enum';
import { InputTO } from 'src/app/ui/input/input.to';
import { CognitoService } from '../../services/cognito.service';
import { User } from '../../models/user.models';
import { LoadingService } from 'src/app/ui/loading/services/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /**
   * formgroup
   */
  form!: FormGroup;

  /**
   * Mensaje de error.
   */
  msgError!: string | null;

  passNotInserted!: boolean;
  usernameNotInserted!: boolean;

  /**
   * username input
   */
  username = {
    id: 'username',
    type: InputTypes.ALL,
    label: 'username',
    maxLength: 30,
    placeholder: 'Username',
    inputContrast: true,
    squaredBorder: true,
    handleBlueCallback: true,
  } as InputTO;

  /**
   * pass input
   */
  pass = {
    id: 'pass',
    type: InputTypes.PASSWORD,
    label: 'pass',
    maxLength: 15,
    placeholder: 'Password',
    inputContrast: true,
    squaredBorder: true,
    handleBlueCallback: true,
  } as InputTO;

  termsChecked!: boolean;

  user: User = {} as User;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cognitoService: CognitoService,
    private loadingService: LoadingService
  ) {
    this.termsChecked = false;
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.hide();
    const regExpRut = '^[0-9]{7,8}[-][0-9kK]{1}$';
    const regExpPass = '^(?=.*[a-z])(?=.*[0-9]).{12,}';
    this.form = this.formBuilder.group({
      // button: [],
      username: ['', [Validators.required]],
      pass: ['', [Validators.required]],
    });
    this.username.form = this.form;
    this.pass.form = this.form;
  }

  handleOptionsCheckbox(event: any) {
    this.termsChecked = event?.target?.checked;
  }

  signIn(): void {
    [this.user.name, this.user.password] = Object.values(this.form.value); // Obtiene los valores del formulario
    this.loadingService.show();
    this.cognitoService
      .signIn(this.user)
      .then(() => {
        this.cognitoService.notifyAuthStatus(true);
        this.navigateToHome();
        this.loadingService.hide();
      })
      .catch((error: any) => {
        let errorUpdate = '';
        if (
          error
            .toString()
            .includes('AuthError:' || error.includes('NotAuthorizedException:'))
        ) {
          errorUpdate = error.toString().replace('AuthError:', '');
        } else if (error.toString().includes('NotAuthorizedException')) {
          errorUpdate = error.toString().replace('NotAuthorizedException:', '');
        }
        this.msgError = errorUpdate;
        this.loadingService.hide();
      });
  }

  /**
   * Método que evalua si el rut no ha sido ingresado
   * @param e evento que se obtiene por parametro con el valor del input.
   */
  onFocusOutUsername(event: any): void {
    const user = this.form.controls['username'].value;
    if (user === '') {
      this.msgError = 'Username cannot be empty';
    }
  }

  /**
   * Método que evalua si el rut no ha sido ingresado
   * @param e evento que se obtiene por parametro con el valor del input.
   */
  onFocusOutPass($event: any): void {
    const pass = this.form.controls['pass'].value;
    if (pass === '') {
      this.msgError = 'Password cannot be empty';
    }
  }

  resetMsgErr() {
    this.msgError = null;
  }

  /**
   * Metodo que se gatilla al presionar el boton del formulario.
   */
  onSubmit(): void {
    this.msgError = null;
    this.form.markAllAsTouched();
    if (this.form.valid && !!this.termsChecked) {
      this.signIn();
    } else if (!this.termsChecked) {
      this.msgError = 'The terms of use option must be checked to proceed';
    }
  }

  navigateToHome() {
    this.router.navigate(['/map/']);
  }
}
