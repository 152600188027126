import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DarkModeConfig } from 'src/app/models';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import { ManageLayerService } from 'src/app/services/manage-layers.service';
import { Constants } from 'src/app/shared';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  /**
   * Flag that validates address search is visible
   */
  addressSearchIsVisible: boolean = true;
  /**
   * Flag that validates advanced search is visible
   */
  advancedSearchIsVisible!: boolean;
  /**
   * Flag that validates manage layers is visible
   */
  manageLayersIsVisible!: boolean;
  /**
   * Flag that validates zoning data is visible
   */
  zoningDataIsVisible!: boolean;
  /**
   * Flag that validates settings is visible
   */
  settingsIsVisible!: boolean;
  /**
   * Flag that validates theme is visible
   */
  themeMode!: any;
  /**
   * flag that handles when multiple containers are opened
   */
  adjustElement!: boolean;
  /**
   * Flag that validates zoning analysis is visible
   */
  zoningAnalysisIsVisible!: boolean;

  /**
   * Flag that validates that there is a vale in the first row
   */
  firstRowHasValue: any;
  /**
   * Subscription
   */
  subscription!: Subscription;

  /**
   * list that contains the counter of opened containers
   */
  counterContainersOpened: any[] = [];

  /**
   * Constructor
   * @param darkmodeTheme darkmode theme
   * @param manageLayerService manageLayerService
   */
  constructor(
    private darkmodeTheme: DarkModeService,
    private manageLayerService: ManageLayerService
  ) {}

  ngOnInit(): void {
    this.themeMode = sessionStorage.getItem('theme');

    this.themeMode = sessionStorage.getItem('theme');
    this.subscription = this.darkmodeTheme.darkModeData.subscribe(
      (data: DarkModeConfig) => {
        this.themeMode = data?.activeTheme;
      }
    );
    this.detectActivatedLayers();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Method that activates adjustelement flag
   * @param event event value
   */
  handleAdjustElement(event: any) {
    this.adjustElement = event;
  }

  /**
   * Method that detect when a layer is activated
   */
  detectActivatedLayers() {
    const data = [];
    let layers = {};
    const commercialDistrict = sessionStorage.getItem('commercialDistrict');
    layers = {
      singleLayer: commercialDistrict,
    };
    data.push(layers);
    this.manageLayerService.reloadSetLayers(data);
  }

  get firstAndSecondAndThirdElementOpened(): boolean {
    return (
      !!this.addressSearchIsVisible &&
      !!this.advancedSearchIsVisible &&
      !!this.zoningDataIsVisible
    );
  }

  get firstAndSecondElementOpened(): boolean {
    return (
      (!!this.addressSearchIsVisible && !!this.advancedSearchIsVisible) ||
      (!!this.addressSearchIsVisible && !!this.zoningDataIsVisible) ||
      (!!this.addressSearchIsVisible && !!this.manageLayersIsVisible) ||
      (!!this.addressSearchIsVisible && !!this.zoningAnalysisIsVisible)
    );
  }

  disabledLastContainerOpened(lastElement: number) {
    switch (lastElement) {
      case 1:
        this.advancedSearchIsVisible = false;
        break;
      case 2:
        this.manageLayersIsVisible = false;
        break;

      case 3:
        this.zoningDataIsVisible = false;
        break;

      case 4:
        this.zoningAnalysisIsVisible = false;
        break;

      default:
        break;
    }
  }

  /**
   * Method that handle which options will be visible
   * @param event event value
   */
  handleOptionToShow(event: any) {
    switch (event) {
      case Constants.PROPERTY_SEARCH:
        this.addressSearchIsVisible = !this.addressSearchIsVisible;
        break;

      case Constants.ADVANCED_SEARCH:
        this.advancedSearchIsVisible = !this.advancedSearchIsVisible;
        if (!!this.advancedSearchIsVisible) {
          if (this.firstRowHasValue !== 1) {
            if (
              !this.counterContainersOpened.includes(1) &&
              this.counterContainersOpened.length === 2
            ) {
              const lastElement =
                this.counterContainersOpened[
                  this.counterContainersOpened.length - 1
                ];
              this.counterContainersOpened.splice(-1);
              this.disabledLastContainerOpened(lastElement);
              this.advancedSearchIsVisible = true;
              this.counterContainersOpened.push(1);
            } else {
              this.counterContainersOpened.push(1);
            }
          }
        } else {
          this.counterContainersOpened = this.counterContainersOpened.filter(
            (ele: any) => ele !== 1
          );
        }
        if (
          !this.firstRowHasValue &&
          this.counterContainersOpened.includes(1) &&
          this.counterContainersOpened.length < 2
        ) {
          this.firstRowHasValue = 1;
          if (
            this.firstRowHasValue === 1 &&
            this.counterContainersOpened.length < 2
          ) {
            if (
              !!this.advancedSearchIsVisible &&
              this.counterContainersOpened.length < 2
            ) {
            } else {
              this.counterContainersOpened =
                this.counterContainersOpened.filter((ele: any) => ele !== 1);
            }
          }
        } else if (
          this.firstRowHasValue === 1 &&
          this.counterContainersOpened.length < 2
        ) {
          if (
            this.counterContainersOpened.length === 1 &&
            this.counterContainersOpened.length < 2
          ) {
            this.counterContainersOpened.forEach((element) => {
              this.firstRowHasValue = element;
            });
          } else {
            this.firstRowHasValue = null;
          }
          this.advancedSearchIsVisible = false;
          this.counterContainersOpened = this.counterContainersOpened.filter(
            (ele: any) => ele !== 1
          );
        }
        break;

      case Constants.MANAGE_LAYERS:
        this.manageLayersIsVisible = !this.manageLayersIsVisible;
        if (!!this.manageLayersIsVisible) {
          if (this.firstRowHasValue !== 2) {
            if (
              !this.counterContainersOpened.includes(2) &&
              this.counterContainersOpened.length === 2 &&
              this.firstRowHasValue !== 2
            ) {
              const lastElement =
                this.counterContainersOpened[
                  this.counterContainersOpened.length - 1
                ];
              this.counterContainersOpened.splice(-1);
              this.disabledLastContainerOpened(lastElement);
              this.manageLayersIsVisible = true;
              this.counterContainersOpened.push(2);
            } else {
              this.counterContainersOpened.push(2);
            }
          }
        } else {
          this.counterContainersOpened = this.counterContainersOpened.filter(
            (ele: any) => ele !== 2
          );
        }
        if (
          !this.firstRowHasValue &&
          this.counterContainersOpened.includes(2) &&
          this.counterContainersOpened.length < 2
        ) {
          this.firstRowHasValue = 2;
          if (
            this.firstRowHasValue === 2 &&
            this.counterContainersOpened.length < 2
          ) {
            if (
              !!this.manageLayersIsVisible &&
              this.counterContainersOpened.length < 2
            ) {
            } else {
              this.counterContainersOpened =
                this.counterContainersOpened.filter((ele: any) => ele !== 2);
            }
          }
        } else if (
          this.firstRowHasValue === 2 &&
          this.counterContainersOpened.length < 2
        ) {
          if (
            this.counterContainersOpened.length === 1 &&
            this.counterContainersOpened.length < 2
          ) {
            this.counterContainersOpened.forEach((element) => {
              this.firstRowHasValue = element;
            });
          } else {
            this.firstRowHasValue = null;
          }
          this.manageLayersIsVisible = false;
          this.counterContainersOpened = this.counterContainersOpened.filter(
            (ele: any) => ele !== 2
          );
        }
        break;

      case Constants.SETTINGS:
        this.settingsIsVisible = !this.settingsIsVisible;
        break;

      case Constants.ZONING_DATA:
        this.zoningDataIsVisible = !this.zoningDataIsVisible;
        if (!!this.zoningDataIsVisible) {
          if (this.firstRowHasValue !== 3) {
            if (
              !this.counterContainersOpened.includes(3) &&
              this.counterContainersOpened.length === 2 &&
              this.firstRowHasValue !== 3
            ) {
              const lastElement =
                this.counterContainersOpened[
                  this.counterContainersOpened.length - 1
                ];
              this.counterContainersOpened.splice(-1);
              this.disabledLastContainerOpened(lastElement);
              this.zoningDataIsVisible = true;
              this.counterContainersOpened.push(3);
            } else {
              this.counterContainersOpened.push(3);
            }
          }
        } else {
          this.counterContainersOpened = this.counterContainersOpened.filter(
            (ele: any) => ele !== 3
          );
        }
        if (
          !this.firstRowHasValue &&
          this.counterContainersOpened.includes(3) &&
          this.counterContainersOpened.length < 2
        ) {
          this.firstRowHasValue = 3;
          if (
            this.firstRowHasValue === 3 &&
            this.counterContainersOpened.length < 2
          ) {
            if (
              !!this.zoningDataIsVisible &&
              this.counterContainersOpened.length < 2
            ) {
            } else {
              this.counterContainersOpened =
                this.counterContainersOpened.filter((ele: any) => ele !== 3);
            }
          }
        } else if (
          this.firstRowHasValue === 3 &&
          this.counterContainersOpened.length < 2
        ) {
          if (
            this.counterContainersOpened.length === 1 &&
            this.counterContainersOpened.length < 2
          ) {
            this.counterContainersOpened.forEach((element) => {
              this.firstRowHasValue = element;
            });
          } else {
            this.firstRowHasValue = null;
          }
          this.zoningDataIsVisible = false;
          this.counterContainersOpened = this.counterContainersOpened.filter(
            (ele: any) => ele !== 3
          );
        }
        break;

      case Constants.ZONING_ANALYSIS:
        this.zoningAnalysisIsVisible = !this.zoningAnalysisIsVisible;
        if (!!this.zoningAnalysisIsVisible) {
          if (this.firstRowHasValue !== 4) {
            if (
              !this.counterContainersOpened.includes(4) &&
              this.counterContainersOpened.length === 2 &&
              this.firstRowHasValue !== 4
            ) {
              const lastElement =
                this.counterContainersOpened[
                  this.counterContainersOpened.length - 1
                ];
              this.counterContainersOpened.splice(-1);
              this.disabledLastContainerOpened(lastElement);
              this.zoningAnalysisIsVisible = true;
              this.counterContainersOpened.push(4);
            } else {
              this.counterContainersOpened.push(4);
            }
          }
        } else {
          this.counterContainersOpened = this.counterContainersOpened.filter(
            (ele: any) => ele !== 4
          );
        }
        if (
          !this.firstRowHasValue &&
          this.counterContainersOpened.includes(4) &&
          this.counterContainersOpened.length < 2
        ) {
          this.firstRowHasValue = 4;
          if (
            this.firstRowHasValue === 4 &&
            this.counterContainersOpened.length < 2
          ) {
            if (
              !!this.zoningAnalysisIsVisible &&
              this.counterContainersOpened.length < 2
            ) {
            } else {
              this.counterContainersOpened =
                this.counterContainersOpened.filter((ele: any) => ele !== 4);
            }
          }
        } else if (
          this.firstRowHasValue === 4 &&
          this.counterContainersOpened.length < 2
        ) {
          if (
            this.counterContainersOpened.length === 1 &&
            this.counterContainersOpened.length < 2
          ) {
            this.counterContainersOpened.forEach((element) => {
              this.firstRowHasValue = element;
            });
          } else {
            this.firstRowHasValue = null;
          }
          this.zoningAnalysisIsVisible = false;
          this.counterContainersOpened = this.counterContainersOpened.filter(
            (ele: any) => ele !== 4
          );
        }
        break;
      default:
        break;
    }
  }
}
