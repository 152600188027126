import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ManageLayerService {
  /**
   * Subject dark mode
   */
  private layerSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  layerData = this.layerSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Metodo que setea la capa
   */
  setLayer(layerId: string): void {
    const data = {
      layer: layerId,
      activate: true,
      layersList: [],
    };
    this.layerSubject.next(data);
  }

  /**
   * Method that removes layer
   */
  removeLayer(layerId: string): void {
    const data = {
      layer: layerId,
      activate: false,
      layersList: [],
    };
    this.layerSubject.next(data);
  }

  /**
   * Method that reload setted layers
   * @param layersArray value
   */
  reloadSetLayers(layersArray: any[]): void {
    const data = {
      layer: '',
      activate: false,
      layersList: layersArray,
    };
    this.layerSubject.next(data);
  }
}
