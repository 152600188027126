import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  /**
   * indicates if the loading element should be visible¿
   */
  @Input() show: number | boolean = 1;
  /**
   * Constructor
   */
  constructor() {}

  ngOnInit(): void {}
}
