<form
  class="form_cnt"
  [ngClass]="{
    'form_cnt--bigger': !!params.biggerInput,
    'form_cnt--contrast': !!params.inputContrast
  }"
  [formGroup]="params.form"
  autocomplete="off"
>
  <div class="icon-search-c" *ngIf="params.isSearch">
    <img src="../../../../../assets/custom-icons/search.svg" />
  </div>
  <div
    class="input_cnt"
    [ngClass]="{
      'input_cnt--err': errorLabel,
      'input_cnt--not_err': !errorLabel,
      'input_cnt--no_border_radius': params.squaredBorder
    }"
  >
    <input
      class="input"
      [type]="type"
      id="input-{{ params.id }}"
      [formControlName]="params.id"
      [minLength]="params.minLength"
      [maxlength]="params.maxLength"
      (blur)="blur($event, params.id)"
      (focus)="focus($event, params.id)"
      (keyup.enter)="keytab()"
      (input)="keyEnterEvent()"
      [placeholder]="params.placeholder"
      [readonly]="params.readOnly"
      [autocomplete]="type === 'password' ? 'off' : 'on'"
    />
    <i
      class="pass-eye"
      [ngClass]="{
        'bi bi-eye-slash': showPassword,
        'bi bi-eye': !showPassword
      }"
      (click)="changePasswordView()"
      *ngIf="params.type === types.PASSWORD"
    ></i>
  </div>
</form>
