<section
  class="advanced_search"
  [ngClass]="{
    'advanced_search--fixed': !adjustElement && twoContainer && !secondRowActive
  }"
>
  <div class="header_info d-flex justify-content-between">
    <p class="header_text">Advanced Search</p>
    <span class="icon-left-arrow icon" (click)="close()"></span>
  </div>

  <section
    class="body_item"
    [ngClass]="{
      'body_item--fixed': adjustElement,
      'body_item--reduced': reducedContainer,
      'body_item--middle': middleContainer
    }"
  >
    <!-- Borough item -->
    <div class="input_cnt">
      <app-switch
        class="switch_item"
        [params]="switchBorough"
        (onclick)="switchBoroughToggle($event.target.checked)"
      ></app-switch>
      <div class="col-md-9">
        <app-select
          [params]="borough"
          (expandElement)="expandSelectedElement($event, 'Borough')"
          (itemSelected)="handleElementSelected($event, 'Borough')"
        ></app-select>
      </div>

      <article class="info_cnt">
        <i class="icon-info icon" data-toggle="tooltip" title="hello"></i>
        <div class="tooltip">Lorem ipsum dolor 1.</div>
      </article>
      <!-- <button ngbTooltip="Tooltip test" placement="left">test</button> -->
    </div>

    <!-- Zoning district -->
    <div class="input_cnt">
      <app-switch
        class="switch_item"
        [params]="switchZoning"
        (onclick)="switchZoningToggle($event.target.checked)"
      ></app-switch>
      <div class="col-md-9">
        <app-select
          [params]="zoning"
          (expandElement)="expandSelectedElement($event, 'Zoning')"
          (itemSelected)="handleElementSelected($event, 'Zoning')"
        ></app-select>
      </div>

      <article class="info_cnt">
        <i class="icon-info icon"></i>
        <div class="tooltip">Lorem ipsum dolor2.</div>
      </article>
    </div>

    <!-- Use permitted -->
    <div class="input_cnt">
      <app-switch
        class="switch_item"
        [params]="switchUse"
        (onclick)="switchUseToggle($event.target.checked)"
      ></app-switch>
      <div class="col-md-9">
        <app-select
          [params]="use"
          (expandElement)="expandSelectedElement($event, 'Use')"
          (itemSelected)="handleElementSelected($event, 'Use')"
        ></app-select>
      </div>

      <article class="info_cnt">
        <i class="icon-info icon"></i>
        <div class="tooltip">Lorem ipsum dolor.</div>
      </article>
    </div>

    <!-- Lot area -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchLotArea"
          (onclick)="switchLotAreaToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchLotArea.enabled }"
          >
            Lot Area
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{ 'input_element--visible': !!switchLotArea.enabled }"
      >
        <ngx-slider
          [(value)]="lotAreaMinValue"
          [(highValue)]="lotAreaMaxValue"
          [options]="lotAreaOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchLotArea.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="lotAreaMinValue"
            (change)="updateInputMinAccordingType($event, 'lotArea')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="lotAreaMaxValue"
            (change)="updateInputMaxAccordingType($event, 'lotArea')"
          />
        </div>
      </div>
    </section>

    <!-- Building area -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchBuildingArea"
          (onclick)="switchBuildingAreaToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchBuildingArea.enabled }"
          >
            Building Area
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{ 'input_element--visible': !!switchBuildingArea.enabled }"
      >
        <ngx-slider
          [(value)]="buildingAreaMinValue"
          [(highValue)]="buildingAreaMaxValue"
          [options]="buildingAreaOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchBuildingArea.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="buildingAreaMinValue"
            (change)="updateInputMinAccordingType($event, 'buildingArea')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="buildingAreaMaxValue"
            (change)="updateInputMaxAccordingType($event, 'buildingArea')"
          />
        </div>
      </div>
    </section>

    <!-- Units allowed -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchUnits"
          (onclick)="switchUnitsToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchUnits.enabled }"
          >
            Units Allowed
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{ 'input_element--visible': !!switchUnits.enabled }"
      >
        <ngx-slider
          [(value)]="unitsMinValue"
          [(highValue)]="unitsMaxValue"
          [options]="unitsOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchUnits.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="unitsMinValue"
            (change)="updateInputMinAccordingType($event, 'units')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="unitsMaxValue"
            (change)="updateInputMaxAccordingType($event, 'units')"
          />
        </div>
      </div>
    </section>

    <!-- Buildable area -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchBuildableArea"
          (onclick)="switchBuildableAreaToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchBuildableArea.enabled }"
          >
            Buildable Area
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{ 'input_element--visible': !!switchBuildableArea.enabled }"
      >
        <ngx-slider
          [(value)]="buildableAreaMinValue"
          [(highValue)]="buildableAreaMaxValue"
          [options]="buildableAreaOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchBuildableArea.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="buildableAreaMinValue"
            (change)="updateInputMinAccordingType($event, 'buildableArea')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="buildableAreaMaxValue"
            (change)="updateInputMaxAccordingType($event, 'buildableArea')"
          />
        </div>
      </div>
    </section>

    <!-- Existing area -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchExistingArea"
          (onclick)="switchExistingAreaToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchExistingArea.enabled }"
          >
            Existing Area
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{ 'input_element--visible': !!switchExistingArea.enabled }"
      >
        <ngx-slider
          [(value)]="existingAreaMinValue"
          [(highValue)]="existingAreaMaxValue"
          [options]="existingAreaOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchExistingArea.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="existingAreaMinValue"
            (change)="updateInputMinAccordingType($event, 'existingArea')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="existingAreaMaxValue"
            (change)="updateInputMaxAccordingType($event, 'existingArea')"
          />
        </div>
      </div>
    </section>

    <!-- Allowable FAR -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchAllowableFar"
          (onclick)="switchAllowableFarToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchAllowableFar.enabled }"
          >
            Allowed FAR
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible': !!switchAllowableFar.enabled
        }"
      >
        <ngx-slider
          [(value)]="allowableFarMinValue"
          [(highValue)]="allowableFarMaxValue"
          [options]="allowableFarOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchAllowableFar.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="allowableFarMinValue"
            (change)="updateInputMinAccordingType($event, 'allowableFar')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="allowableFarMaxValue"
            (change)="updateInputMaxAccordingType($event, 'allowableFar')"
          />
        </div>
      </div>
    </section>

    <!-- Built FAR -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchBuiltFar"
          (onclick)="switchBuiltFarToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchBuiltFar.enabled }"
          >
            Built FAR
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible': !!switchBuiltFar.enabled
        }"
      >
        <ngx-slider
          [(value)]="builtFarMinValue"
          [(highValue)]="builtFarMaxValue"
          [options]="builtFarOptions"
        ></ngx-slider>
      </div>
      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchBuiltFar.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="builtFarMinValue"
            (change)="updateInputMinAccordingType($event, 'builtFar')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="builtFarMaxValue"
            (change)="updateInputMaxAccordingType($event, 'builtFar')"
          />
        </div>
      </div>
    </section>

    <!-- Building Height -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchBuilding"
          (onclick)="switchBuildingToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchBuilding.enabled }"
          >
            Building Height
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible': !!switchBuilding.enabled
        }"
      >
        <ngx-slider
          [(value)]="buildingMinValue"
          [(highValue)]="buildingMaxValue"
          [options]="buildingOptions"
        ></ngx-slider>
      </div>
      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchBuilding.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="buildingMinValue"
            (change)="updateInputMinAccordingType($event, 'buildingHeight')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="buildingMaxValue"
            (change)="updateInputMaxAccordingType($event, 'buildingHeight')"
          />
        </div>
      </div>
    </section>

    <!-- Levels -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchFloors"
          (onclick)="switchFloorsToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchFloors.enabled }"
          >
            Levels
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{ 'input_element--visible': !!switchFloors.enabled }"
      >
        <ngx-slider
          [(value)]="floorsMinValue"
          [(highValue)]="floorsMaxValue"
          [options]="floorsOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchFloors.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="floorsMinValue"
            (change)="updateInputMinAccordingType($event, 'floors')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="floorsMaxValue"
            (change)="updateInputMaxAccordingType($event, 'floors')"
          />
        </div>
      </div>
    </section>

    <!-- Year built -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchYearBuilt"
          (onclick)="switchYearBuiltToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchYearBuilt.enabled }"
          >
            Year Built
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{ 'input_element--visible': !!switchYearBuilt.enabled }"
      >
        <ngx-slider
          [(value)]="yearBuiltMinValue"
          [(highValue)]="yearBuiltMaxValue"
          [options]="yearBuiltOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchYearBuilt.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="yearBuiltMinValue"
            (change)="updateInputMinAccordingType($event, 'yearsBuilt')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="yearBuiltMaxValue"
            (change)="updateInputMaxAccordingType($event, 'yearsBuilt')"
          />
        </div>
      </div>
    </section>

    <!-- Assessed land value -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchAssestedLand"
          (onclick)="switchAssestedLandToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchAssestedLand.enabled }"
          >
            Assessed Land Value
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible': !!switchAssestedLand.enabled
        }"
      >
        <ngx-slider
          [(value)]="assestedLandMinValue"
          [(highValue)]="assestedLandMaxValue"
          [options]="assestedLandOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchAssestedLand.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="assestedLandMinValue"
            (change)="updateInputMinAccordingType($event, 'assestedLand')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="assestedLandMaxValue"
            (change)="updateInputMaxAccordingType($event, 'assestedLand')"
          />
        </div>
      </div>
    </section>

    <!-- Assessed total value -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchAssestedTotalValue"
          (onclick)="switchAssestedTotalValueToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{
              'item_text--enabled': switchAssestedTotalValue.enabled
            }"
          >
            Assessed Total Value
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible': !!switchAssestedTotalValue.enabled
        }"
      >
        <ngx-slider
          [(value)]="assestedTotalValueMinValue"
          [(highValue)]="assestedTotalValueMaxValue"
          [options]="assestedTotalValueOptions"
        ></ngx-slider>
      </div>

      <div
        class="slider_inputs_cnt"
        [ngClass]="{
          'slider_inputs_cnt--visible': !!switchAssestedTotalValue.enabled
        }"
      >
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="assestedTotalValueMinValue"
            (change)="updateInputMinAccordingType($event, 'assestedTotal')"
          />
        </div>
        <div class="slider_inputs_cnt-input">
          <input
            type="text"
            onClick="this.setSelectionRange(0, this.value.length)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [value]="assestedTotalValueMaxValue"
            (change)="updateInputMaxAccordingType($event, 'assestedTotal')"
          />
        </div>
      </div>
    </section>

    <!-- Split Lot -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchSplitLot"
          (onclick)="switchSplitLotToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchSplitLot.enabled }"
          >
            Split Lot
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{ 'input_element--visible_radio': !!switchSplitLot.enabled }"
      >
        <app-radio
          (onClickRadio)="handlererRadio($event)"
          [radioList]="splitLotRadioList"
          [form]="form"
        ></app-radio>
      </div>
    </section>

    <!-- Flood Zone -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchFloodZone"
          (onclick)="switchFloodZoneToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchFloodZone.enabled }"
          >
            Flood Zone
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible_radio': !!switchFloodZone.enabled
        }"
      >
        <app-radio
          [radioList]="floodZoneRadioList"
          (onClickRadio)="handlererRadio($event)"
          [form]="form"
        ></app-radio>
      </div>
    </section>

    <!-- Historic district -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchHistoricDistrict"
          (onclick)="switchHistoricDistrictToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchHistoricDistrict.enabled }"
          >
            Historic District
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible_radio': !!switchHistoricDistrict.enabled
        }"
      >
        <app-radio
          [radioList]="historicDistrictRadioList"
          (onClickRadio)="handlererRadio($event)"
          [form]="form"
        ></app-radio>
      </div>
    </section>

    <!-- Landmark building -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchLandmarkBuilding"
          (onclick)="switchLandmarkBuildingToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchLandmarkBuilding.enabled }"
          >
            Landmark Building
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible_radio': !!switchLandmarkBuilding.enabled
        }"
      >
        <app-radio
          [radioList]="landmarkBuildingRadioList"
          (onClickRadio)="handlererRadio($event)"
          [form]="form"
        ></app-radio>
      </div>
    </section>

    <!-- E Designations -->
    <section>
      <div class="input_cnt">
        <app-switch
          class="switch_item"
          [params]="switchDesignations"
          (onclick)="switchDesignationsToggle($event.target.checked)"
        ></app-switch>
        <div class="col-md-9">
          <p
            class="item_text"
            [ngClass]="{ 'item_text--enabled': switchDesignations.enabled }"
          >
            Environmental Designations
          </p>
        </div>

        <article class="info_cnt">
          <i class="icon-info icon"></i>
          <div class="tooltip">Lorem ipsum dolor.</div>
        </article>
      </div>

      <div
        class="input_element"
        [ngClass]="{
          'input_element--visible_radio': !!switchDesignations.enabled
        }"
      >
        <app-radio
          [radioList]="designationsRadioList"
          (onClickRadio)="handlererRadio($event)"
          [form]="form"
        ></app-radio>
      </div>
    </section>
  </section>

  <div class="btn_search_cnt">
    <button class="btn_search">Search</button>
  </div>

  <article class="result" *ngIf="searchResult">
    <p class="result-title">Search Results</p>
    <div class="result_cnt" *ngFor="let ele of searchResultList">
      <p class="result_cnt-item">{{ ele.item }}</p>
      <p class="result_cnt-result">{{ ele.result }}</p>
    </div>
  </article>
</section>
