<section
  class="zoning_data"
  [ngClass]="{
    'zoning_data--fixed': !adjustElement && twoContainer && !secondRowActive,
    'zoning_data--second_row': !!secondRowActive,
  }"
>
  <section class="header_info">
    <div class="d-flex justify-content-between">
      <p class="header_text">Zoning Data</p>
      <span class="icon-left-arrow icon" (click)="close()"></span>
    </div>

    <section class="no_data_cnt" *ngIf="!bblLotZoningInfo">
      <h1 class="no_data_title">Select Lot</h1>
    </section>

    <section *ngIf="!!bblLotZoningInfo">
      <h1 class="title">
        <ng-container *skeleton="isLoading; height: '22px'; width: '316px'">
          {{ lotAddressTitle }}
        </ng-container>
      </h1>
      <div class="d-flex justify-content-between w-100 pt-1 mb-1">
        <p
          class="subtitle"
          *skeleton="isLoading; height: '10px'; width: '168px'; margin: '0px'"
        >
          {{ lotBoroughSub }} | Block {{ lotBlockSub }} | Lot {{ lotSub }}
        </p>
        <p
          class="subtitle"
          *skeleton="isLoading; height: '10px'; width: '98px'"
        >
          BBL {{ lotBblSub }}
        </p>
      </div>
      <p class="subtitle" *skeleton="isLoading; height: '10px'; width: '120px'">
        Zoning District:
        <span class="subtitle-type"> {{ zoningDistrictCompleteInfo }}</span>
      </p>
      <article class="extra_info" *ngFor="let item of extraInfo; let x = index">
        <article>
          <div class="url_cnt">
            <a
              class="url_cnt-text"
              [href]="item.pdfInfoUrl"
              target="_blank"
              *skeleton="isLoading; height: '10px'; width: '120px'"
            >
              {{ item.pdfInfoText }}
            </a>
          </div>
          <div class="url_cnt">
            <a
              class="url_cnt-text"
              [href]="item.digitalInfoUrl"
              target="_blank"
              *skeleton="isLoading; height: '10px'; width: '120px'"
            >
              {{ item.digitalInfoText }}
            </a>
          </div>
        </article>

        <article>
          <div class="url_cnt">
            <a
              class="url_cnt-text text--right"
              [href]="item.buildingInfoUrl"
              target="_blank"
              *skeleton="isLoading; height: '10px'; width: '120px'"
            >
              {{ item.buildingInfoText }}
            </a>
          </div>
          <div class="url_cnt">
            <a
              class="url_cnt-text text--right"
              [href]="item.propertyInfoUrl"
              target="_blank"
              *skeleton="isLoading; height: '10px'; width: '120px'"
            >
              {{ item.propertyInfoText }}
            </a>
          </div>
        </article>
      </article>
    </section>
  </section>

  <section class="body" *ngIf="!!bblLotZoningInfo">
    <ul class="tabs">
      <li
        class="tabs-options"
        [ngClass]="{ 'options--active': tab.active }"
        (click)="handleTab(i)"
        *ngFor="let tab of infoTypeData; let i = index"
      >
        {{ tab.type }}
      </li>
    </ul>
    <section class="options" *ngIf="lotSelected">
      <article class="option_cnt" *ngFor="let item of lotDataList">
        <section
          class="option w-100"
          [ngClass]="{ 'option--no_border': !!data.noBorder }"
          *ngFor="let data of item.info"
        >
          <ng-template #lotDataListWarningContent>
            <div *ngIf="data.info === 'Environmental Designation'">
              See NYC Office of
              <a
                href="https://www.nyc.gov/site/oer/remediation/e-designation.page"
                target="_blank"
                style="
                  line-height: 1;
                  font-weight: 200;
                  text-decoration: none;
                  color: #f0d459;
                  text-decoration: underline;
                  text-underline-offset: 2px;
                "
                >Enviromental Remediation</a
              >
              for further information.
            </div>
          </ng-template>
          <div class="lot_title" *ngIf="!!data.title">
            <h2 class="title">{{ data.title }}</h2>
            <i
              class="icon_info icon-info"
              tooltipClass="zd_tooltip_financial_Data"
              ngbTooltip="test"
              placement="right"
              container="body"
            ></i>
          </div>
          <article
            class="d-flex align-items-center justify-content-between w-100"
          >
            <div class="d-flex">
              <p class="option-info">{{ data.info }}</p>
              <i
                class="icon_warning icon-warning"
                tooltipClass="zd_tooltip_warning"
                [ngbTooltip]="lotDataListWarningContent"
                placement="right"
                container="body"
                [closeDelay]="10000"
                *ngIf="
                  (data.value !== 'n/a' &&
                    data.value !== '-' &&
                    (data.info === 'Special District' ||
                      data.info === 'Limited Height District' ||
                      data.info === 'Historic District' ||
                      data.info === 'Landmark' ||
                      data.info === 'Landmark Building' ||
                      data.info === 'Environmental Designation')) ||
                  (data.value !== 'No' &&
                    (data.info === 'Flood Zone' ||
                      data.info === 'Split District Lot'))
                "
              ></i>
            </div>
            <p
              class="option-info info--right right--break"
              [ngClass]="{
                ' right--break': !!data.breakValue
              }"
              *ngIf="data.url === null"
            >
              <ng-container
                *skeleton="isLoading; height: '12px'; width: data.width"
              >
                {{ data.value }}
              </ng-container>
            </p>
            <a
              [href]="data.url"
              target="_blank"
              class="option-info info--right_url"
              [ngClass]="{ 'right_url--inactive': data.value === 'n/a' }"
              *ngIf="data.url !== null"
            >
              <ng-container
                *skeleton="isLoading; height: '12px'; width: data.width"
              >
                {{ data.value }}
              </ng-container>
            </a>
          </article>
        </section>
      </article>
    </section>

    <section class="options" *ngIf="buildingSelected">
      <article
        class="option_cnt"
        [ngClass]="{ 'option_cnt--visible': buildingSelected }"
        *ngFor="let item of buildingDataList; let i = index"
      >
        <div
          class="option"
          [ngClass]="{ 'option--no_border': !!data.noBorder }"
          *ngFor="let data of item.info; let x = index"
        >
          <div class="lot_title" *ngIf="!!data.title">
            <h2 class="title">{{ data.title }}</h2>
            <i
              class="icon_info icon-info"
              *ngIf="!!data.showIcon"
              tooltipClass="zd_tooltip_building_gross_Data"
              [ngbTooltip]="dataBuildingGross"
              placement="right"
              container="body"
              [closeDelay]="2000"
            ></i>
          </div>
          <div
            class="option_row d-flex align-items-center justify-content-between w-100"
          >
            <ng-template #dataBuildingGross>
              <b>Data Source:</b> NYC Open Data <br />
              <b>Agency:</b> Department of Finance (DOF) <br />
              <b>Methodology:</b>
              {{ item?.zd_areasource }} <br />
              <b>Information Context:</b>
              <ul style="position: relative; left: -18px">
                <li>
                  Rough estimate of the building gross floor area and does not
                  necessarily take into account all the criteria for calculating
                  zoning floor area (ZFA) as defined in section
                  <a
                    style="
                      line-height: 1;
                      font-weight: 200;
                      text-decoration: none;
                      color: #f0d459;
                      text-decoration: underline;
                      text-underline-offset: 2px;
                    "
                    href="https://zr.planning.nyc.gov/article-i/chapter-2#12-10"
                    target="_blank"
                    >ZR 12-10</a
                  >
                </li>
                <li>
                  Note that the sum of the various floor area fields does not
                  always equal total "Building Gross sf".
                </li>
                <li>
                  Floor Area of zero can mean it is either not available or not
                  applicable.
                </li>
              </ul>
            </ng-template>

            <p class="option-info">{{ data.info || "-" }}</p>
            <p class="option-info info--right">
              <ng-container
                *skeleton="isLoading; height: '12px'; width: data.width"
              >
                {{ data.value || "-" }}
              </ng-container>
            </p>
          </div>
          <div class="inner_info" *ngIf="!!data.innerData">
            <div class="inner_info_cnt" *ngFor="let opt of data.innerData">
              <article class="option_row d-flex">
                <div
                  class="inner_info_cnt-color_box"
                  [ngStyle]="{
                    background: opt.color,
                    border: '3px solid' + opt.color
                  }"
                ></div>
                <p class="inner_info_cnt-info">
                  {{ opt.info }}
                </p>
              </article>
              <p class="inner_info_cnt-info">
                <ng-container
                  *skeleton="isLoading; height: '12px'; width: '48px'"
                >
                  {{ opt.value }}
                </ng-container>
              </p>
            </div>
          </div>

          <div id="chart" class="chart_cnt mt-4" *ngIf="!!data.showChart">
            <ng-container *skeleton="isLoading; height: '100px'; width: '100%'">
              <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [labels]="chartOptions.labels"
                [responsive]="chartOptions.responsive"
                [legend]="chartOptions.legend"
                [dataLabels]="chartOptions.dataLabels"
                [colors]="chartOptions.colors"
                [plotOptions]="chartOptions.plotOptions"
                [xaxis]="chartOptions.xaxis"
                [yaxis]="chartOptions.yaxis"
                [grid]="chartOptions.grid"
                [title]="chartOptions.title"
              >
              </apx-chart>
            </ng-container>
          </div>
        </div>
      </article>
    </section>

    <section class="options" *ngIf="financialSelected">
      <article
        class="option_cnt"
        [ngClass]="{ 'option_cnt--visible': financialSelected }"
        *ngFor="let item of financialDataList"
      >
        <ng-template #htmlTaxClass>
          <b>Property in NYC is divided into 4 classes:</b> <br />
          <b>1. Class 1:</b> Most residential property of up to three units
          (family homes and small stores or offices with one or two apartments
          attached), and most condominiums that are not more than three stories.
          <br />
          <b>2. Class 2:</b> All other property that is not in Class 1 and is
          primarily residential (rentals, cooperatives and condominiums). Class
          2 includes: <br />
          <div style="margin-left: 6px">
            a. Sub-Class 2a (4 - 6 unit rental building); <br />
            b. Sub-Class 2b (7 - 10 unit rental building); <br />
            c. Sub-Class 2c (2 - 10 unit cooperative or condominium); and <br />
            d. Class 2 (11 units or more).
          </div>
          <b>3. Class 3:</b> Most utility property. <br />
          <b> 4. Class 4:</b> All commercial and industrial properties, such as
          office, retail, factory buildings <br />
          and all other properties not included in tax classes 1, 2 or 3.
        </ng-template>

        <ng-template #htmlFinancial>
          <b> Data Source: </b> NYC Open Data <br />
          <b>Agency:</b> Department of Finance (DOF) <br />
          <b> Information Context: </b> Data represent NYC properties
          assessments for the purpose of calculating Property Tax, Grant
          eligible properties Exemptions and/or Abatements. This dataset
          originates from Property Assessors, Property Exemption Specialists,
          ACRIS reporting, and Department of Building reporting.
        </ng-template>

        <ng-template #htmlFloorAreaWarning>
          Zoning Lots Divided by District Boundaries <br />
          show adjusted max floor area per
          <a
            style="color: #f0d459"
            href="https://zr.planning.nyc.gov/article-vii/chapter-7#77-22"
            >ZR 77-22</a
          >
        </ng-template>

        <ng-template #htmlDataFloorAreaInfo>
          <b> Information Context: </b> <br />
          This dataset presents estimated Floor Area Ratio (FAR) and Zoning
          Floor Area (ZFA) figures, based on allowable uses. <br />
          <br />
          <ul>
            <li><b>R (QH):</b> Residential (Quality Housing)</li>
            <li><b>R (HF):</b> Residential (Height Factor)</li>
            <li><b> R (IH):</b> Residential (Inclusionary Housing)</li>
            <li><b>CF:</b> Community Facility</li>
            <li><b>C: </b>Commercial</li>
            <li><b> M: </b>Manufacturing</li>
            <li><b>E: </b>Existing</li>
            <li><b>R: </b>Remain</li>
          </ul>

          Note that: <br />
          <ul>
            <li>
              "Existing" values are based on gross floor area calculations and
              do not necessarily account for all the criteria for calculating
              Zoning Floor Area (ZFA) as defined in the resolution.
            </li>
            <li>
              "Remain" values do not account for the sale or purchase of
              potential air rights.
            </li>
          </ul>
        </ng-template>
        <div class="financial_title">
          <h2 class="title">{{ item.title }}</h2>
          <i
            class="icon_info icon-info"
            tooltipClass="zd_tooltip_financial_Data"
            [ngbTooltip]="htmlFinancial"
            placement="right"
            container="body"
          ></i>
        </div>
        <div
          class="option"
          [ngClass]="{
            'option--innerSpace': !!data.color,
            'option--financial_lines': financialSelected
          }"
          *ngFor="let data of item.info"
        >
          <ng-template #htmlcontent>
            <b>Data Source:</b> The information presented originates from the
            fusion of datasets extracted from NYC Open Data and zlvas
            proprietary algorithms. <br />
            <b>Agency:</b> N/A <br />
            <b>Information Context:</b> This dataset presents estimated Floor
            Area Ratio (FAR) and Zoning Floor Area (ZFA) figures, based on
            allowable uses. It's important to note that the "Built FAR" value is
            grounded in gross floor area calculations, while "Potential Air
            Rights" strictly adhere to As-of-Right regulations. <br />
            <b>Origin:</b> This information is a result of various geographic
            datasets and custom algorithms to yield estimated values. These
            values constitute a preliminary analysis of a zoning lot's potential
            built and available floor areas. Use for reference and informational
            purposes only.
          </ng-template>
          <ng-template #warningContent>
            Zoning Lots Divided by District Boundaries show adjusted max floor
            area per
            <a
              href="https://zr.planning.nyc.gov/article-vii/chapter-7#77-22"
              target="_blank"
              style="
                line-height: 1;
                font-weight: 200;
                text-decoration: none;
                color: #f0d459;
                text-decoration: underline;
                text-underline-offset: 2px;
              "
              >ZR 77-22</a
            >
          </ng-template>
          <h1 class="option-title" *ngIf="data.title">
            {{ data.title || "-" }}
            <i
              class="icon_warning icon-warning"
              tooltipClass="zd_tooltip_warning"
              [ngbTooltip]="warningContent"
              placement="right"
              container="body"
              [closeDelay]="10000"
              *ngIf="splitZoneValue === 'Yes'"
            ></i>
            <i
              class="icon_info icon-info"
              tooltipClass="zd_tooltip_floor_area_info"
              *ngIf="!!data.showIconInfo"
              [ngbTooltip]="htmlcontent"
              placement="right"
              container="body"
            ></i>
          </h1>
          <div class="d-flex align-items-center justify-content-between w-100">
            <div
              class="color_box_financial"
              [ngStyle]="{
                background: data.color,
                border: '3px solid' + data.color
              }"
              *ngIf="data.color"
            ></div>
            <div class="colored_info_cnt" *ngIf="!!data.colorBox">
              <div
                class="colored_info_cnt-color_box_financial_bars"
                [ngStyle]="{
                  background: data.colorBox,
                  border: '3px solid' + data.colorBox
                }"
                *ngIf="data.colorBox"
              ></div>
              <p class="colored_info_cnt-info info--bigger">
                {{ data.info || "-" }}
              </p>
            </div>
            <p class="option-info info--bigger" *ngIf="!data.colorBox">
              {{ data.info || "-" }}
            </p>
            <p class="option-info info--right" *ngIf="!data.hasSelect">
              <ng-container
                *skeleton="isLoading; height: '12px'; width: '48px'"
              >
                {{ data.value || "-" }}
              </ng-container>
              <i
                class="icon-info info_icon info_icon--tax_class"
                tooltipClass="zd_tooltip_tax_financial"
                [ngbTooltip]="htmlTaxClass"
                placement="right"
                container="body"
                *ngIf="data.showIcon"
              ></i>
            </p>
            <app-select
              class="input input--margin_right col-md-3"
              [params]="yearSelect"
              (expandElement)="expandSelectedElement($event)"
              (itemSelected)="handleElementSelected($event)"
              *ngIf="!!data.hasSelect"
            ></app-select>
          </div>
          <div
            class="d-flex align-items-center justify-content-between w-100 up_cnt"
            [ngClass]="{ 'up_cnt--active': data.color }"
          >
            <div
              class="color_box_financial"
              [ngStyle]="{
                background: data.color,
                border: '3px solid' + data.color
              }"
              *ngIf="data.color"
            ></div>
            <p
              class="option-info info--bigger info--subdata"
              [ngClass]="{ 'info--subdata_color': data.color }"
              *ngIf="data.subData"
            >
              {{ data.subData || "-" }}
            </p>
            <p class="option-info info--right" *ngIf="data.subValue">
              <ng-container
                *skeleton="isLoading; height: '12px'; width: '48px'"
              >
                {{ data.subValue || "-" }}
              </ng-container>
            </p>
          </div>
          <div
            id="chartFinancialBarsOptions"
            class="chart_cnt mt-1"
            *ngIf="!!data.hasFinancialBars"
          >
            <ng-container *skeleton="isLoading; height: '180px'; width: '100%'">
              <apx-chart
                [series]="chartFinancialLineOptions.series"
                [chart]="chartFinancialLineOptions.chart"
                [labels]="chartFinancialLineOptions.labels"
                [responsive]="chartFinancialLineOptions.responsive"
                [legend]="chartFinancialLineOptions.legend"
                [dataLabels]="chartFinancialLineOptions.dataLabels"
                [colors]="chartFinancialLineOptions.colors"
                [plotOptions]="chartFinancialLineOptions.plotOptions"
                [xaxis]="chartFinancialLineOptions.xaxis"
                [yaxis]="chartFinancialLineOptions.yaxis"
                [grid]="chartFinancialLineOptions.grid"
                [stroke]="chartFinancialLineOptions.stroke"
                [markers]="chartFinancialLineOptions.markers"
                [title]="chartFinancialLineOptions.title"
              >
              </apx-chart>
            </ng-container>
          </div>
        </div>
        <ng-container *skeleton="isLoading; height: '150px'; width: '100%'">
          <article class="floor_area">
            <div class="financial_title">
              <h2 class="title">
                Floor Area Data
                <i
                  *ngIf="
                    regulationsData &&
                    regulationsData.message.FAR.SPLITZONE === 'true'
                  "
                  class="icon_warning icon-warning"
                  tooltipClass="zd_tooltip_financial_Data"
                  [ngbTooltip]="htmlFloorAreaWarning"
                  placement="right"
                  container="body"
                  [closeDelay]="2000"
                ></i>
              </h2>
              <i
                class="icon_info icon-info"
                tooltipClass="zd_tooltip_floorAreaInfo_Data"
                [ngbTooltip]="htmlDataFloorAreaInfo"
                placement="right"
                container="body"
              ></i>
            </div>
            <p class="subtitle_txt">
              Allowable Floor Area Ratio & Zoning Floor Area
            </p>
            <ul class="header_floor_area">
              <li class="header_floor_area-item">FAR</li>
              <li class="header_floor_area-item">ZFA (sf.)</li>
              <li class="header_floor_area-item">Remain (sf.)</li>
            </ul>

            <section class="floor_area_cnt" *ngFor="let item of floorAreaList">
              <ng-container
                *ngIf="
                  regulationsData &&
                  regulationsData?.message?.FAR?.SPLITZONE === 'true'
                "
              >
                <div class="indicators">
                  <p class="type_txt txt--link">{{ item.zonedist }}</p>

                  <p class="type_txt txt--link" *ngIf="!!item.overlay">
                    <span class="type_separatos">/{{ item.overlay }}</span>
                  </p>
                  <p class="portion_txt">
                    portion = <span class="link"> {{ item.portionNum }}</span> %
                    (<span class="link">{{ item.sfNum }}</span> sf)
                  </p>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  regulationsData &&
                  regulationsData?.message?.FAR?.SPLITZONE !== 'true'
                "
              >
                <div
                  class="indicators indicators--margin"
                  *ngFor="let title of titleFloorAreaList"
                >
                  <p class="type_txt txt--link">{{ title.ZONEDIST }}</p>

                  <p class="type_txt txt--link" *ngIf="!!title.OVERLAY">
                    <span class="type_separatos">/{{ title.OVERLAY }}</span>
                  </p>
                  <p class="portion_txt">
                    portion =
                    <span class="link">
                      {{
                        title.A_SG_P
                          ? title.A_SG_P.toLocaleString("en-GB")
                          : "-"
                      }}</span
                    >
                    % (<span class="link">{{
                      title.A_SG_SF
                        ? title.A_SG_SF.toLocaleString("en-GB")
                        : "-"
                    }}</span>
                    sf)
                  </p>
                </div>
              </ng-container>
              <ul class="floor_area_body" *ngFor="let ele of item.valuesData">
                <li
                  class="floor_area_body-item"
                  [ngClass]="{
                  'item--rqh': ele.type.includes('QH'),
                  'item--hf': ele.type.includes('HF'),
                  'item--rih': ele.type.includes('(IH)'),
                  'item--cf': ele.type ==='CF',
                  'item--c': ele.type ==='C',
                  'item--m': ele.type.includes('M'),
                }"
                >
                  {{ ele.type }}
                </li>
                <li class="floor_area_body-item">
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{ ele.farValue !== "" ? ele.farValue : "-" }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.farValue !== "" ? ele.farValue : "-" }}
                  </p>
                </li>
                <li class="floor_area_body-item">
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{ ele.zfaValue !== "" ? ele.zfaValue : "-" }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.zfaValue !== "" ? ele.zfaValue : "-" }}
                  </p>
                </li>
                <li
                  class="floor_area_body-item"
                  [ngClass]="{
                    'item--negative':
                      ele?.remainValue < 0 ||
                      (ele?.remainValue &&
                        ele?.remainValue !== '-' &&
                        validateNotANumber(ele?.remainValue) &&
                        ele?.remainValue?.includes('-') &&
                        ele?.remainValue?.length > 1)
                  }"
                >
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{ ele.remainValue !== "" ? ele.remainValue : "-" }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.remainValue !== "" ? ele.remainValue : "-" }}
                  </p>
                </li>
              </ul>
            </section>

            <!-- Floor area adjusted -->
            <ng-container
              *ngIf="
                regulationsData &&
                regulationsData?.message?.FAR?.SPLITZONE === 'true'
              "
            >
              <section
                class="floor_area_cnt"
                *ngFor="let item of floorAreaAjdList"
              >
                <div class="indicators">
                  <p class="portion_txt portion_txt--adjusted">
                    Adjusted = <span class="link"> 100%</span>(<span
                      class="link"
                      >{{ formatValues(item.adjustedTotal) }}</span
                    >
                    sf)
                  </p>
                </div>

                <ul
                  class="floor_area_body"
                  *ngFor="let ele of item.adjustedData"
                >
                  <li
                    class="floor_area_body-item"
                    [ngClass]="{
                    'item--rqh': ele.type.includes('QH'),
                    'item--hf': ele.type.includes('HF'),
                    'item--rih': ele.type.includes('(IH)'),
                    'item--cf': ele.type ==='CF',
                    'item--c': ele.type ==='C',
                    'item--m': ele.type.includes('M'),
                  }"
                  >
                    {{ ele.type }}
                  </li>
                  <li class="floor_area_body-item">
                    <p
                      class="item_txt"
                      [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                      *ngIf="!ele.linkable"
                    >
                      {{ ele.farValue !== "" ? ele.farValue : "-" }}
                    </p>
                    <p class="link" *ngIf="ele.linkable">
                      {{ ele.farValue !== "" ? ele.farValue : "-" }}
                    </p>
                  </li>
                  <li class="floor_area_body-item">
                    <p
                      class="item_txt"
                      [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                      *ngIf="!ele.linkable"
                    >
                      {{ ele.zfaValue !== "" ? ele.zfaValue : "-" }}
                    </p>
                    <p class="link" *ngIf="ele.linkable">
                      {{ ele.zfaValue !== "" ? ele.zfaValue : "-" }}
                    </p>
                  </li>
                  <li
                    class="floor_area_body-item"
                    [ngClass]="{
                      'item--negative':
                        ele.remainValue &&
                        ele.remainValue !== '-' &&
                        ele.remainValue.includes('-') &&
                        ele.remainValue.length > 1
                    }"
                  >
                    <p
                      class="item_txt"
                      [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                      *ngIf="!ele.linkable"
                    >
                      {{ ele.remainValue !== "" ? ele.remainValue : "-" }}
                    </p>
                    <p class="link" *ngIf="ele.linkable">
                      {{ ele.remainValue !== "" ? ele.remainValue : "-" }}
                    </p>
                  </li>
                </ul>
              </section>
            </ng-container>

            <!-- Floor area max -->

            <section
              class="floor_area_cnt floor_area_cnt--margin_top"
              *ngFor="let item of floorAreaMaxList"
            >
              <ul class="floor_area_body" *ngFor="let ele of item.maxData">
                <li
                  class="floor_area_body-item"
                  [ngClass]="{
                'item--max': ele.type.includes('Max'),
              }"
                >
                  {{ ele.type }}
                </li>
                <li class="floor_area_body-item">
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{
                      ele.farValue !== "" && ele.farValue !== ""
                        ? ele.farValue
                        : "-"
                    }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.farValue !== "" ? ele.farValue : "-" }}
                  </p>
                </li>
                <li class="floor_area_body-item">
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{ ele.zfaValue !== "" ? ele.zfaValue : "-" }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.zfaValue !== "" ? ele.zfaValue : "-" }}
                  </p>
                </li>
                <li
                  class="floor_area_body-item"
                  [ngClass]="{
                    'item--negative':
                      ele.remainValue &&
                      ele.remainValue !== '-' &&
                      ele.remainValue.includes('-') &&
                      ele.remainValue.length > 1
                  }"
                >
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{ ele.remainValue !== "" ? ele.remainValue : "-" }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.remainValue !== "" ? ele.remainValue : "-" }}
                  </p>
                </li>
              </ul>
            </section>

            <!-- Floor area max -->

            <section
              class="floor_area_cnt"
              *ngFor="let item of floorAreaMaxIHList"
            >
              <ul class="floor_area_body" *ngFor="let ele of item.maxIHData">
                <li
                  class="floor_area_body-item"
                  [ngClass]="{
                'item--maxIh': ele.type.includes('Max IH'),
              }"
                >
                  {{ ele.type }}
                </li>
                <li class="floor_area_body-item">
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{ ele.farValue !== "" ? ele.farValue : "-" }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.farValue !== "" ? ele.farValue : "-" }}
                  </p>
                </li>
                <li class="floor_area_body-item">
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{ ele.zfaValue !== "" ? ele.zfaValue : "-" }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.zfaValue !== "" ? ele.zfaValue : "-" }}
                  </p>
                </li>
                <li
                  class="floor_area_body-item"
                  [ngClass]="{
                    'item--negative':
                      ele.remainValue &&
                      ele.remainValue !== '-' &&
                      ele.remainValue.includes('-') &&
                      ele.remainValue.length > 1
                  }"
                >
                  <p
                    class="item_txt"
                    [ngClass]="{ 'item_txt--pending': !!ele.pending }"
                    *ngIf="!ele.linkable"
                  >
                    {{ ele.remainValue !== "" ? ele.remainValue : "-" }}
                  </p>
                  <p class="link" *ngIf="ele.linkable">
                    {{ ele.remainValue !== "" ? ele.remainValue : "-" }}
                  </p>
                </li>
              </ul>
            </section>

            <section class="floor_graphic_cnt">
              <p class="subtitle_txt subtitle_txt--remain">
                ZFA Allowable / <span class="carbon">Existing</span> /
                <span class="green_light">Remain</span>
              </p>

              <ng-container
                *skeleton="isLoading; height: '100px'; width: '100%'"
              >
                <apx-chart
                  [series]="floorAreaChartOptions?.series"
                  [chart]="floorAreaChartOptions?.chart"
                  [labels]="floorAreaChartOptions?.labels"
                  [responsive]="floorAreaChartOptions?.responsive"
                  [legend]="floorAreaChartOptions?.legend"
                  [dataLabels]="floorAreaChartOptions?.dataLabels"
                  [colors]="floorAreaChartOptions?.colors"
                  [plotOptions]="floorAreaChartOptions?.plotOptions"
                  [xaxis]="floorAreaChartOptions?.xaxis"
                  [yaxis]="floorAreaChartOptions?.yaxis"
                  [grid]="floorAreaChartOptions?.grid"
                  [title]="floorAreaChartOptions?.title"
                >
                </apx-chart>
              </ng-container>
            </section>
          </article>
        </ng-container>
      </article>
    </section>
  </section>
</section>
