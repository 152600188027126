// import { Component, OnInit } from '@angular/core';
// import { HttpClient } from '@angular/common/http';

// @Component({
//   selector: 'app-use-table',
//   templateUrl: './use-table.component.html',
//   styleUrls: ['./use-table.component.scss']
// })
// export class UseTableComponent implements OnInit {

//   csvData: string[][] = [];
//   headers: string[] = [];
//   filteredCsvData: string[][] = [];
//   overlayZonedistList: { OVERLAY: string, ZONEDIST: string }[] = [];  // Recibe la lista de objetos

//   constructor(private http: HttpClient) {}

//   ngOnInit(): void {
//     this.loadCSV();
//   }

//   loadCSV(): void {
//     this.http.get('assets/COY_Use_MASTER.csv', { responseType: 'text' }).subscribe(
//       (data) => {
//         this.parseCSV(data);
//       },
//       (error) => {
//         console.error('Error loading the CSV file', error);
//       }
//     );
//   }

//   parseCSV(csv: string): void {
//     const lines = csv.split('\n');
//     this.csvData = lines.map(line => line.split(';'));
//     this.headers = this.csvData[0];  // Encabezados de la tabla

//     const columnsToFilter = this.overlayZonedistList.reduce((acc, obj) => {
//       const overlayValue = obj.OVERLAY;
//       const zonedistValue = obj.ZONEDIST;

//       if (overlayValue && overlayValue !== 'false') {  // Verificar que OVERLAY no sea false
//         const columnPrefix = overlayValue.slice(0, 2).toUpperCase();  // Obtener los dos primeros caracteres y pasarlos a mayúscula
//         acc.push(columnPrefix); 
//       } else {  
//         if (zonedistValue) {
//           if (zonedistValue.includes('/')) {  
//             acc.push('M/R');  
//           } else {
//             const zonedistPrefix = zonedistValue.slice(0, 2).toUpperCase();  
//             acc.push(zonedistPrefix); 
//           }
//         }
//       }

//       return acc;
//     }, [] as string[]);


//     const usesColumnIndex = this.headers.indexOf('Use');

//     // Filtrar y mostrar columnas
//     const columnIndicesToShow: number[] = [];
//     this.headers.forEach((header, index) => {
//       const headerPrefix = header.slice(0, 2).toUpperCase();
//       if (columnsToFilter.includes(headerPrefix) || index === usesColumnIndex) {
//         columnIndicesToShow.push(index);
//       }
//     });

//     // Filtrar los datos basados en las columnas que se mostrarán
//     this.filteredCsvData = this.csvData.slice(1).map(row => columnIndicesToShow.map(index => row[index]));

//     // Renombrar los encabezados
//     this.headers = columnIndicesToShow.map(index => {
//       const overlayObj = this.overlayZonedistList.find(obj => {
//         const overlayValue = obj.OVERLAY;
//         const zonedistValue = obj.ZONEDIST;
//         const headerPrefix = this.headers[index].slice(0, 2).toUpperCase();

//         return overlayValue && overlayValue.slice(0, 2).toUpperCase() === headerPrefix ||
//                (overlayValue === 'false' && zonedistValue && zonedistValue.slice(0, 2).toUpperCase() === headerPrefix);
//       });

//       if (overlayObj) {
//         // Cambiar el encabezado a OVERLAY si es true, o a ZONEDIST si es false
//         return overlayObj.OVERLAY !== 'false' ? overlayObj.OVERLAY : overlayObj.ZONEDIST;
//       } else {
//         return this.headers[index]; // Si no se encuentra un objeto, mantener el encabezado original
//       }
//     });
//   }
// }

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-use-table',
  templateUrl: './use-table.component.html',
  styleUrls: ['./use-table.component.scss']
})
export class UseTableComponent implements OnInit {

  csvData: string[][] = [];
  headers: string[] = [];
  newCsvData: string[][] = [];  // Nuevo CSV generado
  overlayZonedistList: { OVERLAY: string, ZONEDIST: string }[] = [];  // Lista de objetos recibida
  columnsToCopy: string[] = ['ID', 'Use'];  // Columnas iniciales
  newHeaders: string[] = []
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadCSV();
  }

  loadCSV(): void {
    this.http.get('assets/COY_Use_MASTER.csv', { responseType: 'text' }).subscribe(
      (data) => {
        this.parseCSV(data);
      },
      (error) => {
        console.error('Error loading the CSV file', error);
      }
    );
  }

  parseCSV(csv: string): void {
    const lines = csv.split('\n');
    this.csvData = lines.map(line => line.split(';'));
    this.headers = this.csvData[0];  // Encabezados de la tabla

    // Aplicar la lógica de selección basada en OVERLAY y ZONEDIST
    this.applyOverlayZonedistLogic();
  }
  
  applyOverlayZonedistLogic(): void {
    // Recorrer overlayZonedistList y aplicar la lógica
    this.overlayZonedistList.forEach(obj => {
      const overlayValue = obj.OVERLAY;
      const zonedistValue = obj.ZONEDIST;

      if (overlayValue && overlayValue !== 'false') {  // Si OVERLAY no es 'false'
        const columnPrefix = overlayValue.slice(0, 2).toUpperCase();  // Obtener los dos primeros caracteres y convertir a mayúscula
        if(this.headers.includes(columnPrefix)){
          this.newHeaders.push(`${zonedistValue}/${overlayValue}`)
          this.columnsToCopy.push(columnPrefix);  // Agregar la columna con el prefijo a la lista
        }
        
      } else if (zonedistValue) {  // Si OVERLAY es false, usar ZONEDIST
        if (zonedistValue.includes('/')) { 
          this.newHeaders.push(`${zonedistValue}`)

          this.columnsToCopy.push('M/R');  // Agregar 'M/R' si ZONEDIST contiene '/'
        } else {
          const zonedistPrefix = zonedistValue.slice(0, 2).toUpperCase();  // Obtener prefijo de ZONEDIST
          if (this.headers.includes(zonedistPrefix)){
            this.newHeaders.push(`${zonedistValue}`)

            this.columnsToCopy.push(zonedistPrefix);  
          }else{
            const zonedistPrefix = zonedistValue.slice(0, 3).toUpperCase();  // Obtener prefijo de ZONEDIST
            if (this.headers.includes(zonedistPrefix)){
              this.newHeaders.push(`${zonedistValue}`)

              this.columnsToCopy.push(zonedistPrefix);  
            }
          }
        }
      }
    });

    // Actualizar el CSV después de aplicar la lógica
    this.updateCsvData();
  }

  updateCsvData(): void {
    const newHeaders = this.columnsToCopy;  // Usar las columnas seleccionadas
    const newCsv: string[][] = [newHeaders];  // Inicializar con los nuevos encabezados

    // Recorrer cada fila del CSV original y copiar las columnas seleccionadas
    this.csvData.slice(1).forEach(row => {
      const newRow = this.columnsToCopy.map(columnName => {
        const columnIndex = this.headers.indexOf(columnName);
        return columnIndex !== -1 ? row[columnIndex] : '';  // Agregar el valor o vacío si no existe
      });
      newCsv.push(newRow);
    });
    this.newHeaders.unshift('ID', 'Use')
    this.newCsvData = newCsv;  // Asignar el nuevo CSV generado
  }
}
