<section
  class="settings"
  [ngClass]="{ 'settings--light': themeMode === 'light' }"
>
  <article class="settings_cnt">
    <app-switch [params]="switchTheme"></app-switch>
    <!--TODO: Dar click a switchTheme una vez que se reincorpore el lightmode-->
    <!-- (onclick)="switchThemeToggle($event.target.checked)" -->
  </article>
</section>
