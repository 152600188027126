import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZoningDataService {
  /**
   * Subject dark mode
   */
  private zoningDataSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  zoningDData = this.zoningDataSubject.asObservable();

  constructor() {}

  /**
   * Method that sets the bbl selected
   */
  setZoningInfo(zoningInfo?: any): void {
    if (!!zoningInfo) {
      const data = zoningInfo;
      sessionStorage.setItem('bblLotZoningInfo', JSON.stringify(data));
    }

    this.zoningDataSubject.next(null);
  }

  /**
   * Method that hanlde the financialInfo data
   * @param financialInfo financialInfo data
   */
  setFinancialInfo(financialInfo: any): void {
    const data = financialInfo;
    this.zoningDataSubject.next(data);
  }
}
