import { Component, ChangeDetectorRef } from '@angular/core';
import { EMPTY, Subscription, tap } from 'rxjs';
import { DarkModeConfig } from './models';
import { LoadingService } from './ui/loading/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * app title
   */
  title = 'front-end-zlvas';

  /**
   * Variable that indicate if loading should be shown
   */
  isLoading = 0;

  /**
   * Subscription
   */
  subscription: Subscription;

  /**
   * Subscriptions
   */
  private loadingSubscriptions: Subscription[] = [];

  /**
   * dark mode configuration
   */
  darkModeConfig!: DarkModeConfig;

  /**
   * Const that handle if is authenticated
   */
  isAuthenticated: boolean = false;

  /**
   * Constructor
   * @param darkmodeService darkmodeservice handler
   * @param manageLayerService managelyer handler
   * @param loadingService loadingservice handler
   * @param changeDetectorRef changedetectorref handler
   */
  constructor(
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscription = EMPTY.pipe(tap(() => null)).subscribe();
  }

  ngOnInit(): void {
    //TODO: wait until the light/dark mode function is necessary.
    // this.subscription = this.darkmodeService.darkModeData.subscribe(
    //   (data: DarkModeConfig) => {
    //     // this.loadingService.show();
    //     this.darkModeConfig = data;
    //   }
    // );

    this.subscription = this.loadingService.loadingState.subscribe((state) => {
      this.isLoading = state;
      this.changeDetectorRef.detectChanges();
    });
    this.cleanSession();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Method that cleans session
   */
  cleanSession() {
    sessionStorage.clear();
  }
}
