<form class="radio_cnt" [formGroup]="form">
  <section
    class="radio"
    [ngClass]="{ 'radio--light': themeMode === 'light' }"
    *ngFor="let item of radioList; let i = index"
  >
    <input
      [id]="item.id"
      [name]="item.nameRadio"
      type="radio"
      [disabled]="item.disabled ? item.disabled : false"
      [value]="item?.value"
      [formControlName]="item.formName"
      (click)="handlererRadio(i)"
    />
    <label [for]="item.id" class="radio-label">{{ item?.label }}</label>
  </section>
</form>
