<section class="home" [ngClass]="{ 'home--light': themeMode === 'light' }">
  <!--       Llamado del mapa   -->    
  <app-map></app-map>
  <section class="home_cnt">
    <!--    Fin Llamado del mapa   -->
    <app-nav-bar
      class="nav_bar"
      [counterContainerArray]="counterContainersOpened"
      [firstRowHasValue]="firstRowHasValue"
      (handleOption)="handleOptionToShow($event)"
    >
    </app-nav-bar>

    <section class="first_row">
      <section
        class="info info--first_row first_row--address_search"
        [ngClass]="{ 'info--visible_address': !!addressSearchIsVisible }"
      >
        <article class="info_cnt">
          <app-address-search
            (elementExpanded)="handleAdjustElement($event)"
          ></app-address-search>
        </article>
      </section>

      <section
        class="info info--first_row"
        [ngClass]="{
          'info--visible': !!advancedSearchIsVisible && firstRowHasValue === 1
        }"
      >
        <article class="info_cnt info_cnt--scroll_active">
          <app-advanced-search
            *ngIf="!!advancedSearchIsVisible && firstRowHasValue === 1"
            [adjustElement]="adjustElement"
            [twoContainer]="firstAndSecondElementOpened"
            [themeMode]="themeMode"
            (closeItem)="handleOptionToShow($event)"
          ></app-advanced-search>
        </article>
      </section>

      <section
        class="info info--first_row"
        [ngClass]="{
          'info--visible': !!manageLayersIsVisible && firstRowHasValue === 2
        }"
      >
        <article class="info_cnt info_cnt--scroll_active">
          <app-manage-layers
            *ngIf="!!manageLayersIsVisible && firstRowHasValue === 2"
            (closeItem)="handleOptionToShow($event)"
            [adjustElement]="adjustElement"
            [addressSearchIsVisible]="addressSearchIsVisible"
            [twoContainer]="firstAndSecondElementOpened"
          ></app-manage-layers>
        </article>
      </section>

      <section
        class="info info--first_row"
        [ngClass]="{
          'info--visible': !!zoningDataIsVisible && firstRowHasValue === 3
        }"
      >
        <article class="info_cnt info_cnt--scroll_active">
          <app-zoning-data
            *ngIf="!!zoningDataIsVisible && firstRowHasValue === 3"
            [adjustElement]="adjustElement"
            [twoContainer]="firstAndSecondElementOpened"
            (closeItem)="handleOptionToShow($event)"
          ></app-zoning-data>
        </article>
      </section>

      <section
        class="info info--first_row"
        [ngClass]="{
          'info--visible': !!zoningAnalysisIsVisible && firstRowHasValue === 4
        }"
      >
        <article class="info_cnt">
          <app-zoning-analysis
            *ngIf="!!zoningAnalysisIsVisible && firstRowHasValue === 4"
            [adjustElement]="adjustElement"
            [twoContainer]="firstAndSecondElementOpened"
            [addressSearchIsVisible]="addressSearchIsVisible"
            (closeItem)="handleOptionToShow($event)"
          ></app-zoning-analysis>
        </article>
      </section>

      <section
        class="info_priority"
        [ngClass]="{ 'info_priority--visible': !!settingsIsVisible }"
      >
        <section class="info">
          <article class="info_cnt">
            <app-settings></app-settings>
          </article>
        </section>
      </section>
    </section>

    <section class="second_row">
      <section
        class="info"
        [ngClass]="{
          'info--visible': !!advancedSearchIsVisible && firstRowHasValue !== 1
        }"
      >
        <article class="info_cnt info_cnt--scroll_active">
          <app-advanced-search
            *ngIf="!!advancedSearchIsVisible && firstRowHasValue !== 1"
            [secondRowActive]="true"
            [themeMode]="themeMode"
            (closeItem)="handleOptionToShow($event)"
          ></app-advanced-search>
        </article>
      </section>

      <section
        class="info"
        [ngClass]="{
          'info--visible': !!manageLayersIsVisible && firstRowHasValue !== 2
        }"
      >
        <article class="info_cnt info_cnt--scroll_active">
          <app-manage-layers
            *ngIf="!!manageLayersIsVisible && firstRowHasValue !== 2"
            (closeItem)="handleOptionToShow($event)"
            [secondRowActive]="true"
          ></app-manage-layers>
        </article>
      </section>

      <section
        class="info"
        [ngClass]="{
          'info--visible': !!zoningDataIsVisible && firstRowHasValue !== 3
        }"
      >
        <article class="info_cnt info_cnt--scroll_active">
          <app-zoning-data
            *ngIf="!!zoningDataIsVisible && firstRowHasValue !== 3"
            [secondRowActive]="true"
            [addressSearchIsVisible]="addressSearchIsVisible"
            (closeItem)="handleOptionToShow($event)"
          ></app-zoning-data>
        </article>
      </section>

      <section
        class="info"
        [ngClass]="{
          'info--visible': !!zoningAnalysisIsVisible && firstRowHasValue !== 4
        }"
      >
        <article class="info_cnt">
          <app-zoning-analysis
            *ngIf="!!zoningAnalysisIsVisible && firstRowHasValue !== 4"
            [secondRowActive]="true"
            [addressSearchIsVisible]="addressSearchIsVisible"
            (closeItem)="handleOptionToShow($event)"
          ></app-zoning-analysis>
        </article>
      </section>
    </section>
  </section>
</section>
