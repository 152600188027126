import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SetPaddingService {
  /**
   * Subject dark mode
   */
  private paddingSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  setPaddingData = this.paddingSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that sets padding
   */
  setPadding(padding: number): void {
    const data = {
      actualPadding: padding,
    };
    this.paddingSubject.next(data);
  }
}
