<!-- <table class="table table-striped table-bordered">
  <thead class="thead-dark">
    <tr>
      <th *ngFor="let header of headers">{{ header }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of filteredCsvData">
      <td *ngFor="let cell of row">{{ cell }}</td>
    </tr>
  </tbody>
</table> -->

<table class="table table-striped table-bordered">
  <thead class="thead-dark">
    <tr>
      <th *ngFor="let header of newHeaders">{{ header }}</th> <!-- Mostrar encabezados -->
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of newCsvData.slice(1)"> <!-- Mostrar filas de datos -->
      <td *ngFor="let cell of row">{{ cell }}</td>
    </tr>
  </tbody>
</table>

