import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetLotService } from 'src/app/services/get-lot.service';
import { Constants } from 'src/app/shared';
import { AccordionTO } from 'src/app/ui/accordion/accordion.to';
import { InputTypes } from 'src/app/ui/input/input-types.enum';
import { InputTO } from 'src/app/ui/input/input.to';
import { SelectTO } from 'src/app/ui/select/select.to';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnInit {
  /**
   * form group
   */
  form!: FormGroup;

  /**
   * Var that controls the visibility of bbl options
   */
  bblVisible!: boolean;

  /**
   * bbl num
   */
  bblNum!: any;

  /**
   * block num
   */
  blockNum!: any;

  /**
   * lot num
   */
  lotNum!: any;

  savedPrincipalInputValue!: any;

  blockBtnGoToLot: boolean = false;

  /**
   * Search address input config
   */
  searchAddress = {
    id: 'searchAddress',
    type: InputTypes.ALPHANUMERIC,
    label: 'Address Search',
    maxLength: 100,
    placeholder: 'Search Address',
    biggerInput: true,
  } as InputTO;

  /**
   * TO config of borough field.
   */
  borough: SelectTO = {
    id: 'borough',
    title: 'Borough',
    attrLabel: 'boroughName',
    transparent: true,
    showInputSearch: false,
    hideFirstOption: true,
    elementList: [
      {
        id: 0,
        name: 'Borough',
        num: '' || '0',
      },
      {
        id: 1,
        name: 'Manhattan (1)',
        num: '1',
      },
      {
        id: 2,
        name: 'Bronx (2)',
        num: '2',
      },
      {
        id: 3,
        name: 'Brooklyn (3)',
        num: '3',
      },
      {
        id: 4,
        name: 'Queens (4)',
        num: '4',
      },
      {
        id: 5,
        name: 'Staten Island (5)',
        num: '5',
      },
    ],
    list: [],
  } as SelectTO;

  /**
   * params of AccordionTO
   */
  params: AccordionTO = {
    title: 'BBL Search...',
    bgPrimary: true,
    showPrincipalInput: true,
  } as AccordionTO;

  /**
   * Block input config
   */
  block = {
    id: 'block',
    type: InputTypes.NUMBERS,
    label: 'Block',
    maxLength: 5,
    placeholder: 'Block',
    inputContrast: true,
    handleBlueCallback: true,
  } as InputTO;

  /**
   * Lot input config
   */
  lot = {
    id: 'lot',
    type: InputTypes.NUMBERS,
    label: 'Lot',
    maxLength: 4,
    placeholder: 'Lot',
    inputContrast: true,
    handleBlueCallback: true,
  } as InputTO;

  /**
   * object options of Address search
   */
  mapOptionsAddressSearch = {
    title: 'Address searchs',
    showInfo: false,
    noDropdown: false,
    bgWhite: true,
  } as AccordionTO;

  /**
   * Input element of reducedContainer
   */
  @Input() reducedContainer!: boolean;

  /**
   * Output that validate if container is opened
   */
  @Output() validateContainersOpened = new EventEmitter<string>();

  /**
   * Output that handles when element is expanded
   */
  @Output() elementExpanded = new EventEmitter<boolean>();

  /**
   * Constructor of the element
   * @param formBuilder form builder
   * @param getLotService getlot service
   */
  constructor(
    private formBuilder: FormBuilder,
    private getLotService: GetLotService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      searchAddress: [null],
      borough: [null],
      block: ['', [Validators.required]],
      lot: ['', [Validators.required]],
    });
    this.searchAddress.form = this.form;
    this.borough.form = this.form;
    this.block.form = this.form;
    this.lot.form = this.form;
  }

  /**
   * Method that emit validation when container is opened
   */
  validateContainer() {
    this.validateContainersOpened.emit('AddressSearch');
  }

  /**
   * Method that toggle bbl info visibility
   */
  showBblOptions() {
    this.bblVisible = !this.bblVisible;
    this.elementExpanded.emit(this.bblVisible);
  }

  /**
   * Method that expands the selected element
   * @param event event value
   * @param type type value
   */
  expandSelectedElement(event: any, type: string) {
    switch (type) {
      case Constants.BOROUGH:
        if (!event && !this.borough.expanded) {
          this.borough.expanded = true;
        } else if (!!event && !!this.borough.expanded) {
          this.borough.expanded = false;
        } else {
          this.borough.expanded = event;
        }
        break;
      default:
        break;
    }
  }

  /**
   * Method that closes the selected element
   * @param isClosed boolean value
   */
  closeElement(isClosed: boolean) {
    if (!this.params.showInfo) {
      this.borough.expanded = false;
    }
  }

  /**
   * Method that handles the select input option selected
   * @param event event value
   * @param type type value
   */
  handleElementSelected(event: any, type: string) {
    let elementSelected: any;
    switch (event) {
      case '':
        event = 'Borough';
        this.borough.title = 'Borough';
        this.borough.expanded = false;
        elementSelected = this.borough.elementList.filter(
          (e: any) => e.name === event
        );
        this.bblNum = '0';
        break;

      case 'Manhattan (1)':
        if (
          this.savedPrincipalInputValue &&
          this.savedPrincipalInputValue.length > 0 &&
          type === 'select'
        ) {
          const principalInputValueWithoutFirstCharacter =
            this.savedPrincipalInputValue.slice(1);
          const newPrincipalInputValue = `1${principalInputValueWithoutFirstCharacter}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
        this.borough.title = event;
        this.borough.expanded = false;
        elementSelected = this.borough.elementList.filter(
          (e: any) => e.name === event
        );
        this.bblNum = elementSelected[0].num;
        break;

      case 'Bronx (2)':
        if (
          this.savedPrincipalInputValue &&
          this.savedPrincipalInputValue.length > 0
        ) {
          const principalInputValueWithoutFirstCharacter =
            this.savedPrincipalInputValue.slice(1);
          const newPrincipalInputValue = `2${principalInputValueWithoutFirstCharacter}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
        this.borough.title = event;
        this.borough.expanded = false;
        elementSelected = this.borough.elementList.filter(
          (e: any) => e.name === event
        );
        this.bblNum = elementSelected[0].num;
        break;

      case 'Brooklyn (3)':
        if (
          this.savedPrincipalInputValue &&
          this.savedPrincipalInputValue.length > 0
        ) {
          const principalInputValueWithoutFirstCharacter =
            this.savedPrincipalInputValue.slice(1);
          const newPrincipalInputValue = `3${principalInputValueWithoutFirstCharacter}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
        this.borough.title = event;
        this.borough.expanded = false;
        elementSelected = this.borough.elementList.filter(
          (e: any) => e.name === event
        );
        this.bblNum = elementSelected[0].num;
        break;

      case 'Queens (4)':
        if (
          this.savedPrincipalInputValue &&
          this.savedPrincipalInputValue.length > 0
        ) {
          const principalInputValueWithoutFirstCharacter =
            this.savedPrincipalInputValue.slice(1);
          const newPrincipalInputValue = `4${principalInputValueWithoutFirstCharacter}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
        this.borough.title = event;
        this.borough.expanded = false;
        elementSelected = this.borough.elementList.filter(
          (e: any) => e.name === event
        );
        this.bblNum = elementSelected[0].num;
        break;

      case 'Staten Island (5)':
        if (
          this.savedPrincipalInputValue &&
          this.savedPrincipalInputValue.length > 0
        ) {
          const principalInputValueWithoutFirstCharacter =
            this.savedPrincipalInputValue.slice(1);
          const newPrincipalInputValue = `5${principalInputValueWithoutFirstCharacter}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
        this.borough.title = event;
        this.borough.expanded = false;
        elementSelected = this.borough.elementList.filter(
          (e: any) => e.name === event
        );
        this.bblNum = elementSelected[0].num;
        break;

      default:
        break;
    }
  }

  /**
   * Listeners that listen for especific click to close the expanded element
   * @param event listener event
   */
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (
      !!this.params.showInfo &&
      event.srcElement.className !== '' &&
      !event.srcElement.className.includes('bbl_input') &&
      !event.srcElement.className.includes('header') &&
      !event.srcElement.className.includes('list-item') &&
      !event.srcElement.className.includes('map') &&
      !event.srcElement.className.includes('info_cnt') &&
      !event.srcElement.className.includes('icon') &&
      !event.srcElement.className.includes('background_label') &&
      !event.srcElement.className.includes('title_cnt') &&
      !event.srcElement.className.includes('select_header') &&
      !event.srcElement.className.includes('input') &&
      !event.srcElement.className.includes('inner_input_cnt') &&
      !event.srcElement.className.includes('input_lot') &&
      !event.srcElement.className.includes('btn_lot')
    ) {
      this.params.showInfo = false;
      this.borough.expanded = false;
    }
  }

  /**
   * Method that handle the block type according block number
   * @param event event value
   */
  handleBlock(event: any, identifier: string) {
    this.blockNum = this.form.controls['block'].value;

    if (typeof event === 'string') {
      const constructEventValue = `${Array.from(String(event))[1] || ''}${
        Array.from(String(event))[2] || ''
      }${Array.from(String(event))[3] || ''}${
        Array.from(String(event))[4] || ''
      }${Array.from(String(event))[5] || ''}`;
      this.form.controls['block'].setValue(constructEventValue);
      this.blockNum = this.form.controls['block'].value;
    }

    if (identifier && identifier !== 'principalSearch') {
      if (this.blockNum.length === 0) {
        this.blockNum = '00000';
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${this.blockNum}${
            Array.from(String(this.savedPrincipalInputValue))[6] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[7] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[8] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[9] || 0}`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `0${this.blockNum}0000`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.blockNum.length === 1) {
        this.blockNum = '0000' + this.blockNum;
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${this.blockNum}${
            Array.from(String(this.savedPrincipalInputValue))[6] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[7] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[8] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[9] || 0}`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `0${this.blockNum}0000`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.blockNum.length === 2) {
        this.blockNum = '000' + this.blockNum;
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${this.blockNum}${
            Array.from(String(this.savedPrincipalInputValue))[6] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[7] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[8] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[9] || 0}`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `0${this.blockNum}0000`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.blockNum.length === 3) {
        this.blockNum = '00' + String(this.blockNum);
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${this.blockNum}${
            Array.from(String(this.savedPrincipalInputValue))[6] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[7] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[8] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[9] || 0}`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `0${this.blockNum}0000`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.blockNum.length === 4) {
        this.blockNum = '0' + String(this.blockNum);
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${this.blockNum}${
            Array.from(String(this.savedPrincipalInputValue))[6] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[7] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[8] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[9] || 0}`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `00${this.blockNum}0000`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.blockNum.length === 5) {
        this.blockNum = this.blockNum;
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${this.blockNum}${
            Array.from(String(this.savedPrincipalInputValue))[6] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[7] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[8] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[9] || 0}`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `0${this.blockNum}0000`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      }
    }
  }

  /**
   * Method that handle the lot type according lot selected
   * @param event event value
   */
  handleLot(event: any, identifier: string) {
    this.lotNum = this.form.controls['lot'].value;

    if (typeof event === 'string') {
      const constructEventValue = `${Array.from(String(event))[6] || ''}${
        Array.from(String(event))[7] || ''
      }${Array.from(String(event))[8] || ''}${
        Array.from(String(event))[9] || ''
      }`;
      this.form.controls['lot'].setValue(constructEventValue);
      this.lotNum = this.form.controls['lot'].value;
    }

    if (identifier && identifier !== 'principalSearch') {
      if (this.lotNum.length === 0) {
        this.lotNum = '0000';

        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${Array.from(String(this.savedPrincipalInputValue))[1] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[2] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[3] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[4] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[5] || 0}${
            this.lotNum
          }`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `000000${this.lotNum}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.lotNum.length === 1) {
        this.lotNum = '000' + this.lotNum;

        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${Array.from(String(this.savedPrincipalInputValue))[1] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[2] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[3] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[4] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[5] || 0}${
            this.lotNum
          }`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `000000${this.lotNum}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.lotNum.length === 2) {
        this.lotNum = '00' + this.lotNum;
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${Array.from(String(this.savedPrincipalInputValue))[1] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[2] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[3] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[4] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[5] || 0}${
            this.lotNum
          }`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `000000${this.lotNum}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.lotNum.length === 3) {
        this.lotNum = '0' + this.lotNum;
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${Array.from(String(this.savedPrincipalInputValue))[1] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[2] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[3] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[4] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[5] || 0}${
            this.lotNum
          }`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `000000${this.lotNum}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      } else if (this.lotNum.length === 4) {
        this.lotNum = this.lotNum;
        if (this.savedPrincipalInputValue) {
          const newPrincipalInputValue = `${
            Array.from(String(this.savedPrincipalInputValue))[0]
          }${Array.from(String(this.savedPrincipalInputValue))[1] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[2] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[3] || 0}${
            Array.from(String(this.savedPrincipalInputValue))[4] || 0
          }${Array.from(String(this.savedPrincipalInputValue))[5] || 0}${
            this.lotNum
          }`;

          this.savedPrincipalInputValue = newPrincipalInputValue;
        } else {
          const newPrincipalInputValue = `000000${this.lotNum}`;
          this.savedPrincipalInputValue = newPrincipalInputValue;
        }
      }
    }
  }

  /**
   * Method that handle the search acording bbl number.
   */
  goToLot(event: any) {
    if (this.bblNum && this.blockNum && this.lotNum) {
      const targetElement = event.target as HTMLElement;

      const rect = targetElement.getBoundingClientRect();
      const absX = rect.left + window.scrollX - 72;
      const absY = rect.top + window.scrollY + 36;

      const bbl = this.bblNum + this.blockNum + this.lotNum;
      this.getLotService.getLot(Number(bbl), [absX, absY]);
    }
    setTimeout(() => {
      this.blockBtnGoToLot = false;
    }, 1500);
    this.blockBtnGoToLot = true;
  }

  cleanBblFields(event: any) {
    this.form.controls['lot'].setValue(event);
    this.form.controls['block'].setValue(event);
    this.handleElementSelected(event, 'keyup');
  }

  doCompleteSearch(event: any) {
    const value = event.value;
    const borough = Array.from(value)[0];
    let sectorValue = '';
    this.savedPrincipalInputValue = value;
    switch (borough) {
      case '1':
        sectorValue = 'Manhattan (1)';
        break;
      case '2':
        sectorValue = 'Bronx (2)';
        break;
      case '3':
        sectorValue = 'Brooklyn (3)';
        break;
      case '4':
        sectorValue = 'Queens (4)';
        break;
      case '5':
        sectorValue = 'Staten Island (5)';
        break;
      default:
        break;
    }
    this.handleElementSelected(sectorValue, 'keyup');
    this.handleBlock(value, event.id);
    this.handleLot(value, event.id);
  }
}
