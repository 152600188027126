import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SwitchTO } from './switch.to';
import { Subscription } from 'rxjs';
import { DarkModeConfig } from 'src/app/models';
import { DarkModeService } from 'src/app/services/dark-mode.service';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit, OnDestroy {
  @Input() params!: SwitchTO;

  /**
   * On click emitter
   */
  @Output() onclick = new EventEmitter<any>();

  themeMode!: any;

  /**
   * Subscription
   */
  subscription!: Subscription;

  /**
   * config of dark mode
   */
  darkModeConfig!: DarkModeConfig;

  /**
   * Constructor
   * @param darkmodeTheme daktheme handler
   */
  constructor(private darkmodeTheme: DarkModeService) {}

  ngOnInit(): void {
    this.themeMode = sessionStorage.getItem('theme');
    if (!this.params.validators) {
      this.params.validators = [];
    }
    if (!this.params.form.controls[this.params.id]) {
      this.params.form.addControl(
        this.params.id,
        new FormControl(null, this.params.validators)
      );
    } else {
      this.params.form.controls[this.params.id].addValidators(
        this.params.validators
      );
    }

    this.themeMode = sessionStorage.getItem('theme');
    this.subscription = this.darkmodeTheme.darkModeData.subscribe(
      (data: DarkModeConfig) => {
        this.themeMode = data.activeTheme;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * on click method. Emit onclick
   * @param event Html event
   */
  onChangeMethod(isChecked: any): void {
    this.onclick.emit(isChecked);
  }
}
