import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

/**
 * Checkbox component
 */
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  /**
   * ID
   */
  @Input() id!: string;

  /**
   * Text to show
   */
  @Input() text!: string;

  /**
   * Checked
   */
  @Input() checked!: boolean;

  /**
   * Disabled
   */
  @Input() disabled!: boolean;

  /**
   * Total Columns
   */
  @Input() totalColumns!: number;

  /**
   * On click emitter
   */
  @Output() onclick = new EventEmitter<any>();

  /**
   * Constructor
   */
  constructor() {}

  /**
   * on click method. Emit onclick
   * @param event Html event
   */
  onChangeMethod(isChecked: any): void {
    this.onclick.emit(isChecked);
  }
}
