import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-rect',
  host: {
    class: '',
  },
  template: ``,
  styles: [
    `
      :host {
        display: block;
        width: var(--skeleton-rect-width);
        height: var(--skeleton-rect-height);
        margin-bottom: var(--margin);
        background: linear-gradient(
          270deg,
          rgba(219, 219, 219, 0.05) 0.5%,
          rgba(87, 86, 86, 0.86) 76.5%
        );
        border-radius: 16px;
      }
    `,
  ],
})
export class RectComponent {
  width!: string;
  height!: string;
  margin!: string;
  className!: string;

  /**
   * Constructor
   * @param host host value
   */
  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(this.className);
    }

    host.style.setProperty('--skeleton-rect-width', this.width ?? '100%');
    host.style.setProperty('--skeleton-rect-height', this.height ?? '20px');
    host.style.setProperty('--margin', this.margin ?? '0.325rem');
  }
}
