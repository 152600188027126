import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SetTagEnvelopeService {
  /**
   * Subject dark mode
   */
  private tagEnvelopeSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  setTagEnvelopeData = this.tagEnvelopeSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that sets the tag enveloped
   */
  setTagEnvelope(value: boolean): void {
    const data = {
      tagValue: value,
    };
    this.tagEnvelopeSubject.next(data);
  }
}
