<div id="loader" *ngIf="!hideLoader" class="icon_loading">
  <img
    class="icon_loading-img"
    src="assets/images/cube_loop.gif"
    alt="loading"
  />
</div>

<div class="modal-header">
  <h5 class="modal-title">Visualize</h5>
  <button type="button" class="close" (click)="closeModal()"></button>
</div>

<div class="modal-body">
  <div id="gui-container" style="padding: 10px; position: absolute"></div>
  <div id="scene-container"></div>
</div>

<div class="modal-footer">
  <table>
    <tr>
      <td><label for="filename">File name:</label></td>
      <td>
        <input
          type="text"
          id="filename"
          name="filename"
          [(ngModel)]="filename"
        />
      </td>
    </tr>
    <tr>
      <td><label for="filetype">Save as type:</label></td>
      <td>
        <select id="filetype" name="filetype" [(ngModel)]="extension">
          <option
            *ngFor="let filetype of filetypes"
            [value]="filetype.extension"
          >
            {{ filetype.name }}
          </option>
        </select>
      </td>
    </tr>
  </table>
  <!-- <button style="background-color: #f0d459;" type="button" class="btn" (click)="center()">Center</button> -->
  <button
    id="downloadButton"
    type="button"
    class="btn btn-custom"
    (click)="download()"
    disabled
  >
    Export
  </button>
  <!-- <button type="button" class="btn btn-custom" (click)="closeModal()">Close</button> -->
</div>
