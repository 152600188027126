import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavOptionExtent {
  /**
   * Subject option extent
   */
  private navOptionExtent = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  navBarData = this.navOptionExtent.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that handles when  a option is tuner iff
   */
  activateExtent(value: boolean): void {
    this.navOptionExtent.next(value);
  }
}
