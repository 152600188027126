import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  /**
   * Subject con información de elementos cargando
   */
  private loadingSubject = new BehaviorSubject<number>(0);

  /**
   * Estado de loading
   */
  loadingState = this.loadingSubject.asObservable();

  constructor() {}

  /**
   * Agrega un elemento del contador de carga
   */
  show(): void {
    this.loadingSubject.next(this.loadingSubject.getValue() + 1);
  }

  /**
   * Elimina un elemento del contador de carga
   */
  hide(): void {
    const currentValue = this.loadingSubject.getValue();
    if (currentValue > 0) {
      this.loadingSubject.next(this.loadingSubject.getValue() - 1);
    }
  }

  /**
   * Resetea el contador de loading
   */
  reset(): void {
    this.loadingSubject.next(0);
  }
}
