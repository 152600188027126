import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavBarCloseService {
  /**
   * Subject dark mode
   */
  private navBarSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  navBarData = this.navBarSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that handles when  a option is tuner iff
   */
  turnOffOption(optionName: string): void {
    const data = {
      option: optionName,
    };
    this.navBarSubject.next(data);
  }
}
