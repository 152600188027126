import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ZoningAnalysisService {
  /**
   * Subject dark mode
   */
  private zoningAnalisysSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  zoningAnalysisData = this.zoningAnalisysSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Methods that sets the bbl selected
   */
  setEnvelope(requestConfig: string, btnSelected: boolean): void {
    const btnIsSelected = btnSelected === true ? btnSelected : false;
    const data = {
      dataConfig: requestConfig,
      removeAction: false,
      btnSelected: btnIsSelected,
    };
    this.zoningAnalisysSubject.next(data);
  }

  /**
   * Method that removes envelope
   */
  removeEnvelope(removeAction: boolean): void {
    const data = {
      bbl: '',
      removeAction: removeAction,
    };
    this.zoningAnalisysSubject.next(data);
  }
}
