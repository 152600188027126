export enum InputTypes {
  ALL = 'all',
  ONLY_TEXT = 'text',
  NUMBERS = 'number',
  EMAIL = 'email',
  SERIAL = 'serial',
  ALPHANUMERIC = 'alphanumeric',
  NUMBER_UNIT = 'NUMBER_UNIT',
  PERCENT = 'percent',
  PASSWORD = 'password',
  CURRENCY = 'currency',
}
