import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressSearchService {
  /**
   * Subject dark mode
   */
  private addressDataSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  addressSearchData = this.addressDataSubject.asObservable();

  constructor() {}

  /**
   * Method that hanlde the financialInfo data
   * @param financialInfo financialInfo data
   */
  setBBL(bbl: any): void {
    const data = bbl;
    this.addressDataSubject.next(data);
  }
}
