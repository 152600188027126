import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccordionTO } from './accordion.to';
import { AddressSearchService } from 'src/app/services/address-search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() params!: AccordionTO;
  @Input() savedPrincipalInputValue: any =
    document.getElementById('principalSearch');
  @Output() tabs = new EventEmitter<number>();
  @Output() iconEvent = new EventEmitter<any>();
  @Output() afterExpand = new EventEmitter<boolean>();
  @Output() biggerAccordion = new EventEmitter<boolean>();
  @Output() accordionOpened = new EventEmitter<boolean>();
  @Output() searchEvent = new EventEmitter<any>();
  @Output() cleanEvent = new EventEmitter<any>();

  /**
   * Subscription
   */
  subscription!: Subscription;

  writedElement!: string;

  constructor(private addressSearchService: AddressSearchService) {
    this.subscription = this.addressSearchService.addressSearchData.subscribe(
      (data: any) => {
        const inputElement = document.getElementById(
          'principalSearch'
        ) as HTMLInputElement;

        if (inputElement) {
          inputElement.value = data;
          this.doSearch(data, '');
        }
      }
    );
  }

  handleAccordion() {
    this.accordionOpened.emit();
    if (!this.params.noDropdown) {
      this.params.showInfo = !this.params.showInfo;
    }
    this.renderAfterExpand();
    this.executeBiggerAccordion();
  }
  renderAfterExpand() {
    setTimeout(() => {
      this.afterExpand.emit(this.params.showInfo);
    }, 0.5);
  }

  triggerIconEvent() {
    if (this.params.showInfo) {
      this.iconEvent.emit();
    }
  }

  handleTabOption($event: MouseEvent, index: number) {
    $event.stopPropagation();
    this.tabs.emit(index);
  }

  executeBiggerAccordion() {
    if (!!this.params.bigger) {
      this.biggerAccordion.emit(this.params.showInfo);
    }
  }

  focusOnPrincipalInput(event: any) {
    if (event.type === 'click' && !!this.params.showInfo) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  validateNumber(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];

    const input = event.key;

    if (!allowedKeys.includes(input) && isNaN(Number(input))) {
      event.preventDefault();
    }
  }

  cleanPrincipalInput() {
    this.writedElement = '';
    this.savedPrincipalInputValue = '';
    this.cleanEvent.emit('');
  }

  doSearch(element?: any, identifier?: string) {
    if (element.type === 'input') {
      this.writedElement = (
        document.getElementById('principalSearch') as HTMLInputElement
      ).value;
    } else if (element.type === 'blur') {
      this.writedElement =
        this.savedPrincipalInputValue ||
        element.value ||
        typeof element.data === 'string'
          ? String(element.data || element)
          : '';
    } else {
      this.writedElement =
        this.savedPrincipalInputValue ||
        element.value ||
        typeof element.data === 'string' ||
        element
          ? String(element.data || element)
          : '';
    }
    setTimeout(() => {
      let elementData: any = {
        value: this.writedElement,
        id: identifier,
      };
      if (
        elementData.value.includes('[') ||
        elementData.value.includes('Focus')
      ) {
        elementData.value = '';
      } else {
        this.searchEvent.emit(elementData);
      }
    }, 0);
  }
}
