import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DarkModeConfig } from 'src/app/models';
import { DarkModeService } from 'src/app/services/dark-mode.service';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit, OnDestroy {
  /**
   * Listado de opciones recibido desde componente padre
   */
  @Input() radioList!: any[];

  /**
   * Form control
   */

  /**
   * Input that handles the form group
   */
  @Input() form!: FormGroup;

  /**
   * Output that handle when the radio is clicked
   */
  @Output() onClickRadio = new EventEmitter<any>();

  /**
   * Var that keeps the theme mode type
   */
  themeMode: any;

  /**
   * Subscription
   */
  subscription!: Subscription;

  /**
   * constructor of the component
   * @param darkmodeTheme darkMode theme service
   */
  constructor(private darkmodeTheme: DarkModeService) {}

  ngOnInit(): void {
    this.themeMode = sessionStorage.getItem('theme');

    this.subscription = this.darkmodeTheme.darkModeData.subscribe(
      (data: DarkModeConfig) => {
        this.themeMode = data?.activeTheme;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Method that handle the radio when is selected.
   * @param index identifier value
   */
  handlererRadio(index: number) {
    const radioInfo = {
      value: this.radioList[index].value,
      name: this.radioList[index].formName,
    };
    this.onClickRadio.emit(radioInfo);
  }
}
