<form
  [formGroup]="params.form"
  autocomplete="off"
  class="cnt_switch"
  [ngClass]="{ 'cnt_switch--light': themeMode === 'light' }"
>
  <label class="left_label" *ngIf="params.leftLabel">{{
    params.leftLabel
  }}</label>
  <label class="switch">
    <input
      class="input"
      type="checkbox"
      [formControlName]="params.id"
      [checked]="params.enabled"
      (change)="onChangeMethod($event)"
    />
    <span
      class="slider"
      [ngClass]="{ 'slider--inner_values': params.innerValue }"
    >
    </span>
  </label>
  <label class="label" *ngIf="params.label">{{ params.label }}</label>
</form>
