<section
  class="accordion_cnt"
  [ngClass]="{
    'accordion_cnt--selected': params.showInfo
  }"
  [style.background-color]="
    params.bgWhite ? 'white' : params.bgPrimary ? '#f0d459' : null
  "
>
  <article
    class="header justify-content-md-left'"
    [ngClass]="{
      'header--border': !params.noDropdown,
      'justify-content-md-between': params.centerHeader
    }"
    (click)="handleAccordion()"
  >
    <div class="title_cnt" *ngIf="!params.noDropdown">
      <h1 *ngIf="!params.showPrincipalInput" class="title_cnt-title">
        {{ params.title }}
        <i
          class="icon_title {{ params.icon }}"
          (click)="triggerIconEvent()"
        ></i>
      </h1>
      <div class="principal_input_cnt" (click)="focusOnPrincipalInput($event)">
        <input
          id="principalSearch"
          *ngIf="!!params.showPrincipalInput"
          type="text"
          class="principal_input_cnt-principal_input"
          pattern="[1-9]"
          placeholder="{{ params.title }}"
          maxlength="10"
          (keypress)="validateNumber($event)"
          (paste)="doSearch($event, '')"
          (blur)="doSearch($event, '')"
          (input)="doSearch($event, 'principalSearch')"
          [(ngModel)]="savedPrincipalInputValue"
        />
        <i
          class="icon-close principal_input_cnt-icon_close"
          (click)="cleanPrincipalInput()"
          *ngIf="writedElement || savedPrincipalInputValue"
        ></i>
      </div>
      <span
        class="info_icon icon-arrow_down"
        [ngClass]="{ 'info_icon--rotate': params.showInfo }"
      ></span>
    </div>
    <div
      class="options_cnt"
      [ngClass]="{ 'options_cnt--centered': !!params.centerTabs }"
      *ngIf="!!params.optionsArray"
    >
      <ul
        class="options"
        *ngFor="let item of params.optionsArray; let i = index"
      >
        <li
          class="options-item"
          [ngClass]="{ 'item--active': item.enabled }"
          (click)="handleTabOption($event, i)"
        >
          {{ item.option }}
          <span *ngIf="item.quantity || item.quantity === 0" class="quantity">{{
            item.quantity
          }}</span>
        </li>
      </ul>
    </div>
  </article>
  <article class="inner_cnt">
    <div
      class="content"
      [ngClass]="{
        'content--visible': params.showInfo
      }"
    >
      <ng-content></ng-content>
    </div>
  </article>
</section>
