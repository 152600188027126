import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constants } from '../shared';

@Injectable({ providedIn: 'root' })
export class PrintService {
  /**
   * Subject dark mode
   */
  private printSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  printData = this.printSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that sets the theme
   */
  setPrintExport(bbl: any): void {
    const data = {
      bbl: bbl,
    };
    this.printSubject.next(data);
  }
}
