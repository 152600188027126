<input
  class="form-check-input"
  type="checkbox"
  [id]="id"
  [name]="id"
  [checked]="checked"
  [disabled]="disabled"
  (change)="onChangeMethod($event)"
/>
<label class="label" [for]="id">
  <ng-content></ng-content>
</label>
