import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SelectUseService {
  /**
   * Subject dark mode
   */
  private selectUseSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  selectUseData = this.selectUseSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that sets the selected use info
   * @param select value selected
   */
  setSelectUseInfo(select?: boolean): void {
    const data = select;
    sessionStorage.setItem('selectUseZaInfo', JSON.stringify(data));
    this.selectUseSubject.next(data);
  }
}
