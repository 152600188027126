<section
  class="snackbar"
  *ngIf="!!params.visible"
  [ngClass]="{
  'snackbar--success': params.type === 'success',
  'snackbar--warning': params.type === 'warning',
  'snackbar--error': params.type === 'error',
  'snackbar--alert': params.type === 'alert',
  'snackbar--center': params.center === true,
}"
  [ngStyle]="{
    top: params.position[1] + 'px',
    left: !!params.center ? params.position[0] + '%' : params.position[0] + 'px'
  }"
>
  <p
    class="message"
    [ngClass]="{
      'message--alert': params.type === 'alert',
      'message--error': params.type === 'error'
    }"
  >
    {{ params.message }}
  </p>
</section>
