import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DisabledElementsExtrude {
  /**
   * Subject dark mode
   */
  private elementSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  elementData = this.elementSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that handles elements to be disabled after extrude
   */
  disabledElement(status: boolean): void {
    const data = {
      loadingGifState: status,
    };
    this.elementSubject.next(data);
  }
}
