import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SnackbarTO } from './snackbar.to';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  @Input() params!: SnackbarTO;
}
