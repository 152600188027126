import { LabelType, Options } from '@angular-slider/ngx-slider';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavBarCloseService } from 'src/app/services/nav-bar-close.service';
import { Constants } from 'src/app/shared';
import { AccordionTO } from 'src/app/ui/accordion/accordion.to';
import { RangeTO } from 'src/app/ui/range/range.to';
import { SelectTO } from 'src/app/ui/select/select.to';
import { SwitchTO } from 'src/app/ui/switch/switch.to';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit {
  /**
   * form group
   */
  form!: FormGroup;

  searchResult: boolean = true;

  /**
   * data list of search Result
   */
  searchResultList = [
    {
      item: 'Lots',
      result: '862',
    },
    {
      item: 'Lot Area',
      result: '4,520,368 sf',
    },
    {
      item: 'Building Area',
      result: '256,5269,876 sf',
    },
    {
      item: 'Assessed Land Value',
      result: '$560,059,289',
    },
    {
      item: 'Assessed Total Value',
      result: '$15,526,358,985',
    },
  ];

  /**
   * Accordion config of advanced search
   */
  mapOptionsAdvancedSearch = {
    title: 'Advanced searchs',
    showInfo: false,
    noDropdown: false,
    bgWhite: true,
  } as AccordionTO;

  /**
   * switch config of borough
   */
  switchBorough = {
    id: 'switchBorough',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of zoning
   */
  switchZoning = {
    id: 'switchZoning',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of zoning
   */
  switchUse = {
    id: 'switchUse',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of split lot
   */
  switchSplitLot = {
    id: 'switchSplitLot',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of flood zone
   */
  switchFloodZone = {
    id: 'switchFloodZone',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of historic district
   */
  switchHistoricDistrict = {
    id: 'switchHistoricDistrict',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of landmark building
   */
  switchLandmarkBuilding = {
    id: 'switchLandmarkBuilding',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of Designations
   */
  switchDesignations = {
    id: 'switchDesignations',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of lot area
   */
  switchLotArea = {
    id: 'switchLotArea',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of build area
   */
  switchBuildingArea = {
    id: 'switchBuildingArea',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of buildable area
   */
  switchBuildableArea = {
    id: 'switchBuildableArea',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of buildable area
   */
  switchExistingArea = {
    id: 'switchExistingArea',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Configuracion de switch buildable area
   */
  switchUnits = {
    id: 'switchUnits',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Configuracion de switch year built
   */
  switchYearBuilt = {
    id: 'switchYearBuilt',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Configuracion de switch floors
   */
  switchFloors = {
    id: 'switchFloors',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Configuracion de switch existing floors
   */
  switchExistingBuildingFloors = {
    id: 'switchExistingBuildingFloors',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Configuracion de switch allowable far
   */
  switchAllowableFar = {
    id: 'switchAllowableFar',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Configuracion de switch built far
   */
  switchBuiltFar = {
    id: 'switchBuiltFar',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Configuracion de switch Building height
   */
  switchBuilding = {
    id: 'switchBuilding',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Configuracion de switch assested land
   */
  switchAssestedLand = {
    id: 'switchAssestedLand',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch config of assested total value
   */
  switchAssestedTotalValue = {
    id: 'switchAssestedTotalValue',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * TO config of campo borough.
   */
  borough: SelectTO = {
    id: 'borough',
    title: 'Borough',
    attrLabel: 'boroughName',
    showInputSearch: false,
    elementList: [
      {
        id: 0,
        name: 'Manhattan',
      },
      {
        id: 1,
        name: 'Brooklyn',
      },
      {
        id: 2,
        name: 'Bronx',
      },
      {
        id: 3,
        name: 'Queens',
      },
      {
        id: 4,
        name: 'Staten Island',
      },
    ],
    disabled: !this.switchBorough.enabled,
    list: [],
  } as SelectTO;

  /**
   * TO config of campo zoning.
   */
  zoning: SelectTO = {
    id: 'zoning',
    title: 'Zoning District',
    attrLabel: 'zoningName',
    showInputSearch: false,
    elementList: [
      {
        id: 0,
        name: 'Residential District',
      },
      {
        id: 1,
        name: 'Commercial District',
      },
      {
        id: 2,
        name: 'Manufacturing District',
      },
    ],
    disabled: !this.switchZoning.enabled,
    list: [],
  } as SelectTO;

  /**
   * TO config of campo zoning.
   */
  use: SelectTO = {
    id: 'use',
    title: 'Use Permitted',
    attrLabel: 'useName',
    showInputSearch: false,
    elementList: [
      {
        id: 0,
        name: 'Residential',
      },
      {
        id: 1,
        name: 'Community Facility',
      },
      {
        id: 2,
        name: 'Commercial ',
      },
      {
        id: 3,
        name: 'Manufacturing ',
      },
    ],
    disabled: !this.switchUse.enabled,
    list: [],
  } as SelectTO;

  /**
   * Range input configuration
   */
  rangeInput: RangeTO = {
    id: 'zoningRange',
    defaultValues: { min: -1, max: 9378 },
    relevantValues: { min: 500, max: 36000 },
    oldSelectionValues: { min: 3380, max: 5190 },
    newSelectionValues: { min: 500, max: 3000 },
    minDefault: -1,
    maxDefault: 9378,
    minRelevant: 500,
    maxrelevant: 36000,
    minOldSelection: 3380,
    maxOldSelection: 5190,
    minNewSelection: 500,
    maxNewSelection: 3000,
  } as RangeTO;

  /**
   * options list of radio split lot
   */
  splitLotRadioList: any[];

  /**
   * options list of radio flood zone
   */
  floodZoneRadioList: any[];

  /**
   * options list of radio historic district
   */
  historicDistrictRadioList: any[];

  /**
   * options list of radio landmark buiilding
   */
  landmarkBuildingRadioList: any[];

  /**
   * options List of radio options
   */
  designationsRadioList: any[];

  //lotArea config

  /**
   * Config de lot area
   */
  lotArea = {
    id: 'lotArea',
    formName: 'lotArea',
    disabled: false,
  };

  /**
   * lot area min value
   */
  lotAreaMinValue: number = 10090;
  /**
   * lot area max value
   */
  lotAreaMaxValue: number = 700000;
  /**
   * lot area range input options
   */
  lotAreaOptions: Options = {
    floor: 0,
    ceil: 1000000,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  //buildingArea config
  /**
   * Config de building area
   */
  buildingArea = {
    id: 'buildingArea',
    formName: 'buildingArea',
    disabled: false,
  };

  /**
   * building area min value
   */
  buildingAreaMinValue: number = 10090;
  /**
   * building area max value
   */
  buildingAreaMaxValue: number = 700000;
  /**
   * building area range input options
   */
  buildingAreaOptions: Options = {
    floor: 0,
    ceil: 1000000,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  //buildableArea config
  /**
   * Config de buildable area
   */
  buildableArea = {
    id: 'buildableArea',
    formName: 'buildableArea',
    disabled: false,
  };

  /**
   * buildable area min value
   */
  buildableAreaMinValue: number = 10090;
  /**
   * building area max value
   */
  buildableAreaMaxValue: number = 700000;
  /**
   * building area range input options
   */
  buildableAreaOptions: Options = {
    floor: 0,
    ceil: 1000000,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  //existingArea config
  /**
   * Config de existing area
   */
  existingArea = {
    id: 'existingArea',
    formName: 'existingArea',
    disabled: false,
  };

  /**
   * existing area min value
   */
  existingAreaMinValue: number = 10090;
  /**
   * existing area max value
   */
  existingAreaMaxValue: number = 700000;
  /**
   * existing area range input options
   */
  existingAreaOptions: Options = {
    floor: 0,
    ceil: 1000000,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  //unitsAllowed config
  /**
   * Config de units area
   */
  units = {
    id: 'units',
    formName: 'units',
    disabled: false,
  };

  /**
   * units  min value
   */
  unitsMinValue = 10090;
  /**
   * units  max value
   */
  unitsMaxValue = 700000;
  /**
   * units  range input options
   */
  unitsOptions: Options = {
    floor: 0,
    ceil: 1000000,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  // Year built

  /**
   * year built config
   */
  yearBuilt = {
    id: 'yearBuilt',
    formName: 'yearBuilt',
    disabled: false,
  };

  /**
   * year built min value
   */
  yearBuiltMinValue: number = 2000;
  /**
   * year built max value
   */
  yearBuiltMaxValue: number = 2022;
  /**
   * year built input range options
   */
  yearBuiltOptions: Options = {
    floor: 1960,
    ceil: 2023,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  // floors

  /**
   * year built config
   */
  floors = {
    id: 'floors',
    formName: 'floors',
    disabled: false,
  };

  /**
   * year built min value
   */
  floorsMinValue: number = 3000;
  /**
   * year built max value
   */
  floorsMaxValue: number = 1020;
  /**
   * year built input range options
   */
  floorsOptions: Options = {
    floor: 1000,
    ceil: 2023,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  // Existing building floors

  /**
   * Existing building floors config
   */
  existingFloors = {
    id: 'existingFloors',
    formName: 'existingFloors',
    disabled: false,
  };

  /**
   * Existing building floors min value
   */
  existingFloorsMinValue: number = 1000;
  /**
   * Existing building floors max value
   */
  existingFloorsMaxValue: number = 1720;
  /**
   * Existing building floors input range options
   */
  existingFloorsOptions: Options = {
    floor: 1000,
    ceil: 2023,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  // Allowable far

  /**
   * Allowable far config
   */
  allowableFar = {
    id: 'allowableFar',
    formName: 'allowableFar',
    disabled: false,
  };

  /**
   * Allowable far min value
   */
  allowableFarMinValue: number = 1100;
  /**
   * Allowable far max value
   */
  allowableFarMaxValue: number = 1900;
  /**
   * Allowable far input range options
   */
  allowableFarOptions: Options = {
    floor: 1000,
    ceil: 2023,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  /**
   * Built far config
   */
  builtFar = {
    id: 'builtFar',
    formName: 'builtFar',
    disabled: false,
  };

  /**
   * Built far min value
   */
  builtFarMinValue: number = 50;
  /**
   * Built far max value
   */
  builtFarMaxValue: number = 200;
  /**
   * Built far input range options
   */
  builtFarOptions: Options = {
    floor: 0,
    ceil: 100000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  //Building Height

  /**
   * Building Height config
   */
  building = {
    id: 'building',
    formName: 'building',
    disabled: false,
  };

  /**
   * Building height min value
   */
  buildingMinValue: number = 1100;
  /**
   * Building height max value
   */
  buildingMaxValue: number = 1900;
  /**
   * Building height input range options
   */
  buildingOptions: Options = {
    floor: 1000,
    ceil: 2023,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  //Assested Land

  /**
   * Assested Land config
   */
  assestedLand = {
    id: 'assestedLand',
    formName: 'assestedLand',
    disabled: false,
  };

  /**
   * assested land min value
   */
  assestedLandMinValue: number = 1100;
  /**
   * assested land max value
   */
  assestedLandMaxValue: number = 1900;
  /**
   * assested land input range options
   */
  assestedLandOptions: Options = {
    floor: 1000,
    ceil: 2023,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  //Assested total value

  /**
   * Assested total value config
   */
  assestedTotalValue = {
    id: 'assestedTotalValue',
    formName: 'assestedTotalValue',
    disabled: false,
  };

  /**
   * Assested total value min value
   */
  assestedTotalValueMinValue: number = 1100;
  /**
   * Assested total value max value
   */
  assestedTotalValueMaxValue: number = 1900;
  /**
   * Assested total value input range options
   */
  assestedTotalValueOptions: Options = {
    floor: 1000,
    ceil: 2023,
    step: 1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '' + value;
        case LabelType.High:
          return '' + value;
        default:
          return '';
      }
    },
  };

  /**
   * Var that store the loadRange value
   */
  loadRange!: boolean;

  /**
   * input that handle reducedContainer flag is active
   */
  @Input() reducedContainer: boolean = false;
  /**
   * input that handle middleContainer flag is active
   */
  @Input() middleContainer: boolean = false;
  /**
   * input that handle adjustElement flag is active
   */
  @Input() adjustElement: boolean = false;
  /**
   * input that handle thememode
   */
  @Input() themeMode!: string;
  /**
   * input that handle validations when secondRow is active
   */
  @Input() secondRowActive: boolean = false;
  /**
   * input that handle validations when twoContainer is active
   */
  @Input() twoContainer: boolean = false;
  /**
   * Output that handles validations when containers are opened
   */
  @Output() validateContainersOpened = new EventEmitter<string>();
  /**
   * inOutputput that handle validations when container is closed
   */
  @Output() closeItem = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder,
    private navBarCloseService: NavBarCloseService
  ) {
    this.splitLotRadioList = [
      {
        id: 'splitLotYes',
        nameRadio: 'splitLotRadio',
        formName: 'splitLotRadio',
        value: '1',
        label: 'Yes',
        disabled: !this.switchSplitLot.enabled,
      },
      {
        id: 'splitLotNo',
        nameRadio: 'splitLotRadio',
        formName: 'splitLotRadio',
        value: '2',
        label: 'No',
        disabled: !this.switchSplitLot.enabled,
      },
    ];

    this.floodZoneRadioList = [
      {
        id: 'floodZoneYes',
        nameRadio: 'floodZoneRadio',
        formName: 'floodZoneRadio',
        value: '1',
        label: 'Yes',
        disabled: !this.switchFloodZone.enabled,
      },
      {
        id: 'floodZoneNo',
        nameRadio: 'floodZoneRadio',
        formName: 'floodZoneRadio',
        value: '2',
        label: 'No',
        disabled: !this.switchFloodZone.enabled,
      },
    ];

    this.historicDistrictRadioList = [
      {
        id: 'historicDistrictYes',
        nameRadio: 'historicDistrictRadio',
        formName: 'historicDistrictRadio',
        value: '1',
        label: 'Yes',
        disabled: !this.switchHistoricDistrict.enabled,
      },
      {
        id: 'historicDistrictNo',
        nameRadio: 'historicDistrictRadio',
        formName: 'historicDistrictRadio',
        value: '2',
        label: 'No',
        disabled: !this.switchHistoricDistrict.enabled,
      },
    ];

    this.landmarkBuildingRadioList = [
      {
        id: 'landmarkBuildingYes',
        nameRadio: 'landmarkBuildingRadio',
        formName: 'landmarkBuildingRadio',
        value: '1',
        label: 'Yes',
        disabled: !this.switchLandmarkBuilding.enabled,
      },
      {
        id: 'landmarkBuildingNo',
        nameRadio: 'landmarkBuildingRadio',
        formName: 'landmarkBuildingRadio',
        value: '2',
        label: 'No',
        disabled: !this.switchLandmarkBuilding.enabled,
      },
    ];

    this.designationsRadioList = [
      {
        id: 'designationsYes',
        nameRadio: 'enviromentDesignationsRadio',
        formName: 'enviromentDesignationsRadio',
        value: '1',
        label: 'Yes',
        disabled: !this.switchDesignations.enabled,
      },
      {
        id: 'designationsNo',
        nameRadio: 'enviromentDesignationsRadio',
        formName: 'enviromentDesignationsRadio',
        value: '2',
        label: 'No',
        disabled: !this.switchDesignations.enabled,
      },
    ];
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      switchBorough: [],
      borough: [null, [Validators.required]],
      switchZoning: [],
      switchUse: [],
      zoning: [null, [Validators.required]],
      switchSplitLot: [],
      splitLotRadio: [],
      switchFloodZone: [],
      floodZoneRadio: [],
      switchHistoricDistrict: [],
      historicDistrictRadio: [],
      switchLandmarkBuilding: [],
      switchDesignations: [],
      landmarkBuildingRadio: [],
      enviromentDesignationsRadio: [],
      switchLotArea: [],
      switchBuildingArea: [],
      switchYearBuilt: [],
      switchFloors: [],
      switchExistingBuildingFloors: [],
      switchBuildableArea: [],
      switchExistingArea: [],
      switchUnits: [],
      switchAllowableFar: [],
      switchBuiltFar: [],
      switchBuilding: [],
      switchAssestedLand: [],
      switchAssestedTotalValue: [],
    });
    this.borough.form = this.form;
    this.zoning.form = this.form;
    this.switchBorough.form = this.form;
    this.switchZoning.form = this.form;
    this.switchUse.form = this.form;
    this.switchSplitLot.form = this.form;
    this.switchFloodZone.form = this.form;
    this.switchHistoricDistrict.form = this.form;
    this.switchLandmarkBuilding.form = this.form;
    this.switchDesignations.form = this.form;
    this.switchLotArea.form = this.form;
    this.switchBuildingArea.form = this.form;
    this.switchBuildableArea.form = this.form;
    this.switchExistingArea.form = this.form;
    this.switchUnits.form = this.form;
    this.switchYearBuilt.form = this.form;
    this.switchFloors.form = this.form;
    this.switchExistingBuildingFloors.form = this.form;
    this.switchAllowableFar.form = this.form;
    this.switchBuiltFar.form = this.form;
    this.switchBuilding.form = this.form;
    this.switchAssestedLand.form = this.form;
    this.switchAssestedTotalValue.form = this.form;
    this.setTogglesOnInit();
  }

  setTogglesOnInit() {
    const switchBorrough = sessionStorage.getItem('switchBoroughAdvSrch');
    if (switchBorrough === 'true') {
      this.form.controls[this.switchBorough.id].setValue(true);
      this.switchBoroughToggle(null, true);
    } else {
      this.form.controls[this.switchBorough.id].setValue(false);
    }

    const switchZoning = sessionStorage.getItem('switchZoningAdvSrch');
    if (switchZoning === 'true') {
      this.form.controls[this.switchZoning.id].setValue(true);
      this.switchZoningToggle(null, true);
    } else {
      this.form.controls[this.switchZoning.id].setValue(false);
    }

    const switchUse = sessionStorage.getItem('switchUseAdvSrch');
    if (switchUse === 'true') {
      this.form.controls[this.switchUse.id].setValue(true);
      this.switchUseToggle(null, true);
    } else {
      this.form.controls[this.switchUse.id].setValue(false);
    }

    const switchSplitLot = sessionStorage.getItem('switchSplitLotAdvSrch');
    const splitLotRadioSelected = sessionStorage.getItem('splitLotRadio');
    if (switchSplitLot === 'true') {
      this.form.controls[this.switchSplitLot.id].setValue(true);
      this.form.controls['splitLotRadio'].setValue(splitLotRadioSelected);
      this.switchSplitLotToggle(null, true);
    } else {
      this.form.controls[this.switchSplitLot.id].setValue(false);
    }

    const switchFloodZone = sessionStorage.getItem('switchFloodZoneAdvSrch');
    const floodZoneRadioSelected = sessionStorage.getItem('floodZoneRadio');
    if (switchFloodZone === 'true') {
      this.form.controls[this.switchFloodZone.id].setValue(true);
      this.form.controls['floodZoneRadio'].setValue(floodZoneRadioSelected);
      this.switchFloodZoneToggle(null, true);
    } else {
      this.form.controls[this.switchFloodZone.id].setValue(false);
    }

    const switchHistoricDistrict = sessionStorage.getItem(
      'switchHistoricDistrictAdvSrch'
    );
    const historicDistrictRadioSelected = sessionStorage.getItem(
      'historicDistrictRadio'
    );
    if (switchHistoricDistrict === 'true') {
      this.form.controls[this.switchHistoricDistrict.id].setValue(true);
      this.form.controls['historicDistrictRadio'].setValue(
        historicDistrictRadioSelected
      );
      this.switchHistoricDistrictToggle(null, true);
    } else {
      this.form.controls[this.switchHistoricDistrict.id].setValue(false);
    }

    const switchLandmarkBuilding = sessionStorage.getItem(
      'switchLandmarkBuildingAdvSrch'
    );
    const landmarkBuildingRadioSelected = sessionStorage.getItem(
      'landmarkBuildingRadio'
    );
    if (switchLandmarkBuilding === 'true') {
      this.form.controls[this.switchLandmarkBuilding.id].setValue(true);
      this.form.controls['landmarkBuildingRadio'].setValue(
        landmarkBuildingRadioSelected
      );
      this.switchLandmarkBuildingToggle(null, true);
    } else {
      this.form.controls[this.switchLandmarkBuilding.id].setValue(false);
    }

    const switchDesignations = sessionStorage.getItem(
      'switchDesignationsAdvSrch'
    );
    const eDesignationsRadioSelected = sessionStorage.getItem(
      'enviromentDesignationsRadio'
    );

    if (switchDesignations === 'true') {
      this.form.controls[this.switchDesignations.id].setValue(true);
      this.form.controls['enviromentDesignationsRadio'].setValue(
        eDesignationsRadioSelected
      );
      this.switchDesignationsToggle(null, true);
    } else {
      this.form.controls[this.switchDesignations.id].setValue(false);
    }

    const switchLotArea = sessionStorage.getItem('switchLotAreaAdvSrch');
    if (switchLotArea === 'true') {
      this.form.controls[this.switchLotArea.id].setValue(true);
      this.switchLotAreaToggle(null, true);
    } else {
      this.form.controls[this.switchLotArea.id].setValue(false);
    }

    const switchBuildingArea = sessionStorage.getItem(
      'switchBuildingAreaAdvSrch'
    );
    if (switchBuildingArea === 'true') {
      this.form.controls[this.switchBuildingArea.id].setValue(true);
      this.switchBuildingAreaToggle(null, true);
    } else {
      this.form.controls[this.switchBuildingArea.id].setValue(false);
    }

    const switchBuildableArea = sessionStorage.getItem(
      'switchBuildableAreaAdvSrch'
    );
    if (switchBuildableArea === 'true') {
      this.form.controls[this.switchBuildableArea.id].setValue(true);
      this.switchBuildableAreaToggle(null, true);
    } else {
      this.form.controls[this.switchBuildableArea.id].setValue(false);
    }

    const switchExistingArea = sessionStorage.getItem(
      'switchExistingAreaAdvSrch'
    );
    if (switchExistingArea === 'true') {
      this.form.controls[this.switchExistingArea.id].setValue(true);
      this.switchExistingAreaToggle(null, true);
    } else {
      this.form.controls[this.switchExistingArea.id].setValue(false);
    }

    const switchUnits = sessionStorage.getItem('switchUnitsAdvSrch');
    if (switchUnits === 'true') {
      this.form.controls[this.switchUnits.id].setValue(true);
      this.switchUnitsToggle(null, true);
    } else {
      this.form.controls[this.switchUnits.id].setValue(false);
    }

    const switchYearBuilt = sessionStorage.getItem('switchYearBuiltAdvSrch');
    if (switchYearBuilt === 'true') {
      this.form.controls[this.switchYearBuilt.id].setValue(true);
      this.switchYearBuiltToggle(null, true);
    } else {
      this.form.controls[this.switchYearBuilt.id].setValue(false);
    }

    const switchFloors = sessionStorage.getItem('switchFloorsAdvSrch');
    if (switchFloors === 'true') {
      this.form.controls[this.switchFloors.id].setValue(true);
      this.switchFloorsToggle(null, true);
    } else {
      this.form.controls[this.switchFloors.id].setValue(false);
    }

    const switchExistingBuildingFloors = sessionStorage.getItem(
      'switchExistingBuildingFloorsAdvSrch'
    );
    if (switchExistingBuildingFloors === 'true') {
      this.form.controls[this.switchExistingBuildingFloors.id].setValue(true);
      this.switchExistingBuildingFloorsToggle(null, true);
    } else {
      this.form.controls[this.switchExistingBuildingFloors.id].setValue(false);
    }

    const switchAllowableFar = sessionStorage.getItem(
      'switchAllowableFarAdvSrch'
    );
    if (switchAllowableFar === 'true') {
      this.form.controls[this.switchAllowableFar.id].setValue(true);
      this.switchAllowableFarToggle(null, true);
    } else {
      this.form.controls[this.switchAllowableFar.id].setValue(false);
    }

    const switchBuiltFar = sessionStorage.getItem('switchBuiltFarAdvSrch');
    if (switchBuiltFar === 'true') {
      this.form.controls[this.switchBuiltFar.id].setValue(true);
      this.switchBuiltFarToggle(null, true);
    } else {
      this.form.controls[this.switchBuiltFar.id].setValue(false);
    }

    const switchBuilding = sessionStorage.getItem('switchBuildingAdvSrch');
    if (switchBuilding === 'true') {
      this.form.controls[this.switchBuilding.id].setValue(true);
      this.switchBuildingToggle(null, true);
    } else {
      this.form.controls[this.switchBuilding.id].setValue(false);
    }

    const switchAssestedLand = sessionStorage.getItem(
      'switchAssestedLandAdvSrch'
    );
    if (switchAssestedLand === 'true') {
      this.form.controls[this.switchAssestedLand.id].setValue(true);
      this.switchAssestedLandToggle(null, true);
    } else {
      this.form.controls[this.switchAssestedLand.id].setValue(false);
    }

    const switchAssestedTotalValue = sessionStorage.getItem(
      'switchAssestedTotalValueAdvSrch'
    );
    if (switchAssestedTotalValue === 'true') {
      this.form.controls[this.switchAssestedTotalValue.id].setValue(true);
      this.switchAssestedTotalValueToggle(null, true);
    } else {
      this.form.controls[this.switchAssestedTotalValue.id].setValue(false);
    }
  }

  /**
   * Method that handles the switch borough
   * @param $event boolean
   */
  switchBoroughToggle($event?: any, switchEvent?: boolean) {
    this.switchBorough.enabled = $event || switchEvent;
    if (this.switchBorough.enabled) {
      this.borough.disabled = false;
      sessionStorage.setItem('switchBoroughAdvSrch', $event || switchEvent);
    } else {
      this.borough.disabled = true;
      this.borough.expanded = false;
      sessionStorage.setItem('switchBoroughAdvSrch', '');
    }
  }

  /**
   * Method that handle the selection of switch zoning
   * @param $event boolean
   */
  switchZoningToggle($event?: any, switchEvent?: boolean) {
    this.switchZoning.enabled = $event || switchEvent;
    if (this.switchZoning.enabled) {
      sessionStorage.setItem('switchZoningAdvSrch', $event || switchEvent);
      this.zoning.disabled = false;
    } else {
      this.zoning.disabled = true;
      this.zoning.expanded = false;
      sessionStorage.setItem('switchZoningAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch zoning
   * @param $event boolean
   */
  switchUseToggle($event?: any, switchEvent?: boolean) {
    this.switchUse.enabled = $event || switchEvent;
    if (this.switchUse.enabled) {
      sessionStorage.setItem('switchUseAdvSrch', $event || switchEvent);
      this.use.disabled = false;
    } else {
      this.use.disabled = true;
      this.use.expanded = false;
      sessionStorage.setItem('switchUseAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch zoning
   * @param $event boolean
   */
  switchSplitLotToggle($event: any, switchEvent?: boolean) {
    const splitLotRadioValue = sessionStorage.getItem('splitLotRadio');
    this.switchSplitLot.enabled = $event || switchEvent;
    if (!splitLotRadioValue) {
      this.form.controls['splitLotRadio'].setValue('1');
    } else {
      this.form.controls['splitLotRadio'].setValue(splitLotRadioValue);
    }
    if (this.switchSplitLot.enabled) {
      sessionStorage.setItem('switchSplitLotAdvSrch', $event || switchEvent);
    } else {
      sessionStorage.setItem('switchSplitLotAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch zoning
   * @param $event boolean
   */
  switchFloodZoneToggle($event?: any, switchEvent?: boolean) {
    const floodZoneRadioValue = sessionStorage.getItem('floodZoneRadio');
    this.switchFloodZone.enabled = $event || switchEvent;
    if (!floodZoneRadioValue) {
      this.form.controls['floodZoneRadio'].setValue('1');
    } else {
      this.form.controls['floodZoneRadio'].setValue(floodZoneRadioValue);
    }
    if (this.switchFloodZone.enabled) {
      sessionStorage.setItem('switchFloodZoneAdvSrch', $event || switchEvent);
    } else {
      sessionStorage.setItem('switchFloodZoneAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch zoning
   * @param $event boolean
   */
  switchHistoricDistrictToggle($event: any, switchEvent?: boolean) {
    const historicDistrictRadioValue = sessionStorage.getItem(
      'historicDistrictRadio'
    );
    this.switchHistoricDistrict.enabled = $event || switchEvent;
    if (!historicDistrictRadioValue) {
      this.form.controls['historicDistrictRadio'].setValue('1');
    } else {
      this.form.controls['historicDistrictRadio'].setValue(
        historicDistrictRadioValue
      );
    }
    if (this.switchHistoricDistrict.enabled) {
      sessionStorage.setItem(
        'switchHistoricDistrictAdvSrch',
        $event || switchEvent
      );
    } else {
      sessionStorage.setItem('switchHistoricDistrictAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch zoning
   * @param $event boolean
   */
  switchLandmarkBuildingToggle($event: any, switchEvent?: boolean) {
    const landmarkBuildingRadioValue = sessionStorage.getItem(
      'landmarkBuildingRadio'
    );
    this.switchLandmarkBuilding.enabled = $event || switchEvent;
    if (!landmarkBuildingRadioValue) {
      this.form.controls['landmarkBuildingRadio'].setValue('1');
    } else {
      this.form.controls['landmarkBuildingRadio'].setValue(
        landmarkBuildingRadioValue
      );
    }
    if (this.switchLandmarkBuilding.enabled) {
      sessionStorage.setItem(
        'switchLandmarkBuildingAdvSrch',
        $event || switchEvent
      );
    } else {
      sessionStorage.setItem('switchLandmarkBuildingAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch zoning
   * @param $event boolean
   */
  switchDesignationsToggle($event: any, switchEvent?: boolean) {
    const eDesignationsRadioValue = sessionStorage.getItem(
      'enviromentDesignationsRadio'
    );
    this.switchDesignations.enabled = $event || switchEvent;
    if (!eDesignationsRadioValue) {
      this.form.controls['enviromentDesignationsRadio'].setValue('1');
    } else {
      this.form.controls['enviromentDesignationsRadio'].setValue(
        eDesignationsRadioValue
      );
    }
    if (this.switchDesignations.enabled) {
      sessionStorage.setItem(
        'switchDesignationsAdvSrch',
        $event || switchEvent
      );
    } else {
      sessionStorage.setItem('switchDesignationsAdvSrch', '');
    }
  }
  updateInputMinAccordingType(event: any, type: string) {
    if (event.target.value) {
      switch (type) {
        case 'units':
          this.unitsMinValue = Number(event.target.value);
          break;

        case 'lotArea':
          this.lotAreaMinValue = Number(event.target.value);
          break;

        case 'buildableArea':
          this.buildableAreaMinValue = Number(event.target.value);
          break;

        case 'existingArea':
          this.existingAreaMinValue = Number(event.target.value);
          break;

        case 'allowableFar':
          this.allowableFarMinValue = Number(event.target.value);
          break;

        case 'builtFar':
          this.builtFarMinValue = Number(event.target.value);
          break;

        case 'buildingArea':
          this.buildingAreaMinValue = Number(event.target.value);
          break;

        case 'buildingHeight':
          this.buildingMinValue = Number(event.target.value);
          break;

        case 'floors':
          this.floorsMinValue = Number(event.target.value);
          break;

        case 'years':
          this.yearBuiltMinValue = Number(event.target.value);
          break;

        case 'assestedLand':
          this.assestedLandMinValue = Number(event.target.value);
          break;

        case 'assestedTotal':
          this.assestedTotalValueMinValue = Number(event.target.value);
          break;

        default:
          break;
      }
    }
  }
  /**
   * Method that updates the input max value according type
   * @param event range event
   * @param type type
   */
  updateInputMaxAccordingType(event: any, type: string) {
    if (event.target.value) {
      switch (type) {
        case 'units':
          this.unitsMaxValue = Number(event.target.value);
          break;

        case 'lotArea':
          this.lotAreaMaxValue = Number(event.target.value);
          break;

        case 'buildableArea':
          this.buildableAreaMaxValue = Number(event.target.value);
          break;

        case 'existingArea':
          this.existingAreaMaxValue = Number(event.target.value);
          break;

        case 'allowableFar':
          this.allowableFarMaxValue = Number(event.target.value);
          break;

        case 'builtFar':
          this.builtFarMaxValue = Number(event.target.value);
          break;

        case 'buildingArea':
          this.buildingAreaMaxValue = Number(event.target.value);
          break;

        case 'buildingHeight':
          this.buildingMaxValue = Number(event.target.value);
          break;

        case 'floors':
          this.floorsMaxValue = Number(event.target.value);
          break;

        case 'years':
          this.yearBuiltMaxValue = Number(event.target.value);
          break;

        case 'assestedLand':
          this.assestedLandMaxValue = Number(event.target.value);
          break;

        case 'assestedTotal':
          this.assestedTotalValueMaxValue = Number(event.target.value);
          break;

        default:
          break;
      }
    }
  }

  /**
   * Method that handles the switch lot area
   * @param $event boolean
   */
  switchLotAreaToggle($event?: any, switchEvent?: boolean) {
    this.switchLotArea.enabled = $event || switchEvent;
    if (this.switchLotArea.enabled) {
      sessionStorage.setItem('switchLotAreaAdvSrch', $event || switchEvent);
      this.lotArea.disabled = false;
    } else {
      this.lotArea.disabled = true;
      sessionStorage.setItem('switchLotAreaAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch building area
   * @param $event boolean
   */
  switchBuildingAreaToggle($event?: any, switchEvent?: boolean) {
    this.switchBuildingArea.enabled = $event || switchEvent;
    if (this.switchBuildingArea.enabled) {
      sessionStorage.setItem(
        'switchBuildingAreaAdvSrch',
        $event || switchEvent
      );
      this.buildingArea.disabled = false;
    } else {
      this.buildingArea.disabled = true;
      sessionStorage.setItem('switchBuildingAreaAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch buildiable area
   * @param $event boolean
   */
  switchBuildableAreaToggle($event: any, switchEvent?: boolean) {
    this.switchBuildableArea.enabled = $event || switchEvent;
    if (this.switchBuildableArea.enabled) {
      sessionStorage.setItem(
        'switchBuildableAreaAdvSrch',
        $event || switchEvent
      );
      this.buildableArea.disabled = false;
    } else {
      this.buildableArea.disabled = true;
      sessionStorage.setItem('switchBuildableAreaAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch buildiable area
   * @param $event boolean
   */
  switchExistingAreaToggle($event: any, switchEvent?: boolean) {
    this.switchExistingArea.enabled = $event || switchEvent;
    if (this.switchExistingArea.enabled) {
      sessionStorage.setItem(
        'switchExistingAreaAdvSrch',
        $event || switchEvent
      );
      this.existingArea.disabled = false;
    } else {
      this.existingArea.disabled = true;
      sessionStorage.setItem('switchExistingAreaAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch buildiable area
   * @param $event boolean
   */
  switchUnitsToggle($event: any, switchEvent?: boolean) {
    this.switchUnits.enabled = $event || switchEvent;
    if (this.switchUnits.enabled) {
      sessionStorage.setItem('switchUnitsAdvSrch', $event || switchEvent);
      this.units.disabled = false;
    } else {
      this.units.disabled = true;
      sessionStorage.setItem('switchUnitsAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch yearBuilt
   * @param $event boolean
   */
  switchYearBuiltToggle($event: any, switchEvent?: boolean) {
    this.switchYearBuilt.enabled = $event || switchEvent;
    if (this.switchYearBuilt.enabled) {
      sessionStorage.setItem('switchYearBuiltAdvSrch', $event || switchEvent);
      this.yearBuilt.disabled = false;
    } else {
      this.yearBuilt.disabled = true;
      sessionStorage.setItem('switchYearBuiltAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch number of floors
   * @param $event boolean
   */
  switchFloorsToggle($event?: any, switchEvent?: boolean) {
    this.switchFloors.enabled = $event || switchEvent;
    if (this.switchFloors.enabled) {
      sessionStorage.setItem('switchFloorsAdvSrch', $event || switchEvent);
      this.floors.disabled = false;
    } else {
      this.floors.disabled = true;
      sessionStorage.setItem('switchFloorsAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch existing floors
   * @param $event boolean
   */
  switchExistingBuildingFloorsToggle($event: any, switchEvent?: boolean) {
    this.switchExistingBuildingFloors.enabled = $event || switchEvent;
    if (this.switchExistingBuildingFloors.enabled) {
      sessionStorage.setItem(
        'switchExistingBuildingFloorsAdvSrch',
        $event || switchEvent
      );
      this.existingFloors.disabled = false;
    } else {
      this.existingFloors.disabled = true;
      sessionStorage.setItem('switchExistingBuildingFloorsAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch Allowable Far
   * @param $event boolean
   */
  switchAllowableFarToggle($event?: any, switchEvent?: boolean) {
    this.switchAllowableFar.enabled = $event || switchEvent;
    if (this.switchAllowableFar.enabled) {
      sessionStorage.setItem(
        'switchAllowableFarAdvSrch',
        $event || switchEvent
      );
      this.allowableFar.disabled = false;
    } else {
      this.allowableFar.disabled = true;
      sessionStorage.setItem('switchAllowableFarAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch Built Far
   * @param $event boolean
   */
  switchBuiltFarToggle($event: any, switchEvent?: boolean) {
    this.switchBuiltFar.enabled = $event || switchEvent;
    if (this.switchBuiltFar.enabled) {
      sessionStorage.setItem('switchBuiltFarAdvSrch', $event || switchEvent);
      this.builtFar.disabled = false;
    } else {
      this.builtFar.disabled = true;
      sessionStorage.setItem('switchBuiltFarAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch available Far
   * @param $event boolean
   */
  switchBuildingToggle($event: any, switchEvent?: boolean) {
    this.switchBuilding.enabled = $event || switchEvent;
    if (this.switchBuilding.enabled) {
      sessionStorage.setItem('switchBuildingAdvSrch', $event || switchEvent);
      this.building.disabled = false;
    } else {
      this.building.disabled = true;
      sessionStorage.setItem('switchBuildingAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch assested land
   * @param $event boolean
   */
  switchAssestedLandToggle($event: any, switchEvent?: boolean) {
    this.switchAssestedLand.enabled = $event || switchEvent;
    if (this.switchAssestedLand.enabled) {
      sessionStorage.setItem(
        'switchAssestedLandAdvSrch',
        $event || switchEvent
      );
      this.assestedLand.disabled = false;
    } else {
      this.assestedLand.disabled = true;
      sessionStorage.setItem('switchAssestedLandAdvSrch', '');
    }
  }

  /**
   * Method that handles the switch assested total value
   * @param $event boolean
   */
  switchAssestedTotalValueToggle($event: any, switchEvent?: boolean) {
    this.switchAssestedTotalValue.enabled = $event || switchEvent;
    if (this.switchAssestedTotalValue.enabled) {
      sessionStorage.setItem(
        'switchAssestedTotalValueAdvSrch',
        $event || switchEvent
      );
      this.assestedTotalValue.disabled = false;
    } else {
      this.assestedTotalValue.disabled = true;
      sessionStorage.setItem('switchAssestedTotalValueAdvSrch', '');
    }
  }

  handlererRadio(event: any) {
    switch (event.name) {
      case 'splitLotRadio':
        sessionStorage.setItem('splitLotRadio', event.value);
        break;

      case 'floodZoneRadio':
        sessionStorage.setItem('floodZoneRadio', event.value);
        break;

      case 'historicDistrictRadio':
        sessionStorage.setItem('historicDistrictRadio', event.value);
        break;

      case 'landmarkBuildingRadio':
        sessionStorage.setItem('landmarkBuildingRadio', event.value);
        break;

      case 'enviromentDesignationsRadio':
        sessionStorage.setItem('enviromentDesignationsRadio', event.value);
        break;

      default:
        break;
    }
  }

  /**
   * Method that enables ranges
   * @param event
   */
  enableRanges(event: boolean) {
    this.loadRange = event;
  }

  /**
   * Method that emit value when container is opened
   */
  validateContainer() {
    this.validateContainersOpened.emit('AdvancedSearch');
  }

  /**
   * Method that expands the selected container
   * @param event event value
   * @param type  type value
   */
  expandSelectedElement(event: any, type: string) {
    switch (type) {
      case Constants.BOROUGH:
        this.zoning.expanded = false;
        this.use.expanded = false;
        if (!event && !this.borough.expanded) {
          this.borough.expanded = true;
        } else {
          this.borough.expanded = event;
        }

        break;
      case Constants.ZONING:
        this.borough.expanded = false;
        this.use.expanded = false;
        if (!event && !this.zoning.expanded) {
          this.zoning.expanded = true;
        } else {
          this.zoning.expanded = event;
        }

        break;

      case Constants.USE:
        this.borough.expanded = false;
        this.zoning.expanded = false;
        if (!event && !this.use.expanded) {
          this.use.expanded = true;
        } else {
          this.use.expanded = event;
        }

        break;

      default:
        break;
    }
  }

  /**
   * Method that handle the selected element
   * @param event event value
   * @param type  type value
   */
  handleElementSelected(event: any, type: string) {
    switch (type) {
      case Constants.BOROUGH:
        this.borough.title = event;
        this.borough.expanded = false;

        break;

      case Constants.ZONING:
        this.zoning.title = event;
        this.zoning.expanded = false;

        break;

      case Constants.USE:
        this.use.title = event;
        this.use.expanded = false;

        break;

      default:
        break;
    }
  }

  /**
   * Method that close the advanced search container
   */
  close() {
    this.navBarCloseService.turnOffOption(Constants.ADVANCED_SEARCH);
    this.closeItem.emit(Constants.ADVANCED_SEARCH);
  }

  /**
   * Listener event that listen to when any click is done outside the box
   * @param event event listener
   */
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (
      !!this.borough.expanded &&
      event.srcElement.className !== '' &&
      !event.srcElement.className.includes('header') &&
      !event.srcElement.className.includes('list-item') &&
      !event.srcElement.className.includes('map') &&
      !event.srcElement.className.includes('info_cnt') &&
      !event.srcElement.className.includes('background_label') &&
      !event.srcElement.className.includes('info_icon') &&
      !event.srcElement.className.includes('input') &&
      !event.srcElement.className.includes('inner_input_cnt') &&
      !event.srcElement.className.includes('input_lot') &&
      !event.srcElement.className.includes('btn_search')
    ) {
      this.borough.expanded = false;
    }
  }
}
