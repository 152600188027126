import { BehaviorSubject } from 'rxjs';
import { DarkModeConfig } from '../models';
import { Injectable } from '@angular/core';
import { Constants } from '../shared';

@Injectable({ providedIn: 'root' })
export class DarkModeService {
  /**
   * Subject dark mode
   */
  private darkModeSubject = new BehaviorSubject<DarkModeConfig>(null as any);

  /**
   * stateObservable
   */
  darkModeData = this.darkModeSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that sets the theme
   */
  setThemeMode(): void {
    const data = new DarkModeConfig();
    data.activeTheme = sessionStorage.getItem('theme') || Constants.DARK;
    this.darkModeSubject.next(data);
  }
}
