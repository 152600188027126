export class Constants {
  //options
  static readonly ADDRESS_SEARCH = 'Address Search';
  static readonly PROPERTY_SEARCH = 'Property Search';
  static readonly ADVANCED_SEARCH = 'Advanced Search';
  static readonly MANAGE_LAYERS = 'Manage Layers';
  static readonly ZONING_DATA = 'Zoning Data';
  static readonly ZONING_ANALYSIS = 'Zoning Analysis';
  static readonly AREA_TABULATION = 'Area Tabulation';
  static readonly SAVE_ALL = 'Save all';
  static readonly PRINT = 'Print';
  static readonly FOLDER = 'Folder';
  static readonly SETTINGS = 'Settings';
  static readonly USER = 'User';

  //Select elements
  static readonly BOROUGH = 'Borough';
  static readonly ZONING = 'Zoning';
  static readonly USE = 'Use';

  //Theme
  static readonly DARK = 'dark';
  static readonly LIGHT = 'light';

  //Manage Layers
  static readonly TAXLOT = 'taxLot';
  static readonly ZONINGDISTRICT = 'zoningDistrict';
  static readonly HISTORIC_DISTRICT = 'historicDistrict';
  static readonly HISTORIC_DISTRICT_LABELS = 'Historict_District_l';
  static readonly HISTORIC_DISTRICT_OUTLINES = 'Historict_District_o';
  static readonly COMMUNITY_DISTRICT = 'communityDistrict';
  static readonly COMMUNITY_DISTRICT_LABELS_ID = 'community_districts_l';
  static readonly TOPOGRAPHY = 'topography';
  static readonly COMMERCIAL_OVERLAY = 'commercialOverlay';
  static readonly LANDMARK_SITE = 'landmarkSite';
  static readonly SUBWAY_LINES = 'subwayLines';
  static readonly SUBWAY_LINES_STATIONS_LABELS = 'subway_stations_l';
  static readonly SPECIAL_DISTRICT = 'specialDistrict';
  static readonly SPECIAL_SUBDISTRICT = 'specialSubDistrict';
  static readonly E_DESIGNATIONS = 'environmentalDesignations';
  static readonly LIMITED_HEIGHT_DISTRICT = 'limitedHeightDistrict';
  static readonly MIH = 'mih';
  static readonly MIH_ID = 'MIH';
  static readonly MIH_OUTLINE = 'MIH_o';
  static readonly IHDA = 'ihda';
  static readonly IHDA_ID = 'IHDA';
  static readonly IHDA_OUTLINE = 'IHDA_o';
  static readonly TOPOGRAPHY_ID = 'contour_4326';
  static readonly TOPOGRAPHY_LABELS_ID = 'contour_4326_labels';
  static readonly BOROUGHS = 'boroughs';
  static readonly BOROUGHS_ID = 'borough_boundaries';
  static readonly BOROUGHS_LABELS_ID = 'borough_boundaries_l';
  static readonly TAXLOT_ID = 'taxlot';
  static readonly TAXLOT_OUTLINE_ID = 'taxlot_landuse_o';
  static readonly TAXLOT_LANDUSE_ID = 'taxlot_landuse';
  static readonly LOTS_FACE_ID = 'lot_face_labels';
  static readonly LOT_OUTLINE = 'lot_outline';
  static readonly LOT_SELECT = 'lot_select';
  static readonly LOT_LABELS = 'lot_labels';
  static readonly LOTS = 'lots';
  static readonly SUBWAY_LINES_ID = 'Subway_Lines-0ovpqb';
  static readonly SUBWAY_LINES_STATIONS_ID = 'Subway_Stations-0g0jn1';
  static readonly PRELIMINARY_FLOODZONE = 'preliminaryFloodZone';
  static readonly EFFECTIVE_FLOODZONE = 'floodZone';
  static readonly PRELIMINAR_FLOOD_A_ID = 'Preliminary_Flood_a';
  static readonly PRELIMINAR_FLOOD_V_ID = 'Preliminary_Flood_v';
  static readonly PRELIMINAR_FLOOD_X_ID = 'Preliminary_Flood_x';
  static readonly EFFECTIVE_FLOOD_V_ID = 'Effective_Flood_v';
  static readonly EFFECTIVE_FLOOD_X_ID = 'Effective_Flood_x';
  static readonly EFFECTIVE_FLOOD_A_ID = 'Effective_Flood_a';
  static readonly COMMERCIAL_OVERLAY_C1_ID = 'Commercial_Overlay_c1_1';
  static readonly COMMERCIAL_OVERLAY_C1_LABELS_ID =
    'Commercial_Overlay_c1_1_labels';
  static readonly COMMERCIAL_OVERLAY_C2_ID = 'Commercial_Overlay_c2_1';
  static readonly COMMERCIAL_OVERLAY_C2_LABELS_ID =
    'Commercial_Overlay_c2_1_labels';
  static readonly HISTORIC_DISTRICT_ID = 'Historict_District';
  static readonly SPECIAL_PURPOSE_DISCTRICT_ID = 'Special_Purpose_District';
  static readonly SPECIAL_PURPOSE_DISCTRICT_OUTLINE_ID =
    'Special_Purpose_District_outline';
  static readonly SPECIAL_PURPOSE_DISCTRICT_LABEL =
    'Special_Purpose_District_l';
  static readonly SPECIAL_PURPOSE_SUBDISCTRICT_ID =
    'Special_Purpose_Subdistrict';
  static readonly SPECIAL_PURPOSE_SUBDISCTRICT_OUTLINE_ID =
    'Special_Purpose_Subdistrict_outline';
  static readonly SPECIAL_PURPOSE_SUBDISCTRICT_LABEL =
    'Special_Purpose_Subdistrict_l';
  static readonly ZONING_DISTRICT_R = 'zoning_districts_r';
  static readonly ZONING_DISTRICT_C = 'zoning_districts_c';
  static readonly ZONING_DISTRICT_M = 'zoning_districts_m';
  static readonly ZONING_DISTRICT_P = 'zoning_districts_p';
  static readonly ZONING_DISTRICT_B = 'zoning_districts_b';
  static readonly ZONING_DISTRIC_B_OUTLINE = 'zoning_districts_bo';
  static readonly ZONING_DISTRICTS_B_LABELS = 'zoning_districts_b_labels';
  static readonly ZONING_DISTRICS_P_OUTLINE = 'zoning_districts_po';
  static readonly ZONING_DISTRICTS_P_LABELS = 'zoning_districts_p_labels';
  static readonly ZONING_DISTRICTS_R_OUTLINE = 'zoning_districts_ro';
  static readonly ZONING_DISTRICTS_R_LABELS = 'zoning_districts_r_labels';
  static readonly ZONING_DISTRICTS_M_OUTLINE = 'zoning_districts_mo';
  static readonly ZONING_DISTRICTS_M_LABELS = 'zoning_districts_m_labels';
  static readonly ZONING_DISTRICTS_C_OUTLINE = 'zoning_districts_co';
  static readonly ZONING_DISTRICTS_C_LABELS = 'zoning_districts_c_labels';
  static readonly LANDMARK_SITES_ID = 'Landmark_Sites-8qhywx';
  static readonly EDESIGNATION_ID = 'E_Designation-1memwl';
  static readonly LIMITED_HEIGHT_DISTRICTS_ID = 'Limited_Height_Districts';
  static readonly LIMITED_HEIGHT_DISTRICTS_LABELS =
    'Limited_Height_Districts_l';
  static readonly LIMITED_HEIGHT_DISTRICTS_OUTLINE =
    'Limited_Height_Districts_o';
  static readonly COMMUNITY_DISTRICT_ID = 'community_districts';

  //Air Rights
  static readonly TRANSFERABLE_DEVELOPMENT = 'TransferableDevelopment';

  //manage layers
  static readonly GENERAL = 'General';
  static readonly AIR_RIGHTS = 'Air Rights';
  static readonly STATISTICS = 'Statistics';
  //zoning data
  static readonly LOT = 'Lot';
  static readonly BUILDING = 'Building';
  static readonly FINANCIAL = 'Financial';

  //zoning Analysis
  static readonly BULK = 'Use/Bulk';
  static readonly REGULATIONS = 'Regulations';
  static readonly LAYERS = 'Layers';

  // map view options
  static readonly TWO_DIMENSION = 'map 2d';
  static readonly THREE_DIMENSION = 'map 3d';
  static readonly UP_VIEW = 'map up view';
}
