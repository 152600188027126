<section class="address_search">
  <!-- <div class="input_cnt d-flex">
    <app-input class="input" [params]="searchAddress"></app-input>
    <span class="icon-address_search icon"></span>
  </div> -->
  <div class="search_cnt">
    <div id="geocoder"></div>
    <span class="icon-address_search icon"></span>
  </div>

  <app-accordion
    [params]="params"
    [savedPrincipalInputValue]="savedPrincipalInputValue"
    (searchEvent)="doCompleteSearch($event)"
    (cleanEvent)="cleanBblFields($event)"
    (click)="closeElement(false)"
  >
    <div class="bbl_input">
      <app-select
        class="input input--margin_right col-md-12"
        [params]="borough"
        (expandElement)="expandSelectedElement($event, 'Borough')"
        (itemSelected)="handleElementSelected($event, 'select')"
      ></app-select>
      <div class="inner_input_cnt d-flex mt-2">
        <app-input
          class="input_borought"
          (blurCallback)="handleBlock($event, 'blockInput')"
          (keyInputCallback)="handleBlock($event, 'blockInput')"
          [params]="block"
        ></app-input>
        <app-input
          class="input_borought"
          [params]="lot"
          (blurCallback)="handleLot($event, 'lotInput')"
          (keyInputCallback)="handleLot($event, 'lotInput')"
        ></app-input>
      </div>

      <button
        class="btn_lot"
        [disabled]="!!blockBtnGoToLot"
        (click)="goToLot($event)"
      >
        Go to Lot
      </button>
    </div>
  </app-accordion>
</section>
