import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageLayerService } from 'src/app/services/manage-layers.service';
import { NavBarCloseService } from 'src/app/services/nav-bar-close.service';
import { Constants } from 'src/app/shared';
import { AccordionTO } from 'src/app/ui/accordion/accordion.to';
import { SwitchTO } from 'src/app/ui/switch/switch.to';

@Component({
  selector: 'app-manage-layers',
  templateUrl: './manage-layers.component.html',
  styleUrls: ['./manage-layers.component.scss'],
})
export class ManageLayersComponent implements OnInit {
  /**
   * form group
   */
  form!: FormGroup;
  /**
   * Accordion config
   */
  mapOptionsManageLayers = {
    title: 'Manage layers',
    showInfo: false,
    noDropdown: false,
    bgWhite: true,
  } as AccordionTO;

  /**
   * switch tax lot configuration
   */
  switchTaxLot = {
    id: 'switchTaxLot',
    enabled: true,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch zoning d config
   */
  switchZoningDistrict = {
    id: 'switchZoningDistrict',
    enabled: true,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch commercial overlay config
   */
  switchCommercialOverlay = {
    id: 'switchCommercialOverlay',
    enabled: true,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch special d config
   */
  switchSpecialDistrict = {
    id: 'switchSpecialDistrict',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch specialsubD config
   */
  switchSpecialSubDistrict = {
    id: 'switchSpecialSubDistrict',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch MIH config
   */
  switchMIH = {
    id: 'switchMIH',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch IHDA config
   */
  switchIHDA = {
    id: 'switchIHDA',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * switch limited height config
   */
  switchLimitedHeight = {
    id: 'switchLimitedHeight',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch communityD config
   */
  switchComunnityDistric = {
    id: 'switchComunnityDistric',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch flood zone config
   */
  switchFloodZone = {
    id: 'switchFloodZone',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch Preliminary Flood zone config
   */
  switchPreliminaryFloodZone = {
    id: 'switchPreliminaryFloodZone',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch historicD Config
   */
  switchHistoricDistrict = {
    id: 'switchHistoricDistrict',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch Landmarsite config
   */
  switchLandmarkSite = {
    id: 'switchLandmarkSite',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch Edesignations config
   */
  switchEdesignations = {
    id: 'switchEdesignations',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch subwayLines config
   */
  switchSubwayLines = {
    id: 'switchSubwayLines',
    enabled: true,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch topography config
   */
  switchTopography = {
    id: 'switchTopography',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch boroughs config
   */
  switchBoroughs = {
    id: 'switchBoroughs',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * Switch TransferableD config
   */
  switchTransferableDevelopment = {
    id: 'switchTransferableDevelopment',
    enabled: false,
    innerValue: true,
  } as SwitchTO;

  /**
   * list of actions of manage layers
   */
  manageLayersList: any[] = [];

  /**
   * list of actions of manage layers
   */
  airRightsList: any[] = [];

  /**
   * list of actions of manage layers
   */

  /**
   * StatitsticList array
   */
  statisticsList: any[] = [];

  /**
   * Flag that handle useColors
   */
  useColors: boolean = false;
  /**
   * Flag that handle taxLotDimension
   */
  taxLotDimension: boolean = false;
  /**
   * Flag that handle zoningDistrict
   */
  zoningDistrict: boolean = false;
  /**
   * Flag that handle commercialDistrict
   */
  commercialDistrict!: boolean;
  /**
   * Flag that handle manufacturingDistricts
   */
  manufacturingDistricts!: boolean;
  /**
   * Flag that handle residenceDistricts
   */
  residenceDistricts!: boolean;
  /**
   * Flag that handle parks
   */
  parks!: boolean;
  /**
   * Flag that handle batteryParkCity
   */
  batteryParkCity!: boolean;
  /**
   * Flag that handle commercialOverlay
   */
  commercialOverlay: boolean = false;
  /**
   * Flag that handle c1
   */
  c1: boolean = false;
  /**
   * Flag that handle c2
   */
  c2: boolean = false;
  /**
   * Flag that handle specialDistrict
   */
  specialDistrict: boolean = false;
  /**
   * Flag that handle specialSubDistrict
   */
  specialSubDistrict: boolean = false;
  /**
   * Flag that handle communityDistrict
   */
  communityDistrict: boolean = false;
  /**
   * Flag that handle floodZone
   */
  floodZone: boolean = false;
  /**
   * Flag that handle effectiveFlood
   */
  effectiveFlood: boolean = false;
  /**
   * Flag that handle effectiveFloodV
   */
  effectiveFloodV: boolean = false;
  /**
   * Flag that handle effectiveFloodA
   */
  effectiveFloodA: boolean = false;
  /**
   * Flag that handle effectiveFloodX
   */
  effectiveFloodX: boolean = false;
  /**
   * Flag that handle commercialOverlay1
   */
  commercialOverlay1!: boolean;
  /**
   * Flag that handle commercialOverlay2
   */
  commercialOverlay2!: boolean;
  /**
   * Flag that handle preliminaryFloodV
   */
  preliminaryFloodV: boolean = false;
  /**
   * Flag that handle preliminaryFloodA
   */
  preliminaryFloodA: boolean = false;
  /**
   * Flag that handle preliminaryFloodX
   */
  preliminaryFloodX: boolean = false;
  /**
   * Flag that handle historicDistrict
   */
  historicDistrict: boolean = false;
  /**
   * Flag that handle environmentalDesignations
   */
  environmentalDesignations: boolean = false;
  /**
   * Flag that handle landmark
   */
  landmark: boolean = false;
  /**
   * Flag that handle subway
   */
  subway: boolean = false;
  /**
   * info tab data list
   */
  infoTypeData = [
    {
      type: Constants.GENERAL,
      active: true,
    },
    {
      type: Constants.AIR_RIGHTS,
      active: false,
    },
    {
      type: Constants.STATISTICS,
      active: false,
    },
  ];
  /**
   * Flag that handle generalSelected tab
   */
  generalSelected: boolean = true;
  /**
   * Flag that handle airRightsSelected tab
   */
  airRightsSelected = false;
  /**
   * Flag that handle statisticsSelected tab
   */
  statisticsSelected = false;
  /**
   * Flag that handle noAvaliableFar
   */
  noAvaliableFar = false;
  /**
   * Flag that handle ceroToOneFar
   */
  ceroToOneFar = false;
  /**
   * Flag that handle oneToTwoFar
   */
  oneToTwoFar = false;
  /**
   * Flag that handle twoToThreeFar
   */
  twoToThreeFar = false;
  /**
   * Flag that handle threeToFourFar
   */
  threeToFourFar = false;
  /**
   * Flag that handle fiveToSixFar
   */
  fiveToSixFar = false;
  /**
   * Flag that handle SixToSevenFar
   */
  SixToSevenFar = false;
  /**
   * Flag that handle SevenToEightFar
   */
  SevenToEightFar = false;
  /**
   * Flag that handle eightToNineFar
   */
  eightToNineFar = false;
  /**
   * Flag that handle nineToTenFar
   */
  nineToTenFar = false;
  /**
   * Flag that handle tenToElevenFar
   */
  tenToElevenFar = false;
  /**
   * Flag that handle elevenToTwelveFar
   */
  elevenToTwelveFar = false;
  /**
   * Flag that handle TwelveToThirdteenFar
   */
  TwelveToThirdteenFar = false;
  /**
   * Flag that handle thirdteenToFourteenFar
   */
  thirdteenToFourteenFar = false;
  /**
   * Flag that handle fourteenToFivetfifteen
   */
  fourteenToFivetfifteen = false;
  /**
   * Input var that handle secondRowActive is active
   */
  @Input() secondRowActive: boolean = false;
  /**
   * Input var that handle reducedContainer is active
   */
  @Input() reducedContainer: boolean = false;
  /**
   * Input var that handle twoContainer is active
   */
  @Input() twoContainer: boolean = false;
  /**
   * Input var that handle adjustElement flag is active
   */
  @Input() adjustElement: boolean = false;
  /**
   * Input var that handle addressSearchIsVisible flag is active
   */
  @Input() addressSearchIsVisible: boolean = false;
  /**
   * Output var that validate when container is opened
   */
  @Output() validateContainersOpened = new EventEmitter<string>();
  /**
   * Output var that validate when container is close
   */
  @Output() closeItem = new EventEmitter<string>();

  /**
   * Constructor
   * @param formBuilder FormBuilder
   * @param manageLayerService manage layer service manage
   * @param navBarCloseService navbar close service manage
   */
  constructor(
    private formBuilder: FormBuilder,
    private manageLayerService: ManageLayerService,
    private navBarCloseService: NavBarCloseService
  ) {
    this.constructManageLayerList();
    this.constructAirRightsList();
  }

  ngOnInit(): void {
    this.loadLastTabSelected();
    this.form = this.formBuilder.group({
      switchTaxLot: [],
      switchBoroughs: [],
      switchCommercialOverlay: [],
      switchComunnityDistric: [],
      switchEdesignations: [],
      switchFloodZone: [],
      switchHistoricDistrict: [],
      switchLandmarkSite: [],
      switchPreliminaryFloodZone: [],
      switchSpecialDistrict: [],
      switchSpecialSubDistrict: [],
      switchMIH: [],
      switchIHDA: [],
      switchLimitedHeight: [],
      switchSubwayLines: [],
      switchTopography: [],
      switchZoningDistrict: [],
      switchTransferableDevelopment: [],
    });
    this.switchTaxLot.form = this.form;
    this.switchBoroughs.form = this.form;
    this.switchCommercialOverlay.form = this.form;
    this.switchComunnityDistric.form = this.form;
    this.switchEdesignations.form = this.form;
    this.switchFloodZone.form = this.form;
    this.switchHistoricDistrict.form = this.form;
    this.switchLandmarkSite.form = this.form;
    this.switchPreliminaryFloodZone.form = this.form;
    this.switchSpecialDistrict.form = this.form;
    this.switchSpecialSubDistrict.form = this.form;
    (this.switchMIH.form = this.form),
      (this.switchIHDA.form = this.form),
      (this.switchLimitedHeight.form = this.form),
      (this.switchSubwayLines.form = this.form);
    this.switchTopography.form = this.form;
    this.switchZoningDistrict.form = this.form;
    this.switchTransferableDevelopment.form = this.form;
    this.form.controls[this.switchTaxLot.id].setValue(true);
    this.form.controls[this.switchZoningDistrict.id].setValue(true);
    this.form.controls[this.switchCommercialOverlay.id].setValue(true);
    this.form.controls[this.switchSubwayLines.id].setValue(true);
    this.initFloodStates();
    this.initCommercialOverlayStates();
    this.initZoningDStates();
    this.initTaxLotStates();
    this.getItemStateOnInit();
  }

  /**
   * Method that remembers and load the las tab selected
   */
  loadLastTabSelected() {
    const generalTab = sessionStorage.getItem('generalManageLayers');
    const airRightsTab = sessionStorage.getItem('airRightsManageLayers');
    const statisticsTab = sessionStorage.getItem('statisticsManageLayers');
    this.infoTypeData.forEach((element) => {
      element.active = false;
    });
    this.generalSelected = false;
    this.airRightsSelected = false;
    this.statisticsSelected = false;

    if (
      generalTab === 'true' ||
      (!generalTab && !airRightsTab && !statisticsTab)
    ) {
      this.generalSelected = true;
      this.infoTypeData[0].active = !this.infoTypeData[0].active;
    } else if (airRightsTab) {
      this.airRightsSelected = true;
      this.infoTypeData[1].active = !this.infoTypeData[1].active;
    } else if (statisticsTab) {
      this.statisticsSelected = true;
      this.infoTypeData[2].active = !this.infoTypeData[2].active;
    }
  }

  /**
   * Method that init Commercial overlay states
   */
  initCommercialOverlayStates() {
    const commercialOverlayFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchCommercialOverlay'
    );
    if (commercialOverlayFirstClickState === null) {
      sessionStorage.setItem('firstTimeSwitchCommercialOverlay', '1');
      sessionStorage.setItem('switchCommercialOverlay', 'true');
      this.commercialOverlay1 = true;
      sessionStorage.setItem('checkCommercialOverlay1', 'true');
      this.commercialOverlay2 = true;
      sessionStorage.setItem('checkCommercialOverlay2', 'true');
      this.constructManageLayerList();
    }
  }

  /**
   * Method that init the zoningD states
   */
  initZoningDStates() {
    const preliminaryFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchZoningDistrict'
    );
    if (preliminaryFirstClickState === null) {
      sessionStorage.setItem('firstTimeSwitchZoningDistrict', '1');
      sessionStorage.setItem('switchZoningDistrict', 'true');
      this.commercialDistrict = true;
      sessionStorage.setItem('checkCommercialD', 'true');
      this.manufacturingDistricts = true;
      sessionStorage.setItem('checkManufacturingD', 'true');
      this.residenceDistricts = true;
      sessionStorage.setItem('checkResidenceD', 'true');
      this.parks = true;
      sessionStorage.setItem('checkParks', 'true');
      this.batteryParkCity = true;
      sessionStorage.setItem('checkBatteryParks', 'true');
      this.constructManageLayerList();
    }
  }

  /**
   * Method that init the initTaxLotStates states
   */
  initTaxLotStates() {
    const taxLotFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchTaxLot'
    );
    if (taxLotFirstClickState === null) {
      sessionStorage.setItem('firstTimeSwitchTaxLot', '1');
      sessionStorage.setItem('switchTaxlot', 'true');
      this.constructManageLayerList();
    }
  }

  /**
   * Method that init the initFloodStates states
   */
  initFloodStates() {
    const preliminaryFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchFloodPreliminary'
    );
    const effectiveFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchFloodEffective'
    );
    if (effectiveFirstClickState === null) {
      sessionStorage.setItem('firstTimeSwitchFloodEffective', '1');
    }
    if (preliminaryFirstClickState === null) {
      sessionStorage.setItem('firstTimeSwitchFloodPreliminary', '1');
    }
  }

  /**
   * Method that construct all the manage layers information
   */
  constructManageLayerList() {
    this.manageLayersList = [
      {
        title: 'Zoning and Lan Use',
        label: 'Tax Lot',
        id: 0,
        optionName: 'taxLot',
        switch: this.switchTaxLot,
        border: '#f1f1f1',
        percentage: 'auto',
        tooltipInfo: 'lorem ipsum',
        optionsList: [
          {
            label: 'Tax Lot Dimensions',
            enabled: this.taxLotDimension,
            id: 1,
            optionName: 'taxLotDimensions',
            parentName: 'taxLot',
            colorList: null,
          },
          {
            label: 'Land Use Colors',
            enabled: this.useColors,
            id: 2,
            optionName: 'useColors',
            parentName: 'taxLot',
            colorList: [
              {
                label: 'One & Two Family Buildings',
                color: '#FEFFB2',
              },
              {
                label: 'Multi-Family Walk-Up Buildings',
                color: '#F2BB5A',
              },
              {
                label: 'Multi-Family Elevator Buildings',
                color: '#A87126',
              },
              {
                label: 'Mixed Residential & Commercial Buildings',
                color: '#F08A51',
              },
              {
                label: 'Commercial & Office Buildings',
                color: '#E74138',
              },
              {
                label: 'Industrial & Manufacturing',
                color: '#D36BF4',
              },
              {
                label: 'Transportation & Utility',
                color: '#DBBEE8',
              },
              {
                label: 'Public Facilities & Institutions',
                color: '#5FA0D1',
              },
              {
                label: 'Open Space & Outdoor Recreation',
                color: '#8DD07C',
              },
              {
                label: 'Parking Facilities',
                color: '#BAB8B7',
              },
              {
                label: 'Vacant Land',
                color: '#555555',
              },
              {
                label: 'Other',
                color: '#F1F1F1',
              },
            ],
          },
        ],
      },
      {
        title: null,
        label: 'Zoning District',
        enabled: true,
        id: 1,
        optionName: 'zoningDistrict',
        switch: this.switchZoningDistrict,
        percentage: 'auto',
        tooltipInfo: 'lorem ipsum',
        optionsList: [
          {
            label: 'Commercial District',
            enabled: this.commercialDistrict,
            id: 1,
            optionName: 'commercialDistrict',
            color: '#ED7C77',
            parentName: 'zoningDistrict',
            colorList: null,
          },
          {
            label: 'Manufacturing Districts',
            enabled: this.manufacturingDistricts,
            id: 2,
            optionName: 'manufacturingDistricts',
            parentName: 'zoningDistrict',
            color: '#D178F8',
            colorList: null,
          },
          {
            label: 'Residence Districts',
            enabled: this.residenceDistricts,
            id: 3,
            optionName: 'residenceDistricts',
            parentName: 'zoningDistrict',
            color: '#F7D282',
            colorList: null,
          },
          {
            label: 'Parks',
            enabled: this.parks,
            id: 4,
            optionName: 'parks',
            color: '#9ECF62',
            parentName: 'zoningDistrict',
            colorList: null,
          },
          {
            label: 'Battery Park City',
            enabled: this.batteryParkCity,
            id: 5,
            color: '#BDC1D5',
            optionName: 'batteryParkCity',
            parentName: 'zoningDistrict',
            colorList: null,
          },
        ],
      },
      {
        title: null,
        label: 'Commercial Overlay',
        enabled: false,
        id: 2,
        optionName: 'commercialOverlay',
        switch: this.switchCommercialOverlay,
        percentage: 'auto',
        tooltipInfo: 'lorem ipsum',
        optionsList: [
          {
            label: 'C1-1 Through C1-5',
            enabled: this.commercialOverlay1,
            id: 1,
            optionName: 'c1',
            border: '#B93B23',
            parentName: 'commercialOverlay',
            colorList: null,
          },
          {
            label: 'C2-1 Through C2-5',
            enabled: this.commercialOverlay2,
            id: 2,
            optionName: 'c2',
            border: '#B93B23',
            parentName: 'commercialOverlay',
            colorList: null,
          },
        ],
      },
      {
        title: null,
        label: 'Special Purpose District',
        enabled: false,
        id: 3,
        optionName: 'specialDistrict',
        switch: this.switchSpecialDistrict,
        color: '#445244',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        title: null,
        label: 'Special Purpose Subdistrict',
        enabled: false,
        id: 4,
        optionName: 'specialSubDistrict',
        switch: this.switchSpecialSubDistrict,
        color: '#80957F',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        title: 'Supporting Zoning Layers',
        label: 'MIH',
        enabled: false,
        id: 5,
        optionName: 'mih',
        switch: this.switchMIH,
        color: '#53d0d0',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        label: 'IHDA',
        enabled: false,
        id: 6,
        optionName: 'ihda',
        switch: this.switchIHDA,
        color: '#f679ce',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        label: 'Limited Height Districts',
        enabled: false,
        id: 7,
        optionName: 'limitedHeightDistrict',
        switch: this.switchLimitedHeight,
        color: '#91732C',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        title: null,
        label: 'Historic District',
        enabled: false,
        id: 8,
        optionName: 'historicDistrict',
        switch: this.switchHistoricDistrict,
        color: '#6036C3',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },

      {
        title: null,
        label: 'Landmark Site',
        enabled: false,
        id: 9,
        optionName: 'landmarkSite',
        switch: this.switchLandmarkSite,
        color: '#D3E660',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        title: null,
        label: 'Effective Flood Maps 2007',
        enabled: false,
        id: 10,
        optionName: 'floodZone',
        switch: this.switchFloodZone,
        percentage: 'auto',
        tooltipInfo: 'lorem ipsum',
        optionsList: [
          {
            label: 'V (1% floodplain)',
            enabled: this.effectiveFloodV,
            id: 1,
            optionName: 'floodPlain1',
            color: '#434BAC',
            colorList: null,
            parentName: 'floodZone',
          },
          {
            label: 'A (1% floodplain)',
            enabled: this.effectiveFloodA,
            id: 2,
            optionName: 'floodPlain2',
            color: '#5099E9',
            colorList: null,
            parentName: 'floodZone',
          },
          {
            label: 'Shaded X (0.2% floodplain)',
            enabled: this.effectiveFloodX,
            id: 3,
            color: '#5FC9D2',
            colorList: null,
            optionName: 'shadedFloodPlain',
            parentName: 'floodZone',
          },
        ],
      },

      {
        title: null,
        label: 'Preliminary Flood Maps 2015',
        enabled: false,
        id: 11,
        optionName: 'preliminaryFloodZone',
        switch: this.switchPreliminaryFloodZone,
        percentage: 'auto',
        tooltipInfo: 'lorem ipsum',
        optionsList: [
          {
            label: 'V (1% floodplain)',
            enabled: this.preliminaryFloodV,
            id: 1,
            color: '#434BAC',
            colorList: null,
            optionName: 'preliminaryFloodPlain1',
            parentName: 'preliminaryFloodZone',
          },
          {
            label: 'A (1% floodplain)',
            enabled: this.preliminaryFloodA,
            id: 2,
            optionName: 'preliminaryFloodPlain2',
            color: '#5099E9',
            colorList: null,
            parentName: 'preliminaryFloodZone',
          },
          {
            label: 'Shaded X (0.2% floodplain)',
            enabled: this.preliminaryFloodX,
            id: 3,
            color: '#5FC9D2',
            colorList: null,
            optionName: 'shadedPreliminaryFloodPlain',
            parentName: 'preliminaryFloodZone',
          },
        ],
      },
      {
        title: null,
        label: 'Environmental Designations',
        enabled: false,
        id: 12,
        optionName: 'environmentalDesignations',
        switch: this.switchEdesignations,
        color: '#FFFFFF',
        circle: '#444aac',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        title: 'Other Supporting Layers',
        label: 'Subway Lines',
        enabled: false,
        id: 13,
        optionName: 'subwayLines',
        switch: this.switchSubwayLines,
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        title: null,
        label: 'Topography',
        enabled: false,
        id: 14,
        optionName: 'topography',
        switch: this.switchTopography,
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        title: 'Administrative Boundaries',
        label: 'Community District',
        enabled: false,
        id: 15,
        optionName: 'communityDistrict',
        switch: this.switchComunnityDistric,
        border: '#DFB493',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
      {
        title: null,
        label: 'Boroughs',
        enabled: false,
        id: 16,
        optionName: 'boroughs',
        switch: this.switchBoroughs,
        border: '#E7E598',
        tooltipInfo: 'lorem ipsum',
        optionsList: [],
      },
    ];
  }

  /**
   * Method that constructs all the constructAirRights information
   */
  constructAirRightsList() {
    this.airRightsList = [
      {
        label: 'Transferable Air Rights ',
        id: 0,
        optionName: 'transferableDevelopment',
        switch: this.switchTransferableDevelopment,
        percentage: 'auto',
        tooltipInfo: 'lorem ipsum',
        optionsList: [
          {
            label: 'No available FAR',
            enabled: this.noAvaliableFar,
            id: 1,
            optionName: 'noAvailableFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#808080',
          },
          {
            label: '0.01 to 1 FAR',
            enabled: this.ceroToOneFar,
            id: 3,
            optionName: 'ceroToOneFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#4B9DF8',
          },
          {
            label: '1.01 to 2 FAR',
            enabled: this.oneToTwoFar,
            id: 4,
            optionName: 'oneToTwoFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#59BDF9',
          },
          {
            label: '2.01 to 3 FAR',
            enabled: this.twoToThreeFar,
            id: 5,
            optionName: 'twoToThreeFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#5ECDF9',
          },
          {
            label: '3.01 to 4 FAR',
            enabled: this.threeToFourFar,
            id: 6,
            optionName: 'threeToFourFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#7AFCFD',
          },
          {
            label: '4.01 to 5 FAR',
            enabled: this.threeToFourFar,
            id: 7,
            optionName: 'fourToFiveFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#89FCFD',
          },
          {
            label: '5.01 to 6 FAR',
            enabled: this.fiveToSixFar,
            id: 8,
            optionName: 'fiveToSixFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#BEFDFF',
          },
          {
            label: '6.01 to 7 FAR',
            enabled: this.SixToSevenFar,
            id: 9,
            optionName: 'SixToSevenFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#BEFDFF',
          },
          {
            label: '7.01 to 8 FAR',
            enabled: this.SevenToEightFar,
            id: 10,
            optionName: 'SevenToEightFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#FFFF72',
          },
          {
            label: '8.01 to 9 FAR',
            enabled: this.eightToNineFar,
            id: 11,
            optionName: 'eightToNineFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#FDF151',
          },
          {
            label: '9.01 to 10 FAR',
            enabled: this.nineToTenFar,
            id: 12,
            optionName: 'nineToTenFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#F5C342',
          },
          {
            label: '10.01 to 11 FAR',
            enabled: this.tenToElevenFar,
            id: 13,
            optionName: 'tenToElevenFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#F3AE3D',
          },
          {
            label: '11.01 to 12 FAR',
            enabled: this.elevenToTwelveFar,
            id: 14,
            optionName: 'elevenToTwelveFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#F09135',
          },
          {
            label: '12.01 to 13 FAR',
            enabled: this.TwelveToThirdteenFar,
            id: 15,
            optionName: 'twelveToThirdteenFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#ED7A30',
          },
          {
            label: '13.01 to 14 FAR',
            enabled: this.thirdteenToFourteenFar,
            id: 16,
            optionName: 'thirdteenToFourteenFar',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#EB5B29',
          },
          {
            label: '14.01 to +15 FAR',
            enabled: this.fourteenToFivetfifteen,
            id: 17,
            optionName: 'fourteenToFivetfifteen',
            parentName: 'transferableDevelopment',
            colorList: null,
            color: '#EA3323',
          },
        ],
      },
    ];
  }

  /**
   * Gets the state of all switch and check inputs and sets them at the inicialization of the component.
   */
  getItemStateOnInit() {
    const sessionBoroughs = sessionStorage.getItem('switchBoroughs');
    if (sessionBoroughs === 'true') {
      this.switchBoroughs.enabled = true;
      this.form.controls[this.switchBoroughs.id].setValue(true);
    } else {
      this.switchBoroughs.enabled = false;
      this.form.controls[this.switchBoroughs.id].setValue(false);
    }

    const sessionPreliminaryFlood = sessionStorage.getItem(
      'switchPreliminaryFloodZone'
    );
    if (sessionPreliminaryFlood === 'true') {
      this.switchPreliminaryFloodZone.enabled = true;
      this.form.controls[this.switchPreliminaryFloodZone.id].setValue(true);
    } else {
      this.switchPreliminaryFloodZone.enabled = false;
      this.form.controls[this.switchPreliminaryFloodZone.id].setValue(false);
    }

    const sessionTopography = sessionStorage.getItem('switchTopography');
    if (sessionTopography === 'true') {
      this.switchTopography.enabled = true;
      this.form.controls[this.switchTopography.id].setValue(true);
    } else {
      this.switchTopography.enabled = false;
      this.form.controls[this.switchTopography.id].setValue(false);
    }

    const sessionHistoricD = sessionStorage.getItem('switchHistoricDistrict');
    if (sessionHistoricD === 'true') {
      this.switchHistoricDistrict.enabled = true;
      this.form.controls[this.switchHistoricDistrict.id].setValue(true);
    } else {
      this.switchHistoricDistrict.enabled = false;
      this.form.controls[this.switchHistoricDistrict.id].setValue(false);
    }

    const sessionEdesignations = sessionStorage.getItem('switchEdesignations');
    if (sessionEdesignations === 'true') {
      this.switchEdesignations.enabled = true;
      this.form.controls[this.switchEdesignations.id].setValue(true);
    } else {
      this.switchEdesignations.enabled = false;
      this.form.controls[this.switchEdesignations.id].setValue(false);
    }

    const sessionSpecialD = sessionStorage.getItem('switchSpecialD');
    if (sessionSpecialD === 'true') {
      this.switchSpecialDistrict.enabled = true;
      this.form.controls[this.switchSpecialDistrict.id].setValue(true);
    } else {
      this.switchSpecialDistrict.enabled = false;
      this.form.controls[this.switchSpecialDistrict.id].setValue(false);
    }

    const sessionMIH = sessionStorage.getItem('switchMIH');
    if (sessionMIH === 'true') {
      this.switchMIH.enabled = true;
      this.form.controls[this.switchMIH.id].setValue(true);
    } else {
      this.switchMIH.enabled = false;
      this.form.controls[this.switchMIH.id].setValue(false);
    }

    const sessionIHDA = sessionStorage.getItem('switchIHDA');
    if (sessionIHDA === 'true') {
      this.switchIHDA.enabled = true;
      this.form.controls[this.switchIHDA.id].setValue(true);
    } else {
      this.switchIHDA.enabled = false;
      this.form.controls[this.switchIHDA.id].setValue(false);
    }

    const sessionLimitedH = sessionStorage.getItem('switchLimitedHeightD');
    if (sessionLimitedH === 'true') {
      this.switchLimitedHeight.enabled = true;
      this.form.controls[this.switchLimitedHeight.id].setValue(true);
    } else {
      this.switchLimitedHeight.enabled = false;
      this.form.controls[this.switchLimitedHeight.id].setValue(false);
    }

    const sessionSpeciaSubD = sessionStorage.getItem('switchSpecialSubD');
    if (sessionSpeciaSubD === 'true') {
      this.switchSpecialSubDistrict.enabled = true;
      this.form.controls[this.switchSpecialSubDistrict.id].setValue(true);
    } else {
      this.switchSpecialSubDistrict.enabled = false;
      this.form.controls[this.switchSpecialSubDistrict.id].setValue(false);
    }

    const sessionCommercialOverlay = sessionStorage.getItem(
      'switchCommercialOverlay'
    );
    if (sessionCommercialOverlay === 'true') {
      this.switchCommercialOverlay.enabled = true;
      this.form.controls[this.switchCommercialOverlay.id].setValue(true);
    } else if (sessionCommercialOverlay === null) {
      sessionStorage.setItem('switchCommercialOverlay', 'true');
    } else {
      this.switchCommercialOverlay.enabled = false;
      this.form.controls[this.switchCommercialOverlay.id].setValue(false);
    }

    const sessionZoningD = sessionStorage.getItem('switchZoningDistrict');
    if (sessionZoningD === 'true') {
      this.switchZoningDistrict.enabled = true;
      this.form.controls[this.switchZoningDistrict.id].setValue(true);
    } else if (sessionZoningD === null) {
      sessionStorage.setItem('switchZoningDistrict', 'true');
    } else {
      this.switchZoningDistrict.enabled = false;
      this.form.controls[this.switchZoningDistrict.id].setValue(false);
    }

    const sessionEffectiveFlood = sessionStorage.getItem(
      'switchEffectiveFloodZone'
    );
    if (sessionEffectiveFlood === 'true') {
      this.switchFloodZone.enabled = true;
      this.form.controls[this.switchFloodZone.id].setValue(true);
    } else {
      this.switchFloodZone.enabled = false;
      this.form.controls[this.switchFloodZone.id].setValue(false);
    }

    const sessionLandmark = sessionStorage.getItem('switchLandmarkSite');
    if (sessionLandmark === 'true') {
      this.switchLandmarkSite.enabled = true;
      this.form.controls[this.switchLandmarkSite.id].setValue(true);
    } else {
      this.switchLandmarkSite.enabled = false;
      this.form.controls[this.switchLandmarkSite.id].setValue(false);
    }

    const sessionSubwayLine = sessionStorage.getItem('switchSubwayLine');
    if (sessionSubwayLine === 'true') {
      this.switchSubwayLines.enabled = true;
      this.form.controls[this.switchSubwayLines.id].setValue(true);
    } else if (sessionSubwayLine === null) {
      sessionStorage.setItem('switchSubwayLine', 'true');
    } else {
      this.switchSubwayLines.enabled = false;
      this.form.controls[this.switchSubwayLines.id].setValue(false);
    }

    const sessionCommunityD = sessionStorage.getItem('switchCommunityD');
    if (sessionCommunityD === 'true') {
      this.switchComunnityDistric.enabled = true;
      this.form.controls[this.switchComunnityDistric.id].setValue(true);
    } else {
      this.switchComunnityDistric.enabled = false;
      this.form.controls[this.switchComunnityDistric.id].setValue(false);
    }

    const sessionTaxLot = sessionStorage.getItem('switchTaxLot');
    if (sessionTaxLot === 'true') {
      this.switchTaxLot.enabled = true;
      this.form.controls[this.switchTaxLot.id].setValue(true);
    } else if (sessionTaxLot === null) {
      sessionStorage.setItem('switchTaxLot', 'true');
    } else {
      this.form.controls[this.switchTaxLot.id].setValue(false);
      this.switchTaxLot.enabled = false;
    }

    const zoningDistrictFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchZoningDistrict'
    );

    const sessionCommercialDistrict =
      sessionStorage.getItem('checkCommercialD');
    if (sessionZoningD !== 'false' && zoningDistrictFirstClickState !== null) {
      if (sessionCommercialDistrict === 'true' && sessionZoningD === 'true') {
        this.handleOptionsCheckbox('commercialDistrict', null, 1, true);
      } else if (sessionCommercialDistrict === null) {
        this.handleOptionsCheckbox('commercialDistrict', null, 1, true);
      } else {
        this.handleOptionsCheckbox('commercialDistrict', null, 1, false);
      }
    } else {
      if (sessionCommercialDistrict === 'true') {
        this.commercialDistrict = true;
        this.constructManageLayerList;
      }
    }

    const sessionManufacturingD = sessionStorage.getItem('checkManufacturingD');
    if (sessionZoningD !== 'false' && zoningDistrictFirstClickState !== null) {
      if (sessionManufacturingD === 'true' && sessionZoningD === 'true') {
        this.handleOptionsCheckbox('manufacturingDistricts', null, 1, true);
      } else if (sessionCommercialDistrict === null) {
        this.handleOptionsCheckbox('manufacturingDistricts', null, 1, true);
      } else {
        this.handleOptionsCheckbox('manufacturingDistricts', null, 1, false);
      }
    } else {
      if (sessionManufacturingD === 'true') {
        this.manufacturingDistricts = true;
        this.constructManageLayerList;
      }
    }

    const sessionResidenceD = sessionStorage.getItem('checkResidenceD');
    if (sessionZoningD !== 'false' && zoningDistrictFirstClickState !== null) {
      if (sessionResidenceD === 'true' && sessionZoningD === 'true') {
        this.handleOptionsCheckbox('residenceDistricts', null, 1, true);
      } else if (sessionResidenceD === null) {
        this.handleOptionsCheckbox('residenceDistricts', null, 1, true);
      } else {
        this.handleOptionsCheckbox('residenceDistricts', null, 1, false);
      }
    } else {
      if (sessionResidenceD === 'true') {
        this.residenceDistricts = true;
        this.constructManageLayerList;
      }
    }

    const sessionCheckParks = sessionStorage.getItem('checkParks');
    if (sessionZoningD !== 'false' && zoningDistrictFirstClickState !== null) {
      if (sessionCheckParks === 'true' && sessionZoningD === 'true') {
        this.handleOptionsCheckbox('parks', null, 1, true);
      } else if (sessionCheckParks === null) {
        this.handleOptionsCheckbox('parks', null, 1, true);
      } else {
        this.handleOptionsCheckbox('parks', null, 1, false);
      }
    } else {
      if (sessionCheckParks === 'true') {
        this.parks = true;
        this.constructManageLayerList;
      }
    }

    const sessionCheckBatteryParks =
      sessionStorage.getItem('checkBatteryParks');
    if (sessionZoningD !== 'false' && zoningDistrictFirstClickState !== null) {
      if (sessionCheckBatteryParks === 'true' && sessionZoningD === 'true') {
        this.handleOptionsCheckbox('batteryParkCity', null, 1, true);
      } else if (sessionCheckBatteryParks === null) {
        this.handleOptionsCheckbox('batteryParkCity', null, 1, true);
      } else {
        this.handleOptionsCheckbox('batteryParkCity', null, 1, false);
      }
    } else {
      if (sessionCheckBatteryParks === 'true') {
        this.batteryParkCity = true;
        this.constructManageLayerList;
      }
    }

    const taxLotFirstCheckState = sessionStorage.getItem(
      'firstTimeSwitchTaxLot'
    );

    const sessionCheckUseColors = sessionStorage.getItem('checkUseColors');
    if (sessionTaxLot !== 'false' && taxLotFirstCheckState !== null) {
      if (sessionCheckUseColors === 'true' && sessionTaxLot === 'true') {
        this.handleOptionsCheckbox('useColors', null, 1, true);
      } else {
        this.handleOptionsCheckbox('useColors', null, 1, false);
      }
    } else {
      if (sessionCheckUseColors === 'true') {
        this.useColors = true;
        this.constructManageLayerList;
      }
    }

    const sessionCheckTaxLotDimension = sessionStorage.getItem(
      'checkTaxLotDimension'
    );
    if (sessionTaxLot !== 'false' && taxLotFirstCheckState !== null) {
      if (sessionCheckTaxLotDimension === 'true' && sessionTaxLot === 'true') {
        this.handleOptionsCheckbox('taxLotDimension', null, 1, true);
      } else {
        this.handleOptionsCheckbox('taxLotDimension', null, 1, false);
      }
    } else {
      if (sessionCheckTaxLotDimension === 'true') {
        this.taxLotDimension = true;
        this.constructManageLayerList;
      }
    }

    const preliminaryFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchFloodPreliminary'
    );
    const effectiveFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchFloodEffective'
    );

    const sessionEffectiveFloodV = sessionStorage.getItem(
      'checkEffectiveFloodV'
    );
    if (
      sessionEffectiveFlood !== 'false' &&
      effectiveFirstClickState !== null
    ) {
      if (
        sessionEffectiveFloodV === 'true' &&
        sessionEffectiveFlood === 'true'
      ) {
        this.handleOptionsCheckbox('floodPlain1', null, 1, true);
      } else if (sessionEffectiveFloodV === null && sessionEffectiveFlood) {
        this.handleOptionsCheckbox('shadedFloodPlain', null, 1, true);
      } else {
        this.handleOptionsCheckbox('floodPlain1', null, 1, false);
      }
    } else {
      if (sessionEffectiveFloodV === 'true') {
        this.effectiveFloodV = true;
        this.constructManageLayerList;
      }
    }

    const sessionEffectiveFloodA = sessionStorage.getItem(
      'checkEffectiveFloodA'
    );
    if (
      sessionEffectiveFlood !== 'false' &&
      effectiveFirstClickState !== null
    ) {
      if (
        sessionEffectiveFloodA === 'true' &&
        sessionEffectiveFlood === 'true'
      ) {
        this.handleOptionsCheckbox('floodPlain2', null, 1, true);
      } else if (sessionEffectiveFloodA === null && sessionEffectiveFlood) {
        this.handleOptionsCheckbox('shadedFloodPlain', null, 1, true);
      } else {
        this.handleOptionsCheckbox('floodPlain2', null, 1, false);
      }
    } else {
      if (sessionEffectiveFloodA === 'true') {
        this.effectiveFloodA = true;
        this.constructManageLayerList;
      }
    }

    const sessionEffectiveFloodX = sessionStorage.getItem(
      'checkEffectiveFloodX'
    );
    if (
      sessionEffectiveFlood !== 'false' &&
      effectiveFirstClickState !== null
    ) {
      if (
        sessionEffectiveFloodX === 'true' &&
        sessionEffectiveFlood === 'true'
      ) {
        this.handleOptionsCheckbox('shadedFloodPlain', null, 1, true);
      } else if (sessionEffectiveFloodX === null && sessionEffectiveFlood) {
        this.handleOptionsCheckbox('shadedFloodPlain', null, 1, true);
      } else {
        this.handleOptionsCheckbox('shadedFloodPlain', null, 1, false);
      }
    } else {
      if (sessionEffectiveFloodX === 'true') {
        this.effectiveFloodX = true;
        this.constructManageLayerList;
      }
    }

    const sessionPreliminaryFloodV = sessionStorage.getItem(
      'checkPreliminaryFloodV'
    );
    if (
      sessionPreliminaryFlood !== 'false' &&
      preliminaryFirstClickState !== null
    ) {
      if (
        sessionPreliminaryFloodV === 'true' &&
        sessionPreliminaryFlood === 'true'
      ) {
        this.handleOptionsCheckbox('preliminaryFloodPlain1', null, 1, true);
      } else if (sessionPreliminaryFloodV === null && sessionPreliminaryFlood) {
        this.handleOptionsCheckbox('preliminaryFloodPlain1', null, 1, true);
      } else {
        this.handleOptionsCheckbox('preliminaryFloodPlain1', null, 1, false);
      }
    } else {
      if (sessionPreliminaryFloodV === 'true') {
        this.preliminaryFloodV = true;
        this.constructManageLayerList;
      }
    }

    const sessionPreliminaryFloodA = sessionStorage.getItem(
      'checkPreliminaryFloodA'
    );
    if (
      sessionPreliminaryFlood !== 'false' &&
      preliminaryFirstClickState !== null
    ) {
      if (
        sessionPreliminaryFloodA === 'true' &&
        sessionPreliminaryFlood === 'true'
      ) {
        this.handleOptionsCheckbox('preliminaryFloodPlain2', null, 1, true);
      } else if (sessionPreliminaryFloodA === null && sessionPreliminaryFlood) {
        this.handleOptionsCheckbox('preliminaryFloodPlain2', null, 1, true);
      } else {
        this.handleOptionsCheckbox('preliminaryFloodPlain2', null, 1, false);
      }
    } else {
      if (sessionPreliminaryFloodA === 'true') {
        this.preliminaryFloodA = true;
        this.constructManageLayerList;
      }
    }

    const sessionPreliminaryFloodX = sessionStorage.getItem(
      'checkPreliminaryFloodX'
    );
    if (
      sessionPreliminaryFlood !== 'false' &&
      preliminaryFirstClickState !== null
    ) {
      if (
        sessionPreliminaryFloodX === 'true' &&
        sessionPreliminaryFlood === 'true'
      ) {
        this.handleOptionsCheckbox(
          'shadedPreliminaryFloodPlain',
          null,
          1,
          true
        );
      } else if (sessionPreliminaryFloodX === null && sessionPreliminaryFlood) {
        this.handleOptionsCheckbox(
          'shadedPreliminaryFloodPlain',
          null,
          1,
          true
        );
      } else {
        this.handleOptionsCheckbox(
          'shadedPreliminaryFloodPlain',
          null,
          1,
          false
        );
      }
    } else {
      if (sessionPreliminaryFloodX === 'true') {
        this.preliminaryFloodX = true;
        this.constructManageLayerList;
      }
    }

    const commercialOverlayFirstClickState = sessionStorage.getItem(
      'firstTimeSwitchCommercialOverlay'
    );
    const sessionCommercialOverlay1 = sessionStorage.getItem(
      'checkCommercialOverlay1'
    );
    if (
      sessionCommercialOverlay !== 'false' &&
      commercialOverlayFirstClickState !== null
    ) {
      if (
        sessionCommercialOverlay1 === 'true' &&
        sessionCommercialOverlay === 'true'
      ) {
        this.handleOptionsCheckbox('c1', null, 1, true);
      } else if (
        sessionCommercialOverlay1 === null &&
        sessionCommercialOverlay
      ) {
        this.handleOptionsCheckbox('c1', null, 1, true);
      } else {
        this.handleOptionsCheckbox('c1', null, 1, false);
      }
    } else {
      if (sessionCommercialOverlay1 === 'true') {
        this.commercialOverlay1 = true;
        this.constructManageLayerList;
      }
    }

    const sessionCommercialOverlay2 = sessionStorage.getItem(
      'checkCommercialOverlay2'
    );
    if (
      sessionCommercialOverlay !== 'false' &&
      commercialOverlayFirstClickState !== null
    ) {
      if (
        sessionCommercialOverlay2 === 'true' &&
        sessionCommercialOverlay === 'true'
      ) {
        this.handleOptionsCheckbox('c2', null, 1, true);
      } else if (
        sessionCommercialOverlay2 === null &&
        sessionCommercialOverlay
      ) {
        this.handleOptionsCheckbox('c2', null, 1, true);
      } else {
        this.handleOptionsCheckbox('c2', null, 1, false);
      }
    } else {
      if (sessionCommercialOverlay2 === 'true') {
        this.commercialOverlay2 = true;
        this.constructManageLayerList;
      }
    }
  }

  /**
   * Method that its triggered when the checkbox options are checked.
   * @param event event click check
   */
  //TODO: all hard code must be translated into variables.
  handleOptionsCheckbox(
    option?: any,
    event?: any,
    index?: number,
    switchEvent?: any
  ) {
    if (
      option?.includes('useColors') ||
      event?.currentTarget?.id.includes('useColors')
    ) {
      this.useColors = event?.target?.checked || switchEvent;

      this.manageLayersList.forEach((element) => {
        element.optionsList.forEach((opt: any) => {
          if (
            opt.parentName.includes('taxLot') &&
            opt.optionName.includes('useColors')
          ) {
            opt.enabled = event?.target?.checked || switchEvent;
          }
        });
      });
      sessionStorage.setItem(
        'checkUseColors',
        event?.target?.checked || switchEvent
      );
      if (this.useColors) {
        this.manageLayerService.setLayer(Constants.TAXLOT_LANDUSE_ID);
        sessionStorage.setItem('taxlot_landuse', Constants.TAXLOT_LANDUSE_ID);
      } else {
        sessionStorage.setItem(
          'checkUseColors',
          event?.target?.checked || switchEvent
        );
        this.manageLayerService.removeLayer(Constants.TAXLOT_LANDUSE_ID);
        sessionStorage.setItem('taxlot_landuse', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('taxLotDimension') ||
      event?.currentTarget?.id.includes('taxLotDimension')
    ) {
      this.taxLotDimension = event?.target?.checked || switchEvent;

      this.manageLayersList.forEach((element) => {
        element.optionsList.forEach((opt: any) => {
          if (
            opt.parentName.includes('taxLot') &&
            opt.optionName.includes('taxLotDimension')
          ) {
            opt.enabled = event?.target?.checked || switchEvent;
          }
        });
      });
      sessionStorage.setItem(
        'checkTaxLotDimension',
        event?.target?.checked || switchEvent
      );
      if (this.taxLotDimension) {
        this.manageLayerService.setLayer(Constants.LOTS_FACE_ID);
        sessionStorage.setItem('lot_face_labels', Constants.LOTS_FACE_ID);
      } else {
        sessionStorage.setItem(
          'checkTaxLotDimension',
          event?.target?.checked || switchEvent
        );
        this.manageLayerService.removeLayer(Constants.LOTS_FACE_ID);
        sessionStorage.setItem('lot_face_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('commercialDistrict') ||
      event?.target?.id.includes('commercialDistrict')
    ) {
      this.commercialDistrict = event?.target?.checked || switchEvent;

      if (this.commercialDistrict) {
        sessionStorage.setItem(
          'checkCommercialD',
          event?.target?.checked || switchEvent
        );
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_C);
        sessionStorage.setItem(
          'zoning_districts_c',
          Constants.ZONING_DISTRICT_C
        );
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_C_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_co',
          Constants.ZONING_DISTRICTS_C_OUTLINE
        );
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_C_LABELS);
        sessionStorage.setItem(
          'zoning_districts_c_labels',
          Constants.ZONING_DISTRICTS_C_LABELS
        );
      } else {
        sessionStorage.setItem(
          'checkCommercialD',
          event?.target?.checked ? event?.target?.checked : switchEvent
        );
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_C);
        sessionStorage.setItem('zoning_districts_c', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_C_OUTLINE
        );
        sessionStorage.setItem('zoning_districts_co', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_C_LABELS
        );
        sessionStorage.setItem('zoning_districts_c_labels', '');
        this.constructManageLayerList();
      }
    } else if (
      option?.includes('manufacturingDistricts') ||
      event?.target?.id.includes('manufacturingDistricts')
    ) {
      this.manufacturingDistricts = event?.target?.checked || switchEvent;

      if (this.manufacturingDistricts) {
        sessionStorage.setItem(
          'checkManufacturingD',
          event?.target?.checked || switchEvent
        );
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_M);
        sessionStorage.setItem(
          'zoning_districts_m',
          Constants.ZONING_DISTRICT_M
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_M_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_mo',
          Constants.ZONING_DISTRICTS_M_OUTLINE
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_M_LABELS);
        sessionStorage.setItem(
          'zoning_districts_m_labels',
          Constants.ZONING_DISTRICTS_M_LABELS
        );
      } else {
        sessionStorage.setItem(
          'checkManufacturingD',
          event?.target?.checked ? event?.target?.checked : switchEvent
        );
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_M);
        sessionStorage.setItem('zoning_districts_m', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_M_OUTLINE
        );
        sessionStorage.setItem('zoning_districts_mo', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_M_LABELS
        );
        sessionStorage.setItem('zoning_districts_m_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('residenceDistricts') ||
      event?.target?.id.includes('residenceDistricts')
    ) {
      this.residenceDistricts = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkResidenceD',
        event?.target?.checked || switchEvent
      );

      if (this.residenceDistricts) {
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_R);
        sessionStorage.setItem(
          'zoning_districts_r',
          Constants.ZONING_DISTRICT_R
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_R_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_ro',
          Constants.ZONING_DISTRICTS_R_OUTLINE
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_R_LABELS);
        sessionStorage.setItem(
          'zoning_districts_r_labels',
          Constants.ZONING_DISTRICTS_R_LABELS
        );
      } else {
        sessionStorage.setItem(
          'checkResidenceD',
          event?.target?.checked || switchEvent
        );
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_R);
        sessionStorage.setItem('zoning_districts_r', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_R_OUTLINE
        );
        sessionStorage.setItem('zoning_districts_ro', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_R_LABELS
        );
        sessionStorage.setItem('zoning_districts_r_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('parks') ||
      event?.target?.id.includes('parks')
    ) {
      this.parks = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkParks',
        event?.target?.checked || switchEvent
      );

      if (this.parks) {
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_P);
        sessionStorage.setItem(
          'zoning_districts_p',
          Constants.ZONING_DISTRICT_P
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICS_P_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_po',
          Constants.ZONING_DISTRICS_P_OUTLINE
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_P_LABELS);
        sessionStorage.setItem(
          'zoning_districts_p_labels',
          Constants.ZONING_DISTRICS_P_OUTLINE
        );
      } else {
        sessionStorage.setItem(
          'checkParks',
          event?.target?.checked || switchEvent
        );
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_P);
        sessionStorage.setItem('zoning_districts_p', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICS_P_OUTLINE
        );
        sessionStorage.setItem('zoning_districts_po', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_P_LABELS
        );
        sessionStorage.setItem('zoning_districts_p_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('batteryParkCity') ||
      event?.target?.id.includes('batteryParkCity')
    ) {
      this.batteryParkCity = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkBatteryParks',
        event?.target?.checked || switchEvent
      );

      if (this.batteryParkCity) {
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_B);
        sessionStorage.setItem(
          'zoning_districts_b',
          Constants.ZONING_DISTRICT_B
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRIC_B_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_bo',
          Constants.ZONING_DISTRIC_B_OUTLINE
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_B_LABELS);
        sessionStorage.setItem(
          'zoning_districts_b_labels',
          Constants.ZONING_DISTRICTS_B_LABELS
        );
      } else {
        sessionStorage.setItem(
          'checkBatteryParks',
          event?.target?.checked || switchEvent
        );
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_B);
        sessionStorage.setItem('zoning_districts_b', '');

        this.manageLayerService.removeLayer(Constants.ZONING_DISTRIC_B_OUTLINE);
        sessionStorage.setItem('zoning_districts_bo', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_B_LABELS
        );
        sessionStorage.setItem('zoning_districts_b_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('floodPlain1') ||
      event?.target?.id.includes('floodPlain1')
    ) {
      this.effectiveFloodV = event?.target?.checked || switchEvent;
      sessionStorage.setItem(
        'checkEffectiveFloodV',
        event?.target?.checked || switchEvent
      );
      if (this.effectiveFloodV) {
        this.manageLayerService.setLayer(Constants.EFFECTIVE_FLOOD_V_ID);
        sessionStorage.setItem(
          'Effective_Flood_v',
          Constants.EFFECTIVE_FLOOD_V_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.EFFECTIVE_FLOOD_V_ID);
        sessionStorage.setItem('Effective_Flood_v', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('floodPlain2') ||
      event?.target?.id.includes('floodPlain2')
    ) {
      this.effectiveFloodA = event?.target?.checked || switchEvent;
      sessionStorage.setItem(
        'checkEffectiveFloodA',
        event?.target?.checked || switchEvent
      );
      if (this.effectiveFloodA) {
        this.manageLayerService.setLayer(Constants.EFFECTIVE_FLOOD_A_ID);
        sessionStorage.setItem(
          'Effective_Flood_a',
          Constants.EFFECTIVE_FLOOD_A_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.EFFECTIVE_FLOOD_A_ID);
        sessionStorage.setItem('Effective_Flood_a', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('shadedFloodPlain') ||
      event?.target?.id.includes('shadedFloodPlain')
    ) {
      this.effectiveFloodX = event?.target?.checked || switchEvent;
      sessionStorage.setItem(
        'checkEffectiveFloodX',
        event?.target?.checked || switchEvent
      );
      if (this.effectiveFloodX) {
        this.manageLayerService.setLayer(Constants.EFFECTIVE_FLOOD_X_ID);
        sessionStorage.setItem(
          'Effective_Flood_x',
          Constants.EFFECTIVE_FLOOD_X_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.EFFECTIVE_FLOOD_X_ID);
        sessionStorage.setItem('Effective_Flood_x', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('preliminaryFloodPlain1') ||
      event?.target?.id.includes('preliminaryFloodPlain1')
    ) {
      this.preliminaryFloodV = event?.target?.checked || switchEvent;
      sessionStorage.setItem(
        'checkPreliminaryFloodV',
        event?.target?.checked || switchEvent
      );
      if (this.preliminaryFloodV) {
        this.manageLayerService.setLayer(Constants.PRELIMINAR_FLOOD_V_ID);
        sessionStorage.setItem(
          'Preliminary_Flood_v',
          Constants.PRELIMINAR_FLOOD_V_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.PRELIMINAR_FLOOD_V_ID);
        sessionStorage.setItem('Preliminary_Flood_v', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('preliminaryFloodPlain2') ||
      event?.target?.id.includes('preliminaryFloodPlain2')
    ) {
      this.preliminaryFloodA = event?.target?.checked || switchEvent;
      sessionStorage.setItem(
        'checkPreliminaryFloodA',
        event?.target?.checked || switchEvent
      );
      if (this.preliminaryFloodA) {
        this.manageLayerService.setLayer(Constants.PRELIMINAR_FLOOD_A_ID);
        sessionStorage.setItem(
          'Preliminary_Flood_a',
          Constants.PRELIMINAR_FLOOD_A_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.PRELIMINAR_FLOOD_A_ID);
        sessionStorage.setItem('Preliminary_Flood_a', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('shadedPreliminaryFloodPlain') ||
      event?.target?.id.includes('shadedPreliminaryFloodPlain')
    ) {
      this.preliminaryFloodX = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkPreliminaryFloodX',
        event?.target?.checked || switchEvent
      );
      if (this.preliminaryFloodX) {
        this.manageLayerService.setLayer(Constants.PRELIMINAR_FLOOD_X_ID);
        sessionStorage.setItem(
          'Preliminary_Flood_x',
          Constants.PRELIMINAR_FLOOD_X_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.PRELIMINAR_FLOOD_X_ID);
        sessionStorage.setItem('Preliminary_Flood_x', '');
      }
      this.constructManageLayerList();
    } else if (option?.includes('c1') || event?.target?.id.includes('c1')) {
      this.commercialOverlay1 = event?.target?.checked || switchEvent;

      sessionStorage.setItem(
        'checkCommercialOverlay1',
        event?.target?.checked || switchEvent
      );

      if (this.commercialOverlay1) {
        this.manageLayerService.setLayer(Constants.COMMERCIAL_OVERLAY_C1_ID);
        sessionStorage.setItem(
          'Commercial_Overlay_c1_1',
          Constants.COMMERCIAL_OVERLAY_C1_ID
        );
        this.manageLayerService.setLayer(Constants.COMMERCIAL_OVERLAY_C1_ID);
        this.manageLayerService.setLayer(
          Constants.COMMERCIAL_OVERLAY_C1_LABELS_ID
        );
        sessionStorage.setItem(
          'Commercial_Overlay_c1_1_labels',
          Constants.COMMERCIAL_OVERLAY_C1_LABELS_ID
        );
      } else {
        sessionStorage.setItem('checkCommercialOverlay1', '');
        this.manageLayerService.removeLayer(Constants.COMMERCIAL_OVERLAY_C1_ID);
        sessionStorage.setItem('Commercial_Overlay_c1_1', '');

        this.manageLayerService.removeLayer(Constants.COMMERCIAL_OVERLAY_C1_ID);

        this.manageLayerService.removeLayer(
          Constants.COMMERCIAL_OVERLAY_C1_LABELS_ID
        );
        sessionStorage.setItem('Commercial_Overlay_c1_1_labels', '');
      }
      this.constructManageLayerList();
    } else if (option?.includes('c2') || event?.target?.id.includes('c2')) {
      this.commercialOverlay2 = event?.target?.checked || switchEvent;
      sessionStorage.setItem(
        'checkCommercialOverlay2',
        event?.target?.checked || switchEvent
      );
      if (this.commercialOverlay2) {
        this.manageLayerService.setLayer(Constants.COMMERCIAL_OVERLAY_C2_ID);
        sessionStorage.setItem(
          'Commercial_Overlay_c2_1',
          Constants.COMMERCIAL_OVERLAY_C2_ID
        );

        this.manageLayerService.setLayer(
          Constants.COMMERCIAL_OVERLAY_C2_LABELS_ID
        );
        sessionStorage.setItem(
          'Commercial_Overlay_c2_1_labels',
          Constants.COMMERCIAL_OVERLAY_C2_LABELS_ID
        );
      } else {
        sessionStorage.setItem('checkCommercialOverlay2', '');
        this.manageLayerService.removeLayer(Constants.COMMERCIAL_OVERLAY_C2_ID);
        sessionStorage.setItem('Commercial_Overlay_c2_1', '');

        this.manageLayerService.removeLayer(
          Constants.COMMERCIAL_OVERLAY_C2_LABELS_ID
        );
        sessionStorage.setItem('Commercial_Overlay_c2_1_labels', '');
      }
      this.constructManageLayerList();
    }
  }

  /**
   * Method that handle the activation/deactivation of layers whitout touch the checkbox value
   * @param option option selected
   * @param event event click
   * @param index index of the selected element
   * @param switchEvent event that handle the switch event
   */
  handleLayersThroughSwitch(
    option?: any,
    event?: any,
    index?: number,
    switchEvent?: any
  ) {
    if (
      option?.includes('useColors') ||
      event?.currentTarget?.id.includes('useColors')
    ) {
      const useColorsState = sessionStorage.getItem('checkUseColors');
      if (useColorsState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.TAXLOT_LANDUSE_ID);
        sessionStorage.setItem('taxlot_landuse', Constants.TAXLOT_LANDUSE_ID);
      } else {
        this.manageLayerService.removeLayer(Constants.TAXLOT_LANDUSE_ID);
        sessionStorage.setItem('taxlot_landuse', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('taxLotDimension') ||
      event?.currentTarget?.id.includes('taxLotDimension')
    ) {
      const taxLotDState = sessionStorage.getItem('checkTaxLotDimension');
      if (taxLotDState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.LOTS_FACE_ID);
        sessionStorage.setItem('lot_face_labels', Constants.LOTS_FACE_ID);
      } else {
        this.manageLayerService.removeLayer(Constants.LOTS_FACE_ID);
        sessionStorage.setItem('lot_face_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('commercialDistrict') ||
      event?.target?.id.includes('commercialDistrict')
    ) {
      const commercialDistrictState =
        sessionStorage.getItem('checkCommercialD');
      if (commercialDistrictState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_C);
        sessionStorage.setItem(
          'zoning_districts_c',
          Constants.ZONING_DISTRICT_C
        );
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_C_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_co',
          Constants.ZONING_DISTRICTS_C_OUTLINE
        );
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_C_LABELS);
        sessionStorage.setItem(
          'zoning_districts_c_labels',
          Constants.ZONING_DISTRICTS_C_LABELS
        );
      } else {
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_C);
        sessionStorage.setItem('zoning_districts_c', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_C_OUTLINE
        );
        sessionStorage.setItem('zoning_districts_co', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_C_LABELS
        );
        sessionStorage.setItem('zoning_districts_c_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('manufacturingDistricts') ||
      event?.target?.id.includes('manufacturingDistricts')
    ) {
      const manufacturingDistrictState = sessionStorage.getItem(
        'checkManufacturingD'
      );

      if (manufacturingDistrictState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_M);
        sessionStorage.setItem(
          'zoning_districts_m',
          Constants.ZONING_DISTRICT_M
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_M_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_mo',
          Constants.ZONING_DISTRICTS_M_OUTLINE
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_M_LABELS);
        sessionStorage.setItem(
          'zoning_districts_m_labels',
          Constants.ZONING_DISTRICTS_M_LABELS
        );
      } else {
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_M);
        sessionStorage.setItem('zoning_districts_m', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_M_OUTLINE
        );
        sessionStorage.setItem('zoning_districts_mo', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_M_LABELS
        );
        sessionStorage.setItem('zoning_districts_m_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('residenceDistricts') ||
      event?.target?.id.includes('residenceDistricts')
    ) {
      const residenceDistrictState = sessionStorage.getItem('checkResidenceD');

      if (residenceDistrictState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_R);
        sessionStorage.setItem(
          'zoning_districts_r',
          Constants.ZONING_DISTRICT_R
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_R_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_ro',
          Constants.ZONING_DISTRICTS_R_OUTLINE
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_R_LABELS);
        sessionStorage.setItem(
          'zoning_districts_r_labels',
          Constants.ZONING_DISTRICTS_R_LABELS
        );
      } else {
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_R);
        sessionStorage.setItem('zoning_districts_r', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_R_OUTLINE
        );
        sessionStorage.setItem('zoning_districts_ro', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_R_LABELS
        );
        sessionStorage.setItem('zoning_districts_r_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('parks') ||
      event?.target?.id.includes('parks')
    ) {
      const parksState = sessionStorage.getItem('checkParks');

      if (parksState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_P);
        sessionStorage.setItem(
          'zoning_districts_p',
          Constants.ZONING_DISTRICT_P
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICS_P_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_po',
          Constants.ZONING_DISTRICS_P_OUTLINE
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_P_LABELS);
        sessionStorage.setItem(
          'zoning_districts_p_labels',
          Constants.ZONING_DISTRICS_P_OUTLINE
        );
      } else {
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_P);
        sessionStorage.setItem('zoning_districts_p', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICS_P_OUTLINE
        );
        sessionStorage.setItem('zoning_districts_po', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_P_LABELS
        );
        sessionStorage.setItem('zoning_districts_p_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('batteryParkCity') ||
      event?.target?.id.includes('batteryParkCity')
    ) {
      const batteryParksState = sessionStorage.getItem('checkBatteryParks');

      if (batteryParksState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.ZONING_DISTRICT_B);
        sessionStorage.setItem(
          'zoning_districts_b',
          Constants.ZONING_DISTRICT_B
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRIC_B_OUTLINE);
        sessionStorage.setItem(
          'zoning_districts_bo',
          Constants.ZONING_DISTRIC_B_OUTLINE
        );

        this.manageLayerService.setLayer(Constants.ZONING_DISTRICTS_B_LABELS);
        sessionStorage.setItem(
          'zoning_districts_b_labels',
          Constants.ZONING_DISTRICTS_B_LABELS
        );
      } else {
        this.manageLayerService.removeLayer(Constants.ZONING_DISTRICT_B);
        sessionStorage.setItem('zoning_districts_b', '');

        this.manageLayerService.removeLayer(Constants.ZONING_DISTRIC_B_OUTLINE);
        sessionStorage.setItem('zoning_districts_bo', '');

        this.manageLayerService.removeLayer(
          Constants.ZONING_DISTRICTS_B_LABELS
        );
        sessionStorage.setItem('zoning_districts_b_labels', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('floodPlain1') ||
      event?.target?.id.includes('floodPlain1')
    ) {
      const effectiveFloodVState = sessionStorage.getItem(
        'checkEffectiveFloodV'
      );

      if (effectiveFloodVState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.EFFECTIVE_FLOOD_V_ID);
        sessionStorage.setItem(
          'Effective_Flood_v',
          Constants.EFFECTIVE_FLOOD_V_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.EFFECTIVE_FLOOD_V_ID);
        sessionStorage.setItem('Effective_Flood_v', '');
      }

      this.constructManageLayerList();
    } else if (
      option?.includes('floodPlain2') ||
      event?.target?.id.includes('floodPlain2')
    ) {
      const effectiveFloodAState = sessionStorage.getItem(
        'checkEffectiveFloodA'
      );

      if (effectiveFloodAState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.EFFECTIVE_FLOOD_A_ID);
        sessionStorage.setItem(
          'Effective_Flood_a',
          Constants.EFFECTIVE_FLOOD_A_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.EFFECTIVE_FLOOD_A_ID);
        sessionStorage.setItem('Effective_Flood_a', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('shadedFloodPlain') ||
      event?.target?.id.includes('shadedFloodPlain')
    ) {
      const effectiveFloodXState = sessionStorage.getItem(
        'checkEffectiveFloodX'
      );
      if (effectiveFloodXState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.EFFECTIVE_FLOOD_X_ID);
        sessionStorage.setItem(
          'Effective_Flood_x',
          Constants.EFFECTIVE_FLOOD_X_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.EFFECTIVE_FLOOD_X_ID);
        sessionStorage.setItem('Effective_Flood_x', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('preliminaryFloodPlain1') ||
      event?.target?.id.includes('preliminaryFloodPlain1')
    ) {
      const preliminaryFloodVState = sessionStorage.getItem(
        'checkPreliminaryFloodV'
      );
      if (preliminaryFloodVState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.PRELIMINAR_FLOOD_V_ID);
        sessionStorage.setItem(
          'Preliminary_Flood_v',
          Constants.PRELIMINAR_FLOOD_V_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.PRELIMINAR_FLOOD_V_ID);
        sessionStorage.setItem('Preliminary_Flood_v', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('preliminaryFloodPlain2') ||
      event?.target?.id.includes('preliminaryFloodPlain2')
    ) {
      const preliminaryFloodAState = sessionStorage.getItem(
        'checkPreliminaryFloodA'
      );
      if (preliminaryFloodAState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.PRELIMINAR_FLOOD_A_ID);
        sessionStorage.setItem(
          'Preliminary_Flood_a',
          Constants.PRELIMINAR_FLOOD_A_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.PRELIMINAR_FLOOD_A_ID);
        sessionStorage.setItem('Preliminary_Flood_a', '');
      }
      this.constructManageLayerList();
    } else if (
      option?.includes('shadedPreliminaryFloodPlain') ||
      event?.target?.id.includes('shadedPreliminaryFloodPlain')
    ) {
      const preliminaryFloodXState = sessionStorage.getItem(
        'checkPreliminaryFloodX'
      );
      if (preliminaryFloodXState === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.PRELIMINAR_FLOOD_X_ID);
        sessionStorage.setItem(
          'Preliminary_Flood_x',
          Constants.PRELIMINAR_FLOOD_X_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.PRELIMINAR_FLOOD_X_ID);
        sessionStorage.setItem('Preliminary_Flood_x', '');
      }
      this.constructManageLayerList();
    } else if (option?.includes('c1') || event?.target?.id.includes('c1')) {
      const c1State = sessionStorage.getItem('checkCommercialOverlay1');
      if (c1State === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.COMMERCIAL_OVERLAY_C1_ID);
        sessionStorage.setItem(
          'Commercial_Overlay_c1_1',
          Constants.COMMERCIAL_OVERLAY_C1_ID
        );
        this.manageLayerService.setLayer(Constants.COMMERCIAL_OVERLAY_C1_ID);
        this.manageLayerService.setLayer(
          Constants.COMMERCIAL_OVERLAY_C1_LABELS_ID
        );
        sessionStorage.setItem(
          'Commercial_Overlay_c1_1_labels',
          Constants.COMMERCIAL_OVERLAY_C1_LABELS_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.COMMERCIAL_OVERLAY_C1_ID);
        sessionStorage.setItem('Commercial_Overlay_c1_1', '');

        this.manageLayerService.removeLayer(Constants.COMMERCIAL_OVERLAY_C1_ID);

        this.manageLayerService.removeLayer(
          Constants.COMMERCIAL_OVERLAY_C1_LABELS_ID
        );
        sessionStorage.setItem('Commercial_Overlay_c1_1_labels', '');
      }
    } else if (option?.includes('c2') || event?.target?.id.includes('c2')) {
      const c2State = sessionStorage.getItem('checkCommercialOverlay2');
      if (c2State === 'true' && !!switchEvent) {
        this.manageLayerService.setLayer(Constants.COMMERCIAL_OVERLAY_C2_ID);
        sessionStorage.setItem(
          'Commercial_Overlay_c2_1',
          Constants.COMMERCIAL_OVERLAY_C2_ID
        );

        this.manageLayerService.setLayer(
          Constants.COMMERCIAL_OVERLAY_C2_LABELS_ID
        );
        sessionStorage.setItem(
          'Commercial_Overlay_c2_1_labels',
          Constants.COMMERCIAL_OVERLAY_C2_LABELS_ID
        );
      } else {
        this.manageLayerService.removeLayer(Constants.COMMERCIAL_OVERLAY_C2_ID);
        sessionStorage.setItem('Commercial_Overlay_c2_1', '');

        this.manageLayerService.removeLayer(
          Constants.COMMERCIAL_OVERLAY_C2_LABELS_ID
        );
        sessionStorage.setItem('Commercial_Overlay_c2_1_labels', '');
      }
    }
  }

  validateContainer() {
    this.validateContainersOpened.emit('ManageLayers');
  }

  close() {
    this.navBarCloseService.turnOffOption(Constants.MANAGE_LAYERS);
    this.closeItem.emit(Constants.MANAGE_LAYERS);
  }

  /**
   * Method that handles the selection of the switch lot area
   * @param $event boolean
   */
  switchToggle(index: number, $event: any) {
    this.manageLayersList[index].switch.enabled = $event;
    switch (this.manageLayersList[index].optionName) {
      case Constants.ZONINGDISTRICT:
        this.manageLayersList[index].switch.enabled = $event;
        const zoningDistrictFirstCheckState = sessionStorage.getItem(
          'firstTimeSwitchZoningDistrict'
        );
        const checkCommercialDistrict =
          sessionStorage.getItem('checkCommercialD');
        const checkManufacturingD = sessionStorage.getItem(
          'checkManufacturingD'
        );
        const checkResidenceD = sessionStorage.getItem('checkResidenceD');
        const checkParks = sessionStorage.getItem('checkParks');
        const checkBatteryParks = sessionStorage.getItem('checkBatteryParks');

        sessionStorage.setItem('firstTimeSwitchCommercialOverlay', '2');
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchCommercialOverlay', $event);

        if ($event === true && zoningDistrictFirstCheckState === '2') {
          checkCommercialDistrict === 'true'
            ? (this.commercialDistrict = true)
            : (this.commercialDistrict = false);
          checkManufacturingD === 'true'
            ? (this.manufacturingDistricts = true)
            : (this.manufacturingDistricts = false);
          checkResidenceD === 'true'
            ? (this.residenceDistricts = true)
            : (this.residenceDistricts = false);
          checkParks === 'true' ? (this.parks = true) : (this.parks = false);
          checkBatteryParks === 'true'
            ? (this.batteryParkCity = true)
            : (this.batteryParkCity = false);
          this.constructManageLayerList();
        }

        sessionStorage.setItem('switchZoningDistrict', $event);
        this.manageLayersList[index].optionsList.forEach((ele: any) => {
          this.handleLayersThroughSwitch(ele.optionName, null, index, $event);
        });
        break;
      case Constants.COMMERCIAL_OVERLAY:
        const commercialOverlayFirstCheckState = sessionStorage.getItem(
          'firstTimeSwitchCommercialOverlay'
        );
        const checkCommercialOverlay1 = sessionStorage.getItem(
          'checkCommercialOverlay1'
        );
        const checkCommercialOverlay2 = sessionStorage.getItem(
          'checkCommercialOverlay2'
        );

        sessionStorage.setItem('firstTimeSwitchCommercialOverlay', '2');
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchCommercialOverlay', $event);

        if ($event === true && commercialOverlayFirstCheckState === '2') {
          checkCommercialOverlay1 === 'true'
            ? (this.commercialOverlay1 = true)
            : (this.commercialOverlay1 = false);
          checkCommercialOverlay2 === 'true'
            ? (this.commercialOverlay2 = true)
            : (this.commercialOverlay2 = false);
          this.constructManageLayerList();
        }
        this.manageLayersList[index].optionsList.forEach((ele: any) => {
          this.handleLayersThroughSwitch(ele.optionName, null, index, $event);
        });
        break;
      case Constants.PRELIMINARY_FLOODZONE:
        const preliminaryFirstClickState = sessionStorage.getItem(
          'firstTimeSwitchFloodPreliminary'
        );
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchPreliminaryFloodZone', $event);
        if (
          preliminaryFirstClickState === '1' ||
          preliminaryFirstClickState === null
        ) {
          sessionStorage.setItem('firstTimeSwitchFloodPreliminary', '2');
          this.manageLayersList[index].optionsList.forEach((ele: any) => {
            this.handleOptionsCheckbox(ele.optionName, null, index, $event);
          });
        } else {
          this.manageLayersList[index].optionsList.forEach((ele: any) => {
            this.handleLayersThroughSwitch(ele.optionName, null, index, $event);
          });
        }
        break;
      case Constants.EFFECTIVE_FLOODZONE:
        const effectiveFirstClickState = sessionStorage.getItem(
          'firstTimeSwitchFloodEffective'
        );
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchEffectiveFloodZone', $event);
        if (
          effectiveFirstClickState === '1' ||
          effectiveFirstClickState === null
        ) {
          sessionStorage.setItem('firstTimeSwitchFloodEffective', '2');
          this.manageLayersList[index].optionsList.forEach((ele: any) => {
            this.handleOptionsCheckbox(ele.optionName, null, index, $event);
          });
        } else {
          this.manageLayersList[index].optionsList.forEach((ele: any) => {
            this.handleLayersThroughSwitch(ele.optionName, null, index, $event);
          });
        }
        break;
      case Constants.TAXLOT:
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchTaxLot', $event);
        const taxLotFirstCheckState = sessionStorage.getItem(
          'firstTimeSwitchTaxLot'
        );
        const checkTaxLotD = sessionStorage.getItem('checkTaxLotDimension');
        const checkUseColors = sessionStorage.getItem('checkUseColors');

        sessionStorage.setItem('firstTimeSwitchCommercialOverlay', '2');
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchCommercialOverlay', $event);

        if ($event === true && taxLotFirstCheckState === '2') {
          checkTaxLotD === 'true'
            ? (this.taxLotDimension = true)
            : (this.taxLotDimension = false);
          checkUseColors === 'true'
            ? (this.useColors = true)
            : (this.useColors = false);
          this.constructManageLayerList();
        }

        this.manageLayersList[index].optionsList.forEach((ele: any) => {
          this.handleLayersThroughSwitch(ele.optionName, null, index, $event);
        });
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.TAXLOT_OUTLINE_ID);
          sessionStorage.setItem(
            'taxlot_landuse_o',
            Constants.TAXLOT_OUTLINE_ID
          );

          this.manageLayerService.setLayer(Constants.LOT_OUTLINE);
          sessionStorage.setItem('lot_outline', Constants.LOT_OUTLINE);

          this.manageLayerService.setLayer(Constants.LOT_SELECT);
          sessionStorage.setItem('lot_select', Constants.LOT_SELECT);

          this.manageLayerService.setLayer(Constants.LOTS);
          sessionStorage.setItem('lots', Constants.LOTS);

          this.manageLayerService.setLayer(Constants.LOT_LABELS);
          sessionStorage.setItem('lot_labels', Constants.LOT_LABELS);
        } else {
          this.manageLayerService.removeLayer(Constants.TAXLOT_OUTLINE_ID);
          sessionStorage.setItem('taxlot_landuse_o', '');

          this.manageLayerService.removeLayer(Constants.LOT_OUTLINE);
          sessionStorage.setItem('lot_outline', '');

          this.manageLayerService.removeLayer(Constants.LOT_SELECT);
          sessionStorage.setItem('lot_select', '');

          this.manageLayerService.removeLayer(Constants.LOTS);
          sessionStorage.setItem('lots', '');
          this.manageLayerService.removeLayer(Constants.LOT_LABELS);
          sessionStorage.setItem('lot_labels', '');
        }
        break;
      case Constants.HISTORIC_DISTRICT:
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchHistoricDistrict', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.HISTORIC_DISTRICT_ID);
          sessionStorage.setItem(
            'Historict_District',
            Constants.HISTORIC_DISTRICT_ID
          );
          this.manageLayerService.setLayer(Constants.HISTORIC_DISTRICT_LABELS);
          sessionStorage.setItem(
            'Historict_District_l',
            Constants.HISTORIC_DISTRICT_LABELS
          );

          this.manageLayerService.setLayer(
            Constants.HISTORIC_DISTRICT_OUTLINES
          );
          sessionStorage.setItem(
            'Historict_District_o',
            Constants.HISTORIC_DISTRICT_OUTLINES
          );
        } else {
          this.manageLayerService.removeLayer(Constants.HISTORIC_DISTRICT_ID);
          sessionStorage.setItem('Historict_District', '');
          this.manageLayerService.removeLayer(
            Constants.HISTORIC_DISTRICT_LABELS
          );
          sessionStorage.setItem('Historict_District_l', '');

          this.manageLayerService.removeLayer(
            Constants.HISTORIC_DISTRICT_OUTLINES
          );
          sessionStorage.setItem('Historict_District_o', '');
        }

        break;

      case Constants.TOPOGRAPHY:
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchTopography', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.TOPOGRAPHY_ID);
          sessionStorage.setItem('contour_4326', Constants.TOPOGRAPHY_ID);
          this.manageLayerService.setLayer(Constants.TOPOGRAPHY_LABELS_ID);
          sessionStorage.setItem(
            'contour_4326_labels',
            Constants.TOPOGRAPHY_LABELS_ID
          );
        } else {
          this.manageLayerService.removeLayer(Constants.TOPOGRAPHY_ID);
          sessionStorage.setItem('contour_4326', '');
          this.manageLayerService.removeLayer(Constants.TOPOGRAPHY_LABELS_ID);
          sessionStorage.setItem('contour_4326_labels', '');
        }

        break;

      case Constants.BOROUGHS:
        this.manageLayersList[index].switch.enabled = $event;
        sessionStorage.setItem('switchBoroughs', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.BOROUGHS_ID);
          this.manageLayerService.setLayer(Constants.BOROUGHS_LABELS_ID);
          sessionStorage.setItem('borough_boundaries', Constants.BOROUGHS_ID);
          sessionStorage.setItem(
            'borough_boundaries_l',
            Constants.BOROUGHS_LABELS_ID
          );
        } else {
          this.manageLayerService.removeLayer(Constants.BOROUGHS_ID);
          sessionStorage.setItem('borough_boundaries', '');
          this.manageLayerService.removeLayer(Constants.BOROUGHS_LABELS_ID);
          sessionStorage.setItem('borough_boundaries_l', '');
        }

        break;

      case Constants.E_DESIGNATIONS:
        sessionStorage.setItem('switchEdesignations', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.EDESIGNATION_ID);
          sessionStorage.setItem(
            'E_Designation-1memwl',
            Constants.EDESIGNATION_ID
          );
        } else {
          this.manageLayerService.removeLayer(Constants.EDESIGNATION_ID);
          sessionStorage.setItem('E_Designation-1memwl', '');
        }
        break;

      case Constants.MIH:
        sessionStorage.setItem('switchMIH', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.MIH_ID);
          sessionStorage.setItem(
            'Mandatory_Inclusionary_Housin-1ef841',
            Constants.MIH_ID
          );
          sessionStorage.setItem('MIH_o', Constants.MIH_OUTLINE);
          this.manageLayerService.setLayer(Constants.MIH_OUTLINE);
        } else {
          this.manageLayerService.removeLayer(Constants.MIH_ID);
          sessionStorage.setItem('Mandatory_Inclusionary_Housin-1ef841', '');
          sessionStorage.setItem('MIH_o', '');
          this.manageLayerService.removeLayer(Constants.MIH_OUTLINE);
        }
        break;

      case Constants.IHDA:
        sessionStorage.setItem('switchIHDA', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.IHDA_ID);
          sessionStorage.setItem(
            'Inclusionary_Housing_Designat-61hori',
            Constants.IHDA_ID
          );
          sessionStorage.setItem('IHDA_o', Constants.IHDA_OUTLINE);
          this.manageLayerService.setLayer(Constants.IHDA_OUTLINE);
        } else {
          this.manageLayerService.removeLayer(Constants.IHDA_ID);
          sessionStorage.setItem('Inclusionary_Housing_Designat-61hori', '');
          sessionStorage.setItem('IHDA_o', '');
          this.manageLayerService.removeLayer(Constants.IHDA_OUTLINE);
        }
        break;

      case Constants.LIMITED_HEIGHT_DISTRICT:
        sessionStorage.setItem('switchLimitedHeightD', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(
            Constants.LIMITED_HEIGHT_DISTRICTS_ID
          );
          sessionStorage.setItem(
            'Limited_Height_Districts-3i64wv',
            Constants.LIMITED_HEIGHT_DISTRICTS_ID
          );
          this.manageLayerService.setLayer(
            Constants.LIMITED_HEIGHT_DISTRICTS_LABELS
          );
          sessionStorage.setItem(
            'Limited_Height_Districts_l',
            Constants.LIMITED_HEIGHT_DISTRICTS_LABELS
          );

          this.manageLayerService.setLayer(
            Constants.LIMITED_HEIGHT_DISTRICTS_OUTLINE
          );
          sessionStorage.setItem(
            'Limited_Height_Districts_o',
            Constants.LIMITED_HEIGHT_DISTRICTS_OUTLINE
          );
        } else {
          this.manageLayerService.removeLayer(
            Constants.LIMITED_HEIGHT_DISTRICTS_ID
          );
          sessionStorage.setItem('Limited_Height_Districts-3i64wv', '');

          this.manageLayerService.removeLayer(
            Constants.LIMITED_HEIGHT_DISTRICTS_LABELS
          );
          sessionStorage.setItem('Limited_Height_Districts_l', '');

          this.manageLayerService.removeLayer(
            Constants.LIMITED_HEIGHT_DISTRICTS_OUTLINE
          );
          sessionStorage.setItem('Limited_Height_Districts_o', '');
        }
        break;

      case Constants.SPECIAL_DISTRICT:
        sessionStorage.setItem('switchSpecialD', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(
            Constants.SPECIAL_PURPOSE_DISCTRICT_ID
          );
          sessionStorage.setItem(
            'Special_Purpose_District',
            Constants.SPECIAL_PURPOSE_DISCTRICT_ID
          );

          this.manageLayerService.setLayer(
            Constants.SPECIAL_PURPOSE_DISCTRICT_OUTLINE_ID
          );
          sessionStorage.setItem(
            'Special_Purpose_District_outline',
            Constants.SPECIAL_PURPOSE_DISCTRICT_OUTLINE_ID
          );

          this.manageLayerService.setLayer(
            Constants.SPECIAL_PURPOSE_DISCTRICT_LABEL
          );
          sessionStorage.setItem(
            'Special_Purpose_District_l',
            Constants.SPECIAL_PURPOSE_DISCTRICT_LABEL
          );
        } else {
          this.manageLayerService.removeLayer(
            Constants.SPECIAL_PURPOSE_DISCTRICT_ID
          );
          sessionStorage.setItem('Special_Purpose_District', '');

          this.manageLayerService.removeLayer(
            Constants.SPECIAL_PURPOSE_DISCTRICT_OUTLINE_ID
          );
          sessionStorage.setItem('Special_Purpose_District_outline', '');

          this.manageLayerService.removeLayer(
            Constants.SPECIAL_PURPOSE_DISCTRICT_LABEL
          );
          sessionStorage.setItem('Special_Purpose_District_l', '');
        }
        break;

      case Constants.SPECIAL_SUBDISTRICT:
        sessionStorage.setItem('switchSpecialSubD', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_ID
          );
          sessionStorage.setItem(
            'Special_Purpose_Subdistrict',
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_ID
          );

          this.manageLayerService.setLayer(
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_OUTLINE_ID
          );
          sessionStorage.setItem(
            'Special_Purpose_Subdistrict_outline',
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_OUTLINE_ID
          );
          this.manageLayerService.setLayer(
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_LABEL
          );
          sessionStorage.setItem(
            'Special_Purpose_Subdistrict_l',
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_LABEL
          );
        } else {
          this.manageLayerService.removeLayer(
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_ID
          );
          sessionStorage.setItem('Special_Purpose_Subdistrict', '');

          this.manageLayerService.removeLayer(
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_OUTLINE_ID
          );
          sessionStorage.setItem('Special_Purpose_Subdistrict_outline', '');

          this.manageLayerService.removeLayer(
            Constants.SPECIAL_PURPOSE_SUBDISCTRICT_LABEL
          );
          sessionStorage.setItem('Special_Purpose_Subdistrict_l', '');
        }
        break;

      case Constants.LANDMARK_SITE:
        sessionStorage.setItem('switchLandmarkSite', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.LANDMARK_SITES_ID);
          sessionStorage.setItem(
            'Landmark_Sites-8qhywx',
            Constants.LANDMARK_SITES_ID
          );
        } else {
          this.manageLayerService.removeLayer(Constants.LANDMARK_SITES_ID);
          sessionStorage.setItem('Landmark_Sites-8qhywx', '');
        }
        break;

      case Constants.SUBWAY_LINES:
        sessionStorage.setItem('switchSubwayLine', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.SUBWAY_LINES_ID);
          sessionStorage.setItem(
            'Subway_Lines-0ovpqb',
            Constants.SUBWAY_LINES_ID
          );

          this.manageLayerService.setLayer(Constants.SUBWAY_LINES_STATIONS_ID);
          sessionStorage.setItem(
            'Subway_Stations-0g0jn1',
            Constants.SUBWAY_LINES_STATIONS_ID
          );

          this.manageLayerService.setLayer(
            Constants.SUBWAY_LINES_STATIONS_LABELS
          );
          sessionStorage.setItem(
            'subway_stations_l',
            Constants.SUBWAY_LINES_STATIONS_LABELS
          );
        } else {
          this.manageLayerService.removeLayer(Constants.SUBWAY_LINES_ID);
          sessionStorage.setItem('Subway_Stations-0g0jn1', '');

          this.manageLayerService.removeLayer(
            Constants.SUBWAY_LINES_STATIONS_ID
          );
          sessionStorage.setItem('Subway_Stations-0g0jn1', '');

          this.manageLayerService.removeLayer(
            Constants.SUBWAY_LINES_STATIONS_LABELS
          );
          sessionStorage.setItem('subway_stations_l', '');
        }
        break;

      case Constants.COMMUNITY_DISTRICT:
        sessionStorage.setItem('switchCommunityD', $event);
        if (this.manageLayersList[index].switch.enabled) {
          this.manageLayerService.setLayer(Constants.COMMUNITY_DISTRICT_ID);
          sessionStorage.setItem(
            'community_districts',
            Constants.COMMUNITY_DISTRICT_ID
          );

          this.manageLayerService.setLayer(
            Constants.COMMUNITY_DISTRICT_LABELS_ID
          );
          sessionStorage.setItem(
            'community_districts_l',
            Constants.COMMUNITY_DISTRICT_LABELS_ID
          );
        } else {
          this.manageLayerService.removeLayer(Constants.COMMUNITY_DISTRICT_ID);
          sessionStorage.setItem('community_districts', '');

          this.manageLayerService.removeLayer(
            Constants.COMMUNITY_DISTRICT_LABELS_ID
          );
          sessionStorage.setItem('community_districts_l', '');
        }
        break;

      default:
        break;
    }
  }

  /**
   * Method that handles the selection of the switch airHeight
   * @param $event boolean
   */
  switchToggleAirHeight(index: number, $event: any) {
    this.airRightsList[index].switch.enabled = $event;
    switch (this.airRightsList[index].optionName) {
      case Constants.TRANSFERABLE_DEVELOPMENT:
        sessionStorage.setItem('switchTransferableDevelopment', $event);
        if (this.airRightsList[index].switch.enabled) {
        }
        break;
    }
  }

  /**
   * Method that handles the selection of the tab option
   * @param index index value
   */
  handleTab(index: number) {
    this.infoTypeData.forEach((element) => {
      element.active = false;
    });
    this.generalSelected = false;
    this.airRightsSelected = false;
    this.statisticsSelected = false;
    switch (this.infoTypeData[index].type) {
      case Constants.GENERAL:
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.generalSelected = this.infoTypeData[index].active;
        sessionStorage.setItem(
          'generalManageLayers',
          String(this.generalSelected)
        );
        sessionStorage.setItem('airRightsManageLayers', '');
        sessionStorage.setItem('statisticsManageLayers', '');
        break;

      case Constants.AIR_RIGHTS:
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.airRightsSelected = this.infoTypeData[index].active;
        sessionStorage.setItem(
          'airRightsManageLayers',
          String(this.airRightsSelected)
        );
        sessionStorage.setItem('generalManageLayers', String(''));
        sessionStorage.setItem('statisticsManageLayers', '');
        break;

      case Constants.STATISTICS:
        this.infoTypeData[index].active = !this.infoTypeData[index].active;
        this.statisticsSelected = this.infoTypeData[index].active;
        sessionStorage.setItem(
          'statisticsManageLayers',
          String(this.statisticsSelected)
        );
        sessionStorage.setItem('generalManageLayers', String(''));
        sessionStorage.setItem('airRightsManageLayers', '');
        break;

      default:
        break;
    }
  }
}
