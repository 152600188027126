import { Component, OnInit, Input  } from '@angular/core';

declare let google: any;  // Declarar google como una variable global

@Component({
  selector: 'app-streetview',
  templateUrl: './streetview.component.html',
  styleUrls: ['./streetview.component.scss']
})
export class StreetviewComponent  implements OnInit {

  @Input() lotProperties: any;  // Esta propiedad recibirá los datos de features

  private panorama:any;
  private streetViewService = new google.maps.StreetViewService();

  constructor() { }

  ngOnInit(): void {
    if (typeof google !== 'undefined') {
      this.initialize(this.lotProperties);
      console.log('Google Maps API cargada correctamente');
    } else {
      console.error('Google Maps API no está disponible');
    }
  }

  initialize(features: any): void {
    const fenway = { lat: features.CENTROID[1], lng: features.CENTROID[0] };
    const whereToLookLatLng = new google.maps.LatLng({ lat: features.CENTROID[1], lng: features.CENTROID[0] });

    const panoRequest = {
      location: new google.maps.LatLng(fenway.lat, fenway.lng),
      preference: google.maps.StreetViewPreference.NEAREST,
      radius: 1000,
      source: google.maps.StreetViewSource.OUTDOOR
    };
    
    this.streetViewService.getPanorama(panoRequest, (panoData: any, status: any) => {
      if (status === google.maps.StreetViewStatus.OK && panoData) {
        this.panorama = new google.maps.StreetViewPanorama(
          document.getElementById('pano') as HTMLElement,
          {
            pano: panoData.location.pano,
            pov: {
              heading: 34,
              pitch: 10,
            }
          }
        );

        if (panoData != null) {
          const ManLatLng = panoData.location.latLng;
          const heading = google.maps.geometry.spherical.computeHeading(ManLatLng, whereToLookLatLng);
          const pov = this.panorama.getPov();
          pov.heading = heading;
          this.panorama.setPov(pov);
        }
      } else {
        console.error('Error al obtener panorama:', status);
      }
    });
  }

  movePanorama(features: any){
    const fenway = { lat: features.CENTROID[1], lng: features.CENTROID[0] };
    const whereToLookLatLng = new google.maps.LatLng({ lat: features.CENTROID[1], lng: features.CENTROID[0] });

    const request = {
      location: new google.maps.LatLng(fenway.lat, fenway.lng),
      preference: google.maps.StreetViewPreference.NEAREST,
      radius: 1000,
      source: google.maps.StreetViewSource.OUTDOOR
    };

    this.streetViewService.getPanorama(request, (panoData: any, status: any) => {
      if (status === google.maps.StreetViewStatus.OK && panoData) {
        if (panoData != null) {
          this.panorama.setPosition(panoData.location.latLng);
          const ManLatLng = panoData.location.latLng;
          const heading = google.maps.geometry.spherical.computeHeading(ManLatLng, whereToLookLatLng);
          const pov = this.panorama.getPov();
          pov.heading = heading;
          this.panorama.setPov(pov);
        }
      } else {
        console.error('Error al obtener panorama:', status);
      }
    });
  }
}
