import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import { Sky } from 'three/examples/jsm/objects/Sky';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader';

import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min';

import { getPosition } from 'suncalc';

@Component({
  selector: 'app-view-export',
  templateUrl: './view-export.component.html',
  styleUrls: ['./view-export.component.scss'],
})
export class ViewExportComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(public activeModal: NgbActiveModal) {}

  bbl: any;
  exportBBL: any;
  hideLoader: boolean = false;
  scene: any;
  camera: any;
  renderer: any;
  obj: any;
  doc: any;
  controls: any;
  rhino: any;
  objectsIntersect: any = [];

  ambientLight: any;
  sunLight: any;
  sky: any;

  params = {
    Opacity: 60,
    Buildings: true,
    Lines: true,
    animateTime: true,
    showSunSurface: true,
    showAnalemmas: true,
    showSunDayPath: true,
    minute: new Date().getMinutes(),
    hour: new Date().getHours(),
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    latitude: 40.756014041236135,
    longitude: -73.97801067921247,
    radius: 1800,
    timeSpeed: 10000,
  };

  skyControl = {
    sky: true,
    turbidity: 10,
    rayleigh: 0.5,
    mieCoefficient: 0,
    mieDirectionalG: 0.5,
    exposure: 0.5,
  };

  updatables = [];
  sunPathLight = new THREE.Group();
  sphereLight = new THREE.Group();

  date: any = new Date();

  filename: any;
  extension: any;
  filetypes: any[] | undefined;

  async ngOnInit() {
    this.filename = this.bbl;
    this.filetypes = [{ name: 'Rhino 8 3D Models', extension: '3dm' }];
    this.extension = this.filetypes[0].extension;
  }

  async ngOnDestroy() {
    for (let i = this.scene.children.length - 1; i >= 0; i--) {
      const obj = this.scene.children[i];
      this.scene.remove(obj);
    }
    this.renderer.renderLists.dispose();
    this.renderer.dispose();
    this.scene = null;
    this.renderer = null;
    this.camera = null;
    this.obj = null;
    this.doc = null;
    this.controls = null;
    this.rhino = null;

    THREE.Cache.clear();
  }

  async ngAfterViewInit() {
    THREE.Object3D.DEFAULT_UP = new THREE.Vector3(0, 1, 0);

    const scene = new THREE.Scene();
    scene.background = new THREE.Color('#000101');

    const sky = new Sky();
    sky.scale.setScalar(450000);

    scene.add(sky);

    const camera = new THREE.PerspectiveCamera(
      45,
      (window.innerWidth * 0.975 - 25.7) / (window.innerHeight * 0.7),
      1,
      10000
    );
    camera.position.set(900, 400, 100);
    camera.updateProjectionMatrix();

    const feetToMeters = 0.3048; // 1 pie es aproximadamente 0.3048 metros
    scene.scale.set(feetToMeters, feetToMeters, feetToMeters);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(
      window.innerWidth * 0.975 - 25.7,
      window.innerHeight * 0.7
    );
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.VSMShadowMap;
    // renderer.toneMapping = THREE.ReinhardToneMapping
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.5;

    document.body.appendChild(renderer.domElement);

    const gridHelper = new THREE.GridHelper(2000, 10);
    //  gridHelper.rotation.x = Math.PI / 2
    scene.add(gridHelper);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };

    const ambientLight = new THREE.AmbientLight('white', 1.7);
    scene.add(ambientLight);

    const spotLight = new THREE.DirectionalLight('white', 4);
    spotLight.position.set(5000, 5000, 5000);

    spotLight.castShadow = false;
    scene.add(spotLight);

    const spotLightHelper = new THREE.SpotLightHelper(spotLight);
    scene.add(spotLightHelper);

    const sunLight = new THREE.DirectionalLight('white', 8);
    sunLight.castShadow = true;
    sunLight.shadow.bias = -0.00086;
    sunLight.shadow.camera.left = -this.params.radius;
    sunLight.shadow.camera.right = this.params.radius;
    sunLight.shadow.camera.top = this.params.radius;
    sunLight.shadow.camera.bottom = -this.params.radius;
    sunLight.shadow.camera.near = 0.1;
    sunLight.shadow.camera.far = 10000;
    sunLight.shadow.radius = 0.3;
    sunLight.shadow.blurSamples = 25;
    sunLight.shadow.mapSize.set(1024 * 1, 1024 * 1);

    this.sunLight = sunLight;

    const groundMaterial = new THREE.MeshPhongMaterial({ color: 'gray' });
    const ground = new THREE.Mesh(
      new THREE.PlaneGeometry(2000, 2000, 8, 8),
      groundMaterial
    );
    ground.receiveShadow = true;
    ground.rotation.x = -Math.PI / 2;
    scene.add(ground);

    const sun = new THREE.Mesh(
      new THREE.SphereGeometry(50, 32, 16),
      new THREE.MeshBasicMaterial({ color: 'yellow' })
    );
    this.sphereLight.add(sun, sunLight);
    this.sunPathLight.add(this.sphereLight);

    scene.add(this.sunPathLight);

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    raycaster.setFromCamera(mouse, camera);

    window.addEventListener('resize', onWindowResize, false);
    document.addEventListener('mousemove', onMouseMove);

    animate();

    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

    function onWindowResize() {
      camera.aspect =
        (window.innerWidth * 0.975 - 25.7) / (window.innerHeight * 0.7);
      camera.updateProjectionMatrix();
      renderer.setSize(
        window.innerWidth * 0.975 - 25.7,
        window.innerHeight * 0.7
      );
      animate();
    }

    const clock = new THREE.Clock();

    const loopStart = () => {
      renderer.setAnimationLoop(() => {
        const delta = clock.getDelta();

        this.skyTick(this.sphereLight, sky, this.skyControl, renderer);
        this.sunTick(delta);
        renderer.render(scene, camera);
      });
    };
    function loopStop() {
      renderer.setAnimationLoop(null);
    }

    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.controls = controls;
    this.sky = sky;

    const cont = document.getElementById('scene-container') as HTMLElement;
    cont.appendChild(renderer.domElement);

    this.updateSunPosition();
    this.drawSunDayPath();
    // this.drawSunSurface()
    // this.drawAnalemmas()

    loopStart();

    const objectsIntersect = await this.create(scene);

    let selectElement: any = null;

    // Directions arrows
    let arrowVertices = [-200, 0, 0, 0, 0, 200, 0, 0, -200];
    const material = new THREE.MeshStandardMaterial({ color: 'red' });
    const arrowGeometry = new THREE.BufferGeometry();
    arrowGeometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(arrowVertices, 3)
    );
    const arrowN = new THREE.Mesh(arrowGeometry, material);
    arrowN.position.x = -this.params.radius + 700;

    scene.add(arrowN);

    function onMouseMove(event: any) {
      event.preventDefault();
      mouse.x = (event.clientX / (window.innerWidth - 30)) * 2 - 1;
      mouse.y = -(event.clientY / (window.innerHeight - 145)) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);

      if (selectElement) {
        selectElement.material.color.set('#555555');
        selectElement = null;
      }
      if (objectsIntersect.length) {
        const intersects = raycaster.intersectObjects(objectsIntersect, false);

        if (intersects.length > 0) {
          const res = intersects.filter(function (res) {
            return res && res.object;
          })[0];

          if (res && res.object) {
            selectElement = res.object;
            selectElement.material.color.set('#f0d459');
          }
        }
      }
    }
  }

  async create(scene: any) {
    console.warn = function () {};

    const objectsIntersect: any = [];

    const envelopes = this.exportBBL['envelopes']
      ? this.exportBBL['envelopes']
      : undefined;
    const builds = this.exportBBL['builds']
      ? this.exportBBL['builds']
      : undefined;

    const rhino3dm = await window.rh3dm();
    this.rhino = await rhino3dm();

    const loader = new Rhino3dmLoader();
    loader.setLibraryPath( './assets/js/rhino3dm/' )

    const file = await fetch('assets/zlvas.3dm');
    const buffer = await file.arrayBuffer();
    const arr2 = new Uint8Array(buffer);
    const doc = this.rhino.File3dm.fromByteArray(arr2);

    const objects = doc.objects();
    const cnt = objects.count;

    for (let i = 0; i < cnt; i++) {
      const object = objects.get(i).geometry();
      const xform = this.rhino.Transform.translationXYZ(0, -400, 0);
      object.transform(xform);
    }

    doc.settings().pageUnitSystem = this.rhino.UnitSystem.Feet;
    doc.settings().modelUnitSystem = this.rhino.UnitSystem.Feet;

    const main_layer_index = doc.layers().addLayer('Main', { r: 173, g: 173, b: 173, a: 255 });
    const main_layer = doc.layers().findIndex(main_layer_index);
    const main_layer_id = main_layer.id;

    const lot_layer_index = doc.layers().addLayer('Lot', { r: 255, g: 0, b: 0, a: 255 });
    const sep_layer_index = doc.layers().addLayer('Envelope - Sky Exposure Plane', {r: 255, g: 0, b: 0, a: 255});
    const envelope_layer_index = doc.layers().addLayer('Envelope', { r: 255, g: 0, b: 0, a: 255 });
    const yard_layer_index = doc.layers().addLayer('Yard', { r: 0, g: 188, b: 0, a: 255 });
    const tags_layer_index = doc.layers().addLayer('Tags', { r: 0, g: 0, b: 255, a: 255 });

    const grayMaterial = new this.rhino.Material();
    grayMaterial.transparency = 0.3;
    grayMaterial.toPhysicallyBased();
    grayMaterial.physicallyBased().baseColor = { r: 0.5, g: 0.5, b: 0.5, a: 1 };
    grayMaterial.physicallyBased().metallic = 0.2;
    grayMaterial.physicallyBased().roughness = 0;
    doc.materials().add(grayMaterial);

    const ext_layer_index = doc.layers().addLayer('Extrusions', { r: 173, g: 173, b: 173, a: 255 });
    const ext_layer = doc.layers().findIndex(ext_layer_index);
    ext_layer.parentLayerId = main_layer_id;
    ext_layer.renderMaterialIndex = 0;

    const oa_build = new this.rhino.ObjectAttributes();
    oa_build.materialSource = this.rhino.ObjectMaterialSource.MaterialFromLayer;
    oa_build.layerIndex = ext_layer_index;

    const oa_lot = new this.rhino.ObjectAttributes();
    oa_lot.layerIndex = lot_layer_index;

    const oa_sep = new this.rhino.ObjectAttributes();
    oa_sep.layerIndex = sep_layer_index;

    const oa_envelope = new this.rhino.ObjectAttributes();
    oa_envelope.layerIndex = envelope_layer_index;

    const oa_yard = new this.rhino.ObjectAttributes();
    oa_yard.layerIndex = yard_layer_index;

    const oa_tags = new this.rhino.ObjectAttributes();
    oa_tags.layerIndex = tags_layer_index;

    if (envelopes) {
      for (const envelope in envelopes) {
        const li = envelopes[envelope]['points'];
        for (let index = 0; index < li.length; index++) {
          const geometry = new THREE.SphereGeometry(2, 32, 32);
          geometry.translate(li[index][0][0], li[index][0][1], li[index][0][2]);

          const line = new this.rhino.LineCurve(
            [li[index][0][0], li[index][0][1], li[index][0][2]],
            [li[index][1][0], li[index][1][1], li[index][1][2]]
          );

          const mesh = new THREE.Mesh(
            geometry,
            new THREE.MeshBasicMaterial({ color: 0xffff00 })
          );
          mesh.rotateY(Math.PI / 2);
          mesh.rotateX(-Math.PI / 2);

          switch (li[index][2]) {
            case 'lot':
              doc.objects().add(line, oa_lot);
              break;
            case 'sep':
              if (li[index][0][2] > 0) scene.add(mesh);
              doc.objects().add(line, oa_sep);
              break;
            case 'envelope':
              if (li[index][0][2] > 0) scene.add(mesh);
              doc.objects().add(line, oa_envelope);
              break;
            case 'yard':
              doc.objects().add(line, oa_yard);
              break;
            case 'tags':
              break;
          }
          geometry.dispose();
          mesh.clear();
        }
      }
    }

    if (builds) {
      const createShape = (points: any) => {
        const shape = new THREE.Shape();
        shape.moveTo(points[1][0], points[1][1]);
        points.forEach((pt: any[]) => shape.lineTo(pt[0], pt[1]));
        return shape;
      };

      const curveShape = (points: any[]) => {
        const curvePoints = new this.rhino.Point3dList();
        points.forEach((pt: any[]) => curvePoints.add(pt[0], pt[1], 0));
        return this.rhino.NurbsCurve.create(false, 1, curvePoints);
      };

      const buildsMesh = new THREE.Group();
      scene.add(buildsMesh);

      const linesMesh = new THREE.Group();
      scene.add(linesMesh);

      for (const build in builds) {
        const polygon = builds[build];
        const extrudes: any = [];

        for (let index = 0; index < polygon.length; index++) {
          if (polygon[index][0][2] <= 0) continue;

          const poligonoShape = createShape(polygon[index]);
          const height = polygon[index][0][2] <= 0 ? 0.1 : polygon[index][0][2];

          const extrudeSettings = {
            steps: 1,
            depth: height,
            bevelEnabled: true,
            bevelSegments: 5,
          };

          const extrudeGeometry = new THREE.ExtrudeGeometry(
            poligonoShape,
            extrudeSettings
          );

          const geometry = BufferGeometryUtils.mergeVertices(
            extrudeGeometry,
            0.01
          );
          geometry.computeVertexNormals();

          extrudes.push(geometry);

          geometry.clearGroups();
          geometry.dispose();

          const curvePoints = curveShape(polygon[index]);

          const extrusion = this.rhino.Extrusion.create(
            curvePoints,
            -1 * height,
            true
          );

          doc.objects().add(extrusion, oa_build);
        }

        const material = new THREE.MeshStandardMaterial({
          color: '#555555',
          roughness: 0.5,
          metalness: 0.2,
          transparent: true,
          opacity: 0.6,
        });

        // const material = new THREE.ShadowMaterial({
        //     color: '#555555',
        //     opacity:0.6,
        //     transparent:true,
        // })

        const combinedGeometry = BufferGeometryUtils.mergeGeometries(extrudes);
        combinedGeometry.rotateZ(Math.PI / 2);
        combinedGeometry.rotateX(-Math.PI / 2);
        const mesh = new THREE.Mesh(combinedGeometry, material);

        scene.add(mesh);

        material.dispose();

        mesh.castShadow = true;
        mesh.receiveShadow = true;

        combinedGeometry.clearGroups();
        combinedGeometry.dispose();

        mesh.geometry.deleteAttribute('normal');
        mesh.geometry = BufferGeometryUtils.mergeVertices(mesh.geometry, 0.01);
        mesh.geometry.computeVertexNormals();

        //scene.add( mesh )

        const edges = new THREE.EdgesGeometry(mesh.geometry);
        const line = new THREE.LineSegments(
          edges,
          new THREE.LineBasicMaterial({ color: 0xffffff })
        );
        //scene.add( line )

        edges.clearGroups();
        edges.dispose();

        objectsIntersect.push(mesh);
        buildsMesh.add(mesh);
        linesMesh.add(line);
      }

      this.gui(buildsMesh, linesMesh);
    }

    let arr = new Uint8Array(doc.toByteArray()).buffer;

    this.doc = doc;

    loader.parse(
      arr,
      (object) => {
        this.hideLoader = true;
        const downloadButton = document.getElementById(
          'downloadButton'
        ) as HTMLButtonElement;
        // object.traverse((child:any) => {
        //     if (child.isPoints) {
        //       child.material.size = 10
        //     }
        //   },false)

        object.rotateY(Math.PI / 2);
        object.rotateX(-Math.PI / 2);

        scene.add(object);
        downloadButton.disabled = false;
      },
      (error) => {console.error }
    );
    return objectsIntersect;
  }

  gui(buildsMesh: any, linesMesh: any) {
    const buttons = {
      Center: function () {
        center();
      },
      Reset: function () {
        reset();
      },
    };

    function reset() {
      for (let i = 0; i < buildsMesh.children.length; i++) {
        buildsMesh.children[i].material.opacity = 0.6;
        buildsMesh.children[i].material.visible = true;
      }
      for (let i = 0; i < linesMesh.children.length; i++) {
        linesMesh.children[i].visible = true;
      }

      gui.reset();
    }

    const center = () => {
      this.controls.reset();
    };

    const gui = new GUI({ autoPlace: false, injectStyles: true });

    const buildingFolder = gui.addFolder('building');

    buildingFolder
      .add(this.params, 'Opacity', 0, 100)
      .onChange(function (value) {
        for (let i = 0; i < buildsMesh.children.length; i++) {
          buildsMesh.children[i].material.opacity = Number(value) / 100;
        }
      });

    buildingFolder.add(this.params, 'Buildings').onChange(function (value) {
      for (let i = 0; i < buildsMesh.children.length; i++) {
        buildsMesh.children[i].material.visible = Boolean(value);
      }
    });

    buildingFolder.add(this.params, 'Lines').onChange(function (value) {
      for (let i = 0; i < linesMesh.children.length; i++) {
        linesMesh.children[i].visible = Boolean(value);
      }
    });

    const timeFolder = gui.addFolder('Sun Path');
    timeFolder
      .add(this.params, 'minute', 0, 60, 1)
      .onChange(() => this.updateHour())
      .listen();
    timeFolder
      .add(this.params, 'hour', 0, 24, 1)
      .onChange(() => this.updateHour())
      .listen();
    timeFolder
      .add(this.params, 'day', 1, 30, 1)
      .onChange(() => this.updateMonth())
      .listen();
    timeFolder
      .add(this.params, 'month', 1, 12, 1)
      .onChange(() => this.updateMonth())
      .listen();
    timeFolder.add(this.params, 'animateTime');
    timeFolder.add(this.params, 'timeSpeed').min(0).max(10000).step(0.1);
    timeFolder.add(this.sky, 'visible').name('Sky');
    timeFolder.add(this.sunLight, 'castShadow').name('Sun shadows');
    timeFolder
      .add(this.sunPathLight.children[0].children[0], 'visible')
      .name('Sun Sphere');
    timeFolder
      .add(this.params, 'showSunDayPath')
      .name('Sun path')
      .onChange(() => this.updateMonth());

    timeFolder.add(buttons, 'Center');
    timeFolder.add(buttons, 'Reset');

    const guiContainer = document.getElementById(
      'gui-container'
    ) as HTMLAreaElement;

    guiContainer.appendChild(gui.domElement);

    // gui.close()
  }

  getSunPosition(date: any) {
    let sunPosition = getPosition(
      date,
      this.params.latitude,
      this.params.longitude
    );
    let x =
      this.params.radius *
      Math.cos(sunPosition.altitude) *
      Math.cos(sunPosition.azimuth);
    let z =
      this.params.radius *
      Math.cos(sunPosition.altitude) *
      Math.sin(sunPosition.azimuth);
    let y = this.params.radius * Math.sin(sunPosition.altitude);
    return { x, y, z };
  }

  updateHour() {
    this.date = new Date(this.date).setHours(this.params.hour);
    this.date = new Date(this.date).setMinutes(this.params.minute);
    this.updateSunPosition();
    this.drawSunDayPath();
  }

  updateMonth() {
    this.date = new Date(this.date).setHours(this.params.hour);
    this.date = new Date(this.date).setDate(this.params.day);
    this.date = new Date(this.date).setMonth(this.params.month - 1);
    this.updateSunPosition();
    this.drawSunDayPath();
  }

  updateSunPosition() {
    let sunPosition = this.getSunPosition(this.date);
    this.sphereLight.position.set(sunPosition.x, sunPosition.y, sunPosition.z);
    this.sunLight.lookAt(0, 0, 0);
  }

  drawSunDayPath() {
    if (this.params.showSunDayPath) {
      let dayPath: any = this.sunPathLight.getObjectByName('dayPath');
      this.sunPathLight.remove(dayPath);
      let pathMaterial = new THREE.LineDashedMaterial({
        color: 'red',
        linewidth: 1,
        scale: 1,
        dashSize: 50,
        gapSize: 30,
        transparent: true,
        opacity: 0.5,
      });
      let geometry = new THREE.BufferGeometry();
      let positions = [];
      for (let h = 0; h < 24; h++) {
        let date = new Date(this.date).setHours(h);
        let sunPosition = this.getSunPosition(date);
        positions.push(sunPosition.x, sunPosition.y, sunPosition.z);
      }
      geometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(positions, 3)
      );
      let path = new THREE.LineLoop(geometry, pathMaterial);
      path.computeLineDistances();
      path.name = 'dayPath';
      this.sunPathLight.add(path);
    } else {
      let dayPath: any = this.sunPathLight.getObjectByName('dayPath');
      this.sunPathLight.remove(dayPath);
    }
  }

  drawSunSurface() {
    if (this.params.showSunSurface) {
      let sunSurface: any = this.sunPathLight.getObjectByName('sunSurface');
      this.sunPathLight.remove(sunSurface);
      let vertices = [];
      for (let m = 0; m < 6; m++) {
        let date: any = new Date();
        for (let h = 0; h < 24; h++) {
          date = new Date(date).setMonth(m);
          date = new Date(date).setHours(h);
          let sunPosition = this.getSunPosition(date);
          vertices.push(sunPosition.x, sunPosition.y, sunPosition.z);
          date = new Date(date).setHours(h + 1);
          let sunPosition2 = this.getSunPosition(date);
          vertices.push(sunPosition2.x, sunPosition2.y, sunPosition2.z);
          date = new Date(date).setMonth(m + 1);
          date = new Date(date).setHours(h);
          let sunPosition3 = this.getSunPosition(date);
          vertices.push(sunPosition3.x, sunPosition3.y, sunPosition3.z);
          vertices.push(sunPosition3.x, sunPosition3.y, sunPosition3.z);
          vertices.push(sunPosition2.x, sunPosition2.y, sunPosition2.z);
          date = new Date(date).setHours(h + 1);
          let sunPosition4 = this.getSunPosition(date);
          vertices.push(sunPosition4.x, sunPosition4.y, sunPosition4.z);
        }
      }
      let surfaceGeometry = new THREE.BufferGeometry();
      let surfaceMaterial = new THREE.MeshStandardMaterial({
        color: 'yellow',
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.1,
      });
      surfaceGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(vertices, 3)
      );
      let surfaceMesh = new THREE.Mesh(surfaceGeometry, surfaceMaterial);
      surfaceMesh.name = 'sunSurface';
      this.sunPathLight.add(surfaceMesh);
    } else {
      let sunSurface: any = this.sunPathLight.getObjectByName('sunSurface');
      this.sunPathLight.remove(sunSurface);
    }
  }

  drawAnalemmas() {
    if (this.params.showAnalemmas) {
      let analemmaPath: any = this.sunPathLight.getObjectByName('analemmaPath');
      this.sunPathLight.remove(analemmaPath);
      let analemmas = new THREE.Group();
      for (let h = 7; h < 18; h++) {
        let vertices = [];
        let from = new Date(2022, 0, 1);
        let to = new Date(2023, 0, 1);
        for (let d = from; d < to; d.setDate(d.getDate() + 1)) {
          let date = new Date(d).setHours(h);
          let sunPosition = this.getSunPosition(date);
          vertices.push(sunPosition.x, sunPosition.y, sunPosition.z);
        }
        let geometry = new THREE.BufferGeometry();
        let analemmaMaterial = new THREE.LineDashedMaterial({
          color: 'yellow',
          linewidth: 1,
          scale: 10,
          dashSize: 6,
          gapSize: 3,
          transparent: true,
          opacity: 0.7,
        });
        geometry.setAttribute(
          'position',
          new THREE.Float32BufferAttribute(vertices, 3)
        );
        let analemma = new THREE.LineLoop(geometry, analemmaMaterial);
        analemma.computeLineDistances();
        analemmas.add(analemma);
        analemmas.name = 'analemmaPath';
      }
      this.sunPathLight.add(analemmas);
    } else {
      let analemmaPath: any = this.sunPathLight.getObjectByName('analemmaPath');
      this.sunPathLight.remove(analemmaPath);
    }
  }

  skyTick(sphereLight: any, sky: any, skyControl: any, renderer: any) {
    if (this.sunPathLight.visible) {
      let sunPosition = new THREE.Vector3().setFromMatrixPosition(
        sphereLight.matrixWorld
      );
      if (sunPosition.y < 0) {
        sphereLight.children[1].visible = false;
      } else {
        sphereLight.children[1].visible = true;
      }
      const uniforms = sky.material.uniforms;
      uniforms['turbidity'].value = skyControl.turbidity;
      uniforms['rayleigh'].value = skyControl.rayleigh;
      uniforms['mieCoefficient'].value = skyControl.mieCoefficient;
      uniforms['mieDirectionalG'].value = skyControl.mieDirectionalG;
      uniforms['sunPosition'].value.copy(sunPosition);
      renderer.toneMappingExposure = skyControl.exposure;
    }
  }

  sunTick(delta: any) {
    if (this.params.animateTime) {
      let time = new Date(this.date).getTime();
      this.date = new Date(this.date).setTime(
        time + delta * 1000 * this.params.timeSpeed
      );
      this.params.minute = new Date(this.date).getMinutes();
      this.params.hour = new Date(this.date).getHours();
      this.params.day = new Date(this.date).getDate();
      this.params.month = new Date(this.date).getMonth();
      this.updateSunPosition();
      this.drawSunDayPath();
      //this.drawSunSurface()
    }
  }

  download() {
    let buffer = this.doc.toByteArray();
    this.saveByteArray(`${this.filename}.${this.extension}`, buffer);
  }

  saveByteArray(fileName: any, byte: any) {
    let blob = new Blob([byte], { type: 'application/octect-stream' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  closeModal() {
    this.activeModal.close();
  }

  center() {
    this.controls.reset();
  }
}
