import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GetLotService {
  /**
   * Subject dark mode
   */
  private getLotSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  getLotData = this.getLotSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that gets lots according bbl number
   */
  getLot(bbl: number,position:any): void {
    const data: number = bbl;
    this.getLotSubject.next([data,position])
  }
}
