import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectTO } from './select.to';
import { FormGroup, FormBuilder } from '@angular/forms';

/**
 * Componente que contiene el elemento Select en el formulario.
 */
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  /**
   * formgroup
   */
  form!: FormGroup;
  /**
   * Var that content the title of the select
   */
  selectTitle: any;
  /**
   * List that contains the original data.
   */
  originalData!: any[];

  /**
   * Config params of the element
   */
  @Input()
  params!: SelectTO;
  /**
   * Output tht handles the item selected
   */
  @Output() itemSelected = new EventEmitter<string>();
  /**
   * Output that handles when the element is expanded.
   */
  @Output() expandElement = new EventEmitter<boolean>();

  /**
   * Inicializador.
   */
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      search: ['', []],
    });
    if (this.params.elementList) {
      this.originalData = this.params?.elementList;
    }
  }

  /**
   * Constructor
   * @param formBuilder from buildes handler
   */
  constructor(private formBuilder: FormBuilder) {
    this.selectTitle = this.params?.title;
  }

  /**
   * Methid that handle the element selected
   * @param index index value
   */
  handleElement(index: any) {
    if (this.params.elementList && this.params.elementList.length > 0) {
      this.selectTitle = this.params.elementList[index].name as string;
      this.itemSelected.emit(this.selectTitle);
    }
  }

  /**
   * Method that toggle the expanded element.
   */
  expandSelect() {
    this.params.selected = !this.params.selected;
    this.expandElement.emit(this.params.selected);
  }

  /**
   * Method that find and select the value con the select list.
   * @param event value
   */
  findDataOnList(event: any) {
    const searchWord = event.target.value;
    const newFilter = Object.keys(this.params.elementList).reduce(
      (result: any, key: any) => {
        if (
          this.params.elementList[key].name
            .toLowerCase()
            ?.match(searchWord.toLowerCase())
        ) {
          result.push(this.params.elementList[key]);
        }

        return result;
      },

      []
    );

    this.params!.elementList = newFilter;
  }

  /**
   * Method that handles the keydown function
   * @param event value
   */
  handleKeyDown(event: any) {
    if (event.key === 'Backspace') {
      this.params.elementList = this.originalData;
    }
  }
}
