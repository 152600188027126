<!-- <style>
    .geocoder-dropdown-item {
    padding: 5px;
  }
  .geocoder-dropdown-icon {
    margin-right: 10px;
  }
  .geocoder-dropdown-text {
    color: #5a3fc0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: larger;
  }

    .mapboxgl-ctrl-geocoder {
        min-width: 100%;
    }
</style> -->

<style>
  ::ng-deep .modal-viewexport .modal-dialog {
    max-width: 100%;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
  }
</style>

<app-snackbar [params]="paramsSnack"></app-snackbar>

<div *ngIf="loading" class="icon_loading">
  <img
    class="icon_loading-img"
    src="assets/images/cube_loop.gif"
    alt="loading"
  />
</div>

<div
  id="map-popup"
  style="
    z-index: 2000;
    position: absolute;
    background: white;
    font-size: 11px;
    padding: 6px;
    border: 1px solid black;
  "
></div>

<ul
  style="
    position: absolute;
    z-index: 1000;
    text-align: right;
    font-size: 14px;
    list-style: none;
    margin-left: 75%;
  "
></ul>

<article class="btn_cnt">
  <figure class="option" (click)="showStreetviewMode()">
    <img
      class="img"
      src="assets/images/streetviewOFF.png"
      [ngClass]="{ 'img--visible': !showStreetview }"
      alt=""
    />
    <img
      class="img"
      src="assets/images/streetviewON.png"
      alt=""
      [ngClass]="{ 'img--visible': showStreetview }"
    />
  </figure>

  <figure class="option" (click)="showSateliteMode()">
    <img
      class="img"
      src="assets/images/satelital.png"
      [ngClass]="{ 'img--visible': !sateliteMode }"
      alt=""
    />
    <img
      class="img"
      src="assets/images/map.png"
      alt=""
      [ngClass]="{ 'img--visible': sateliteMode }"
    />
  </figure>

  <figure class="option" (click)="showBuildings()">
    <img
      class="img"
      src="assets/images/no-building.png"
      [ngClass]="{ 'img--visible': showBuilding }"
      alt=""
    />
    <img
      class="img"
      src="assets/images/3d-building.png"
      [ngClass]="{ 'img--visible': !showBuilding }"
      alt=""
    />
    <div class="opacitySlideController" (click)="onClick($event)" >
      <div class="triangle"></div>
      <div>
        <p class="text">Set Buildings Opacity</p>
      </div>
      <div class="d-flex aling-items-center position-relative">
        <div class="slider">
          <ngx-slider
            [(value)]="opacityMinValue"
            [options]="opacitySliderOptions"
            (valueChange)="handleSliderOpacity(opacityMinValue)"
          ></ngx-slider>
        </div>
        <div class="slider_value_input">
          <input
            id="inputOpacity"
            class="input_opacity"
            type="text"
            (change)="handleKeyDown($event)"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            [disabled]="!showBuilding"
            [value]="opacityMinValue"
          />
          <p
            class="percent"
            (click)="onClick($event)"
            [style.color]="!showBuilding ? '#474242' : '#F0D459'"
            [ngClass]="{
              'percent--mid': opacityMinValue >= 10,
              'percent--higher': opacityMinValue === 100
            }"
          >
            %
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          class="btn_reset btn_reset--extrude"
          [disabled]="!showBuilding || !enableExtrudeBtn"
          (click)="set3dEnvelope()"
        >
          Extrude Assembled
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button
          class="btn_reset"
          (click)="resetSlideValue()"
          [disabled]="!showBuilding"
        >
          Reset Buildings
          <span
            class="icon-reset icon"
            [ngClass]="{ 'icon--disabled': !showBuilding }"
          ></span>
        </button>
      </div>
    </div>
  </figure>

  <figure
    class="option"
    [ngClass]="{ 'option--disabled': disabledOptionMap }"
    (click)="setShow2d()"
  >
    <img
      class="img"
      [ngClass]="{ 'img--visible': show2d, 'img--disabled': disabledOptionMap }"
      src="assets/images/3d.png"
      alt=""
    />
    <img
      class="img"
      [ngClass]="{
        'img--visible': !show2d,
        'img--disabled': disabledOptionMap
      }"
      src="assets/images/2d.png"
      alt=""
    />
  </figure>
</article>



<ul *ngIf="testPanel" id ="assemble" style="
position: absolute;
z-index: 1000;
text-align: left;
font-size: 14px;
list-style: none;
margin-left: 70%;
background-color:black;
width: 290px;
">>
    <li *ngFor="let bbl of this.useBulkAvailable">
        <label style="color: white;">{{ bbl.BBL }} | {{ bbl.ZONEDIST }} | {{ bbl.OVERLAY }}</label> <br>
        
        <ul class="subgroup" id="{{ bbl.INDEX }}">
          <input type="text" name="bbl" style="display:none" value="{{ bbl.BBL }}"> 
          <input type="text" name="zonedist" style="display:none" value="{{ bbl.ZONEDIST }}"> 
          <input type="text" name="overlay" style="display:none" value="{{ bbl.OVERLAY }}"> 
          <li>
              <input  [disabled] = "(bbl.USES.R | json) == '[]'" type="checkbox" name="use" [value]="'R'" (change)="getBulks()">
              <label  style="padding: 0px 5px;color: white;"> R </label>

              <input  [disabled] = "(bbl.USES.CF | json) == '[]'" type="checkbox" name="use" [value]="'CF'" (change)="getBulks()">
              <label  style="padding: 0px 5px;color: white;"> CF </label>

              <input  [disabled] = "(bbl.USES.C | json) == '[]'" type="checkbox" name="use" [value]="'C'" (change)="getBulks()">
              <label  style="padding: 0px 5px;color: white;"> C </label>

              <input  [disabled] = "(bbl.USES.M | json) == '[]'" type="checkbox" name="use" [value]="'M'" (change)="getBulks()">
              <label  style="padding: 0px 5px;color: white;"> M </label>
          </li>

          <!-- <li>
              <input  type="checkbox" name="rMinus25" [value]=true>
              <label  style="padding: 0px 5px;color: white;"> R < 25 </label>
          </li> -->


          <li>
              <input *ngIf="bbl.R25" [disabled] = "!bbl.R_BAR" type="radio" name="rMinus25_{{ bbl.INDEX }}" [value]=25 (change)="getBulks()">
              <label *ngIf="bbl.R25" style="padding: 0px 5px;color: white;" >25% or less</label>

              <input *ngIf="bbl.R25 && bbl.R65" [disabled] = "!bbl.R_BAR" type="radio" name="rMinus25_{{ bbl.INDEX }}" [value]=45 (change)="getBulks()">
              <label *ngIf="bbl.R25 && bbl.R65" style="padding: 0px 5px;color: white;">between</label>

              <input *ngIf="bbl.R65" [disabled] = "!bbl.R_BAR" type="radio" name="rMinus25_{{ bbl.INDEX }}" [value]=65 (change)="getBulks()" checked>
              <label *ngIf="bbl.R65" style="padding: 0px 5px;color: white;">65% or more</label>

              <input *ngIf="bbl.R25 && !bbl.R65" [disabled] = "!bbl.R_BAR" type="radio" name="rMinus25_{{ bbl.INDEX }}" [value]=80 (change)="getBulks()" checked>
              <label *ngIf="bbl.R25 && !bbl.R65" style="padding: 0px 5px;color: white;">More than 25%</label>
          </li>
          
          <li>-----------</li>

          <input *ngIf="bbl.BULKS.QH" [disabled] = "bbl.BULKS_ACTIVE.QH" type="radio" name="bulk_{{ bbl.INDEX }}" [value]="'QH'" (change)="getYards()">
          <label *ngIf="bbl.BULKS.QH" style="padding: 0px 5px;color: white;">QH</label>
          
          <span *ngIf="bbl.BULKS.QH">|</span>

          <input *ngIf="bbl.BULKS.QGF" [disabled] = "bbl.BULKS_ACTIVE.QGF" type="radio" name="bulk_{{ bbl.INDEX }}" [value]="'QGF'" (change)="getYards()">
          <label *ngIf="bbl.BULKS.QGF" style="padding: 0px 5px;color: white;">QGF</label>
          
          <span *ngIf="bbl.BULKS.QGF">|</span>

          <input *ngIf="bbl.BULKS.BH" [disabled] = "bbl.BULKS_ACTIVE.BH" type="radio" name="bulk_{{ bbl.INDEX }}" [value]="'BH'" (change)="getYards()">
          <label *ngIf="bbl.BULKS.BH" style="padding: 0px 5px;color: white;">BH</label>
          <br>
          <input *ngIf="bbl.BULKS.AH" [disabled] = "bbl.BULKS_ACTIVE.AH" type="radio" name="bulk_{{ bbl.INDEX }}" [value]="'AH'" (change)="getYards()">
          <label *ngIf="bbl.BULKS.AH" style="padding: 0px 5px;color: white;">AH</label>

          <span *ngIf="bbl.BULKS.AH">|</span>

          <input *ngIf="bbl.BULKS.TB" [disabled] = "bbl.BULKS_ACTIVE.TB" type="radio" name="bulk_{{ bbl.INDEX }}" [value]="'TB'" (change)="getYards()">
          <label *ngIf="bbl.BULKS.TB" style="padding: 0px 5px;color: white;">TB</label>
          
          <span *ngIf="bbl.BULKS.TB">|</span>

          <input *ngIf="bbl.BULKS.ST_BH" [disabled] = "bbl.BULKS_ACTIVE.ST_BH" type="radio" name="bulk_{{ bbl.INDEX }}" [value]="'ST_BH'" (change)="getYards()">
          <label *ngIf="bbl.BULKS.ST_BH" style="padding: 0px 5px;color: white;">ST_BH</label>
          <br>
          <input *ngIf="bbl.BULKS.ST_AH" [disabled] = "bbl.BULKS_ACTIVE.ST_AH" type="radio" name="bulk_{{ bbl.INDEX }}" [value]="'ST_AH'" (change)="getYards()">
          <label *ngIf="bbl.BULKS.ST_AH" style="padding: 0px 5px;color: white;">ST_AH</label>
          
          <span *ngIf="bbl.BULKS.ST_AH">|</span>

          <input *ngIf="bbl.BULKS.SD" [disabled] = "bbl.BULKS_ACTIVE.SD" type="radio" name="bulk_{{ bbl.INDEX }}" [value]="'SD'" (change)="getYards()">
          <label *ngIf="bbl.BULKS.SD" style="padding: 0px 5px;color: white;">SD</label>

          
          <li>-----------</li>
          <!-- <span style="color: white;">RYE_HF</span>
          <br>
          <select [(ngModel)]="selectedRYE_HF">
              <option [value]=""></option>
              <option *ngFor="let item of this.RYE_HF" [value]="item">{{ item }}</option>
          </select>

          <br>
          <span style="color: white;">RYE_QH</span>
          <br>
          <select [(ngModel)]="selectedRYE_QH">
              <option [value]=""></option>
              <option *ngFor="let item of this.RYE_QH" [value]="item">{{ item }}</option>
          </select> -->

          <li>-----------</li>

      </ul> 
    </li>

    <li class="subgroup2" *ngIf="useBulkAvailable.length">
      <input *ngIf="useBulkAvailable[0].YARDS.A" [disabled] = "useBulkAvailable[0].YARDS_ACTIVE.A" type="radio" name="yard" [value]="'A'">
      <label *ngIf="useBulkAvailable[0].YARDS.A" style="padding: 0px 5px;color: white;">A</label>
          
      <span *ngIf="useBulkAvailable[0].YARDS.A" >|</span> 

      <input *ngIf="useBulkAvailable[0].YARDS.B_1" [disabled] = "useBulkAvailable[0].YARDS_ACTIVE.B_1" type="radio" name="yard" [value]="'B_1'">
      <label *ngIf="useBulkAvailable[0].YARDS.B_1" style="padding: 0px 5px;color: white;">B_1</label>
          
      <span *ngIf="useBulkAvailable[0].YARDS.B_1" >|</span> 

      <input *ngIf="useBulkAvailable[0].YARDS.B_2" [disabled] = "useBulkAvailable[0].YARDS_ACTIVE.B_2" type="radio" name="yard" [value]="'B_2'">
      <label *ngIf="useBulkAvailable[0].YARDS.B_2" style="padding: 0px 5px;color: white;">B_2</label>
          
      <span *ngIf="useBulkAvailable[0].YARDS.B_2" >|</span> 

      <input *ngIf="useBulkAvailable[0].YARDS.B_3" [disabled] = "useBulkAvailable[0].YARDS_ACTIVE.B_3" type="radio" name="yard" [value]="'B_3'">
      <label *ngIf="useBulkAvailable[0].YARDS.B_3" style="padding: 0px 5px;color: white;">B_3</label>
          
      <span *ngIf="useBulkAvailable[0].YARDS.B_3" >|</span> 

      <input *ngIf="useBulkAvailable[0].YARDS.C" [disabled] = "useBulkAvailable[0].YARDS_ACTIVE.C" type="radio" name="yard" [value]="'C'">
      <label *ngIf="useBulkAvailable[0].YARDS.C" style="padding: 0px 5px;color: white;">C</label>
          
      <span *ngIf="useBulkAvailable[0].YARDS.C" >|</span> 
    </li>
    <button (click)="getAssembler()">getAssemble</button>
    <button (click)="exportPDF()">exportPDF</button>
    <button (click)="imageMap()">mapPDF</button>
    <li><button (click)="getPdfFeature()">PDF feature</button></li>
    <li><button (click)="openStreetView()">ON StreetView</button></li>
    <li><button (click)="closeStreetView()">OFF StreetView</button></li>
    <li><button (click)="openUseTable()">ON USETable</button></li>    
</ul>

<!-- Componente para exportar PDF-->
<app-pdf-report></app-pdf-report>   
<div id="map" class="map"></div>
