import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DarkModeConfig } from 'src/app/models';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import { SwitchTO } from 'src/app/ui/switch/switch.to';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  /**
   * form group
   */
  form!: FormGroup;
  /**
   * Configuration of switch theme
   */
  switchTheme = {
    id: 'switchTheme',
    enabled: false,
    innerValue: true,
    label: 'Dark mode',
    leftLabel: 'Light mode',
  } as SwitchTO;

  /**
   * Flag that controls the theme mode
   */
  themeMode!: any;

  /**
   * Subscription
   */
  subscription!: Subscription;

  /**
   * dark mode config
   */
  darkModeConfig!: DarkModeConfig;

  /**
   * Constructor of the component
   * @param formBuilder formbuilder
   * @param themeService theme service config
   * @param loadingService loading service config
   */
  constructor(
    private formBuilder: FormBuilder,
    private themeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      switchTheme: [],
    });
    this.switchTheme.form = this.form;
    this.themeMode = sessionStorage.getItem('theme');
    this.themeService.setThemeMode();

    this.subscription = this.themeService.darkModeData.subscribe(
      (data: DarkModeConfig) => {
        this.darkModeConfig = data;
        if (this.darkModeConfig.activeTheme === 'dark') {
          this.form.controls['switchTheme'].setValue(true);
        } else {
          this.form.controls['switchTheme'].setValue(false);
        }
      }
    );
  }

  /**
   * Method that handles the selection of switch borough
   * Metodo que maneja la seleccion de switch borough
   * @param $event boolean
   */
  switchThemeToggle($event: any) {
    let theme = 'light';
    this.switchTheme.enabled = $event;
    if (this.switchTheme.enabled) {
      theme = 'dark';
      sessionStorage.setItem('theme', theme);
      this.themeService.setThemeMode();
    } else {
      theme = 'light';
      sessionStorage.setItem('theme', theme);
      this.themeService.setThemeMode();
    }
  }
}
