import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputTypes } from './input-types.enum';
import { inputMessage } from './input.message';
import { InputTO } from './input.to';

/**
 * Component that contains input of the form.
 */
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  /**
   * Config params of the elem.
   */
  @Input() params!: InputTO;

  /**
   * Output that informs about the icon info.
   */
  @Output()
  infoCallback = new EventEmitter<any>();

  /**
   * Output that informs that the form must be sent.
   */
  @Output()
  submitCallback = new EventEmitter<any>();

  /**
   * Output that triggers on write
   */
  @Output()
  keyInputCallback = new EventEmitter<any>();

  /**
   * Output that triggers on blur event
   */
  @Output()
  blurCallback = new EventEmitter<boolean>();

  /**
   * Element that belongs to the input
   */
  element!: AbstractControl;

  /**
   * Temportal subscription to obtain input type.
   */
  subscription!: Subscription;

  /**
   * Entry labels
   */
  entryLabel: any;

  /**
   * Input type element.
   */
  type!: string;

  /**
   * Flag that controls if the pass must be showed.
   */
  showPassword = false;

  /**
   * Flag that indicates if inputs is focused.
   */
  isFocus = false;

  /**
   * Regular expression that replaces values not allowed for serial type.
   */
  private patternReplaceSerial = /[b-zB-Z.\-@]+/g;

  /**
   * Regular expression for values not allowed for type text.
   */
  private patternReplaceText = /[^a-zA-ZÀ-ÿ\s\u00f1\u00d1]+/g;

  /**
   * Regular expression that replaces not allowed values of type number.
   */
  private patternReplaceNumber = /[^0-9]+/g;

  /**
   * Regular expression to replace not allowed values of type number unit.
   * Expresion regular para remplazar valores no permitidos del tipo NUMBER_UNIT.
   */
  private patternReplaceAlphanumeric = /[^0-9a-zA-ZÀ-ÿ\u00f1\u00d1., -]+/;

  /**
   * Regular expression to replaces not allowed values of type alphanumeric.
   */
  private patternReplaceNumberUnit = /[^0-9a-zA-Z]+/;

  /**
   * Regular expression that replaces not allowe values of type Percent.
   */
  private patternReplacePercentCommaDot = /[,]+/;

  /**
   * Regular expression that replaces not allowed values of type percent.
   */
  private patternReplacePercentNumbers = /[^.\d]/g;

  /**
   * Regular expression that handles to replace not allowed values of type percent.
   */
  private patternReplacePercentDecimals = /^(\d*\.?)|(\d*)\.?/g;

  /**
   * Regular expressiont that replaces not allowed values of types percent
   */
  private patternReplacePercentDotComma = /[.]+/;

  /**
   * Constante empty value.
   */
  private empty = '' as const;

  /**
   * constant dot value.
   */
  private dot = '.' as const;

  /**
   * constant comma value.
   */
  private comma = ',' as const;

  /**
   * constant of type text.
   */
  private typeText = 'text' as const;

  /**
   * Constante value to replace decimals.
   */
  private replaceDecimals = '$1$2' as const;

  /**
   * constant for pipe symbol.
   */
  private symbol = 'symbol' as const;

  /**
   * constant for pipe symbol.
   */
  private decimalFormatCurrency = '1.0-0' as const;

  /**
   * Final symbol to type CURRENCY.
   */
  private currencyEndSymbol = '.-' as const;

  /**
   * focus map key of the input
   */
  public focusLabels!: Map<string, boolean>;

  /**
   * Constructor.
   *
   * @param currencyPipe money pipe
   */
  constructor() {}

  /**
   * Component initializer
   */
  ngOnInit(): void {
    this.initInputFocusLabels();
    if (!this.params.validators) {
      this.params.validators = [];
    }
    if (!this.params.form.controls[this.params.id]) {
      this.params.form.addControl(
        this.params.id,
        new FormControl(null, this.params.validators)
      );
    } else {
      this.params.form.controls[this.params.id].addValidators(
        this.params.validators
      );
    }
    this.element = this.params.form.controls[this.params.id];
    if (!this.params.validators) {
      this.params.validators = [];
    }
    if (this.element.value || parseInt(this.element.value) === 0) {
      this.focusLabels.set(this.params.id, true);
    }
    // Se define tipo de input que sera el elemento html.
    switch (this.params.type) {
      case InputTypes.PASSWORD:
        this.type = InputTypes.PASSWORD;
        break;
      case InputTypes.NUMBERS:
        this.type = this.typeText;
        break;
      default:
        this.type = this.typeText;
        break;
    }
    this.element.updateValueAndValidity();
    if (!this.params.placeholder) {
      this.params.placeholder = '';
    }
    if (!this.params.decimalSymbol) {
      this.params.decimalSymbol = this.comma;
    }

    this.pasiveObserver();
  }

  /**
   * Method that inits the focus labels
   */
  initInputFocusLabels() {
    this.focusLabels = new Map<string, boolean>();
    this.focusLabels.set(this.params.id, false);
  }

  /**
   * Method that triggers after blur event
   */
  blur(event: any, inputName: any): void {
    this.isFocus = false;
    this.transicionLabelBlur(event, inputName);
    // Se desuscribe el campo ya que se perdio el foco y no se modificara.
    this.subscription.unsubscribe();
    if (!this.element.value) {
      this.element.markAsUntouched();
    }
    if (
      this.params.type === InputTypes.NUMBERS &&
      this.element.valid &&
      this.element.value === '-'
    ) {
      this.element.setValue(null);
    }
    if (this.params.type === InputTypes.PERCENT && this.element.valid) {
      // Si el input es del tipo PERCENT se limpia.
      if (
        String(this.element.value).includes(this.params.decimalSymbol) &&
        this.element.value.split(this.params.decimalSymbol)[1].length <
          this.params.decimalLength
      ) {
        let value = this.element.value;
        if (this.params.decimalSymbol !== this.dot) {
          value = value.replace(this.params.decimalSymbol, this.dot);
        }
        this.element.setValue(
          parseFloat(value).toFixed(this.params.decimalLength)
        );
      }
      if (
        String(this.element.value).indexOf(this.params.decimalSymbol) ===
        String(this.element.value).length - 1
      ) {
        this.element.setValue(
          this.element.value.substring(0, this.element.value.length - 1)
        );
      }
    }
    if (!!this.params.handleBlueCallback) {
      this.blurCallback.emit(true);
    }
    // Se realiza un trim del valor final para evitar ingreso de espacios innesesarios.
    this.element.setValue(
      this.element.value ? String(this.element.value).trim() : null
    );
    this.pasiveObserver();
  }

  /**
   * Method that validates if can be done the onblur transition of the input.
   * Metodo que valida si se puede hacer la transicion de onblur del input
   */
  transicionLabelBlur(event: any, inputName: any) {
    if (event.target.value === '') {
      this.focusLabels.set(inputName, false);
    } else {
      this.focusLabels.set(inputName, true);
    }
  }

  /**
   * Method that init after doing the focus of the input and delete the errors
   */
  focus(event: any, inputName: any): void {
    if (!this.params.readOnly) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.isFocus = true;
      this.transicionLabelFoco(event, inputName);
      this.element.markAsUntouched();
      if (this.params.type === InputTypes.SERIAL && this.element.value) {
        this.element.setValue(
          this.element.value.replace(this.patternReplaceSerial, this.empty)
        );
      }
      this.observer();
      if (this.params.autoSelectFocus) {
        event.target.select();
      }
    }
  }

  /**
   * Method that validates if is allowed the transition onfocus of the input.
   */
  transicionLabelFoco(event: any, inputName: any) {
    if (!!event) {
      this.focusLabels.set(inputName, true);
    }
  }

  /**
   * Method that triggrs after pressing a keyup inside the input and do a cleaning of not allowed characters.
   */
  observer(): void {
    this.subscription = this.element.valueChanges.subscribe((value) => {
      let negative;
      let newValue = null;
      // Segun el tipo de input se ejecuta la limpieza especifica.
      switch (this.params.type) {
        case InputTypes.ONLY_TEXT:
          newValue = value.replace(this.patternReplaceText, this.empty);
          break;

        case InputTypes.NUMBERS:
          negative = false;
          if (this.params.supportNegativeNumber && value.indexOf('-') === 0) {
            negative = true;
          }
          newValue = value.replace(this.patternReplaceNumber, this.empty);
          if (negative) {
            newValue = '-' + newValue;
          }
          break;

        case InputTypes.ALPHANUMERIC:
          newValue = value.replace(this.patternReplaceAlphanumeric, this.empty);
          break;

        case InputTypes.NUMBER_UNIT:
          newValue = value.replace(this.patternReplaceNumberUnit, this.empty);
          break;

        case InputTypes.PERCENT:
          negative = false;
          if (this.params.supportNegativeNumber && value.indexOf('-') === 0) {
            negative = true;
          }
          newValue = value.replace(
            this.patternReplacePercentCommaDot,
            this.dot
          );
          newValue = newValue
            .replace(this.patternReplacePercentNumbers, this.empty)
            .replace(this.patternReplacePercentDecimals, this.replaceDecimals);
          if (
            newValue.includes(this.dot) &&
            newValue.split(this.dot)[1].length > this.params.decimalLength
          ) {
            newValue = parseFloat(newValue);
            newValue = newValue.toFixed(this.params.decimalLength) + '';
          }
          if (!this.params.decimalSymbol) {
            newValue = newValue.replace(
              this.patternReplacePercentDotComma,
              this.comma
            );
          } else {
            newValue = newValue.replace(
              this.patternReplacePercentDotComma,
              this.params.decimalSymbol
            );
          }
          if (negative) {
            newValue = '-' + newValue;
          }

          break;

        case InputTypes.CURRENCY:
          newValue = value.replace(this.patternReplaceNumber, this.empty);
          break;
      }
      if (this.params.upperCase) {
        if (!newValue) {
          newValue = value;
        }
        newValue = newValue.toUpperCase();
      }
      if (this.params.forceLowerCase) {
        if (!newValue) {
          newValue = value;
        }
        newValue = newValue.toLowerCase();
      }
      // Si el valor fue alterado por la limpieza se ingresa para actualizarlo en el formulario.
      if (newValue !== null && value !== newValue) {
        this.element.setValue(newValue, { emitEvent: false });
      }
    });
  }

  /**
   * Method that triggers after press any key inside the input and do a clean of not allowed characters.
   */
  pasiveObserver(): void {
    this.subscription = this.element.valueChanges.subscribe((value) => {
      if (value && value !== '') {
        this.focusLabels.set(this.params.id, true);
      } else {
        this.focusLabels.set(this.params.id, false);
      }
    });
  }

  /**
   * Method that go forward to the next element on the from after press enter event.
   */
  keytab(): void {
    let next = false;
    let nextId = '';
    Object.keys(this.params.form.controls).forEach((item) => {
      if (!next && item === this.params.id) {
        next = true;
      } else if (next && !nextId && this.params.form.controls[item].enabled) {
        nextId = item;
      }
    });

    if (nextId) {
      let element = document.getElementById('input-' + nextId);
      if (element) {
        element.focus();
      } else {
        element = document.getElementById('select-' + nextId);
        if (element) {
          element.focus();
        } else {
          if (this.params.form.valid) {
            this.submitCallback.emit(true);
          }
        }
      }
    } else {
      this.submitCallback.emit(true);
    }
  }

  /**
   * Method that triggers after key enter.
   */
  keyEnterEvent() {
    this.keyInputCallback.emit();
  }

  /**
   * Function that returns the error message
   * @returns mensaje de error.
   */
  get errorLabel(): string | null {
    let label = '';
    if (this.element?.touched && this.element?.errors) {
      const errors = Object.keys(this.element.errors);
      label = inputMessage[errors[0]];
      if (label && label.includes('$COUNT')) {
        if (errors[0] === 'minlength') {
          label = label.replace(
            '$COUNT',
            this.element.errors['minlength'].requiredLength
          );
        }
        if (errors[0] === 'maxlength') {
          label = label.replace(
            '$COUNT',
            this.element.errors['maxlength'].requiredLength
          );
        }
      }
    }
    return label;
  }

  /**
   *Function that show error border on input
   * @returns {boolean}
   */
  get errorBorder(): boolean {
    let respuesta = false;
    if (this.element.touched && this.element.errors) {
      respuesta = true;
    }
    return respuesta;
  }

  /**
   * Method that realize change to show or not eh
   * Realiza el cambio de mostrar o no el texto cuando es del tipo password.
   */
  changePasswordView(): void {
    this.showPassword = !this.showPassword;
    this.type = this.showPassword ? this.typeText : this.params.type;
  }

  /**
   * return availables types
   */
  get types(): any {
    return InputTypes;
  }
}
